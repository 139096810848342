import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import check from "../assets/Images/icon/check.png";
import building from "../assets/Images/icon/building.png";
import { FaCheckCircle } from "react-icons/fa";
import { AlertCircle } from "lucide-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Agreement from "../components/Agreement";
import PremiumUser from "../components/PremiumUser";

function Membership() {
  const { isSubscribed } = useSelector((state) => state.membership);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [buttonCollapsed, setButtonCollapsed] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [VoucherCode, setVoucherCode] = useState("");
  const startDate = useState(moment().format("DD MMM YYYY"));

  const handleCardSelect = (plan) => {
    setButtonCollapsed(true);
    setSelectedPlan(plan);
  };

  const renderPlanCard = () => {
    return membershipPlans1.map((plan) => (
      <button
        key={plan.id}
        className={`relative bg-white shadow-lg rounded-lg p-4 sm:p-4 w-full mt-4 ${
          selectedPlan === plan ? "border-4 border-lime-500" : ""
        }`}
        onClick={() => handleCardSelect(plan)}
      >
        {/* Selected label and green tick */}
        {selectedPlan === plan && (
          <div className="absolute top-4 right-4 bg-green-100 text-lime-600 text-xs font-medium font-inter px-2 py-1 rounded-xl flex items-center gap-1">
            <span className="hidden sm:flex">Selected</span>
            <FaCheckCircle />
          </div>
        )}
        <p className="text-zinc-500 text-xs sm:text-sm font-medium font-inter">
          {plan.type}
        </p>
        <h1 className="text-black text-lg sm:text-3xl font-semibold font-inter mt-3">
          {plan.price}
        </h1>

        {selectedPlan === plan && (
          <>
            {/* divider */}
            <div className="w-full border-b-2 border-[#F3F5F6] my-3 sm:my-6"></div>
            {/* branches */}
            <div className="flex items-center justify-start space-x-1.5 sm:space-x-3">
              <img
                src={check}
                alt="check icon"
                className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
              />
              <p className="text-black text-xs sm:text-base font-normal font-open">
                Covers up-to {plan.branches} branches or collection centres
              </p>
              {[...Array(plan.branches)].map((_, i) => (
                <img
                  key={i}
                  src={building}
                  alt="building icon"
                  className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                />
              ))}
            </div>
            {/* Commission fee */}
            <div className="flex items-center justify-start space-x-1.5 sm:space-x-3 mt-2 sm:mt-4">
              <img
                src={check}
                alt="check icon"
                className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
              />
              <p className="text-black text-xs sm:text-base font-normal font-open">
                AllesHealth Commission Fee: {plan.commissionFee}
              </p>
            </div>
          </>
        )}

        {!buttonCollapsed && (
          <>
            {/* divider */}
            <div className="w-full border-b-2 border-[#F3F5F6] my-3 sm:my-6"></div>
            {/* branches */}
            <div className="flex items-center justify-start space-x-1.5 sm:space-x-3">
              <img
                src={check}
                alt="check icon"
                className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
              />
              <p className="text-black text-xs sm:text-base font-normal font-open">
                Covers up-to {plan.branches} branches or collection centres
              </p>
              {[...Array(plan.branches)].map((_, i) => (
                <img
                  key={i}
                  src={building}
                  alt="building icon"
                  className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                />
              ))}
            </div>
            {/* Commission fee */}
            <div className="flex items-center justify-start space-x-1.5 sm:space-x-3 mt-2 sm:mt-4">
              <img
                src={check}
                alt="check icon"
                className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
              />
              <p className="text-black text-xs sm:text-base font-normal font-open">
                AllesHealth Commission Fee: {plan.commissionFee}
              </p>
            </div>
          </>
        )}
      </button>
    ));
  };

  const renderView = () => {
    if (showAgreement) {
      return <Agreement setShowAgreement={setShowAgreement} />;
    } else if (isSubscribed) {
      return <PremiumUser />;
    } else {
      return (
        <>
          <div className="m-10 flex flex-col justify-between h-[calc(100vh-9rem)]">
            <div>
              {/* Membership */}
              <h1 className="text-black text-sm sm:text-xl font-semibold font-open">
                {buttonCollapsed
                  ? "Membership"
                  : "Select a Plan for Membership"}
              </h1>

              {/* Content */}
              <div className="flex item-start space-x-10">
                {/* plans card */}
                <div className="flex flex-col items-start justify-center max-w-xl w-1/2">
                  {renderPlanCard()}
                </div>

                {buttonCollapsed && (
                  <div className="w-1/2 max-w-lg">
                    <>
                      {/* Voucher Code Input */}
                      <div className="my-4">
                        <label>
                          <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                            Voucher Code
                            {/* <sup className="text-red-600">*</sup> */}
                          </p>
                          <input
                            type="text"
                            name="GSTIN"
                            value={VoucherCode}
                            onChange={(e) => setVoucherCode(e.target.value)}
                            maxLength={6}
                            placeholder="Input Voucher Code (Optional)"
                            className={`w-full text-black text-xs font-normal font-open bg-white rounded-md p-4 border  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          />
                          {VoucherCode.length === 6 ? (
                            <div className="flex items-center mt-3">
                              <AlertCircle size={15} className="text-red-500" />
                              <p className="text-red-500 text-xs font-semibold font-open ml-1">
                                Invalid Voucher Code
                              </p>
                            </div>
                          ) : (
                            <div className="h-7"></div>
                          )}
                        </label>
                      </div>

                      <div className="bg-white rounded-lg shadow border-2 border-sky-400 border-opacity-30 p-4">
                        <h1 className="text-zinc-950 text-sm sm:text-xl font-semibold mb-2">
                          Summary :
                        </h1>
                        <h1 className="text-zinc-950 text-xs font-semibold mb-1">
                          Final (Recurring) Amount
                        </h1>
                        <h1 className="text-neutral-400 text-xs font-normal mb-2">
                          {selectedPlan?.price}
                        </h1>
                        <h1 className="text-zinc-950 text-xs font-semibold font-open mb-1">
                          Renewal Period
                        </h1>
                        <h1 className="text-neutral-400 text-xs font-normal mb-2">
                          {selectedPlan?.type === "Monthly Payment"
                            ? "Monthly"
                            : "Yearly"}
                        </h1>
                        <h1 className="text-zinc-950 text-xs font-semibold font-open mb-1">
                          Start Date
                        </h1>
                        <h1 className="text-neutral-400 text-xs font-normal">
                          {startDate}
                        </h1>
                      </div>
                      <div className="mt-4">
                        <button
                          onClick={() => {
                            localStorage.setItem(
                              "selectedPlan",
                              JSON.stringify(selectedPlan)
                            );
                            setShowAgreement(true);
                          }}
                          className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
                        >
                          Proceed
                        </button>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="text-zinc-950 text-xs sm:text-sm font-normal font-open">
                Got more collection centres? Contact us to get the customised
                pricing for your business.
              </div>

              <a
                href="mailto:info@alleshealth.com"
                className="text-sky-500 text-xs sm:text-sm"
              >
                info@alleshealth.com
              </a>
            </div>
          </div>
        </>
      );
    }
  };

  return renderView();
}
export default Membership;

const membershipPlans1 = [
  {
    id: "1",
    type: "Monthly Payment",
    price: "INR 250",
    branches: 2,
    commissionFee: "8%",
  },
  {
    id: "2",
    type: "Yearly Payment",
    price: "INR 2,500",
    branches: 3,
    commissionFee: "6%",
  },
];
