import React from "react";
import { Carousel } from "antd";
const AntdCarousel = () => {
  return (
    <div className="md:max-w-sm lg:max-w-lg w-[501px] mx-auto">
      <Carousel autoplay>
        <div className="text-start mb-5">
          <h5 className="text-sky-100 text-[40px] font-bold">
            Reach out to Millions of Patients and Grow Your Business
          </h5>
        </div>
        <div className="text-start mb-5">
          <h5 className="text-sky-100 text-[40px] font-bold">
            Save over INR 1L in Marketing Budget in a Month
          </h5>
        </div>
        <div className="text-start mb-5">
          <h5 className="text-sky-100 text-[40px] font-bold">
            Boost your Healthcare Business anywhere in the Country
          </h5>
        </div>
      </Carousel>
    </div>
  );
};
export default AntdCarousel;
