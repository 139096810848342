import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import membershipReducer from "../slices/membershipSlice";
import offersReducer from "../slices/OffersSlice";
import productImagesReducer from "../slices/ProductImageSlice";
import productReducer from "../slices/productSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  membership: membershipReducer,
  product: productReducer,
  offers: offersReducer,
  productImages: productImagesReducer,
});

export default rootReducer;
