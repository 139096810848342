import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  registerMerchant,
  verifyMerchantOTP,
} from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";

function OtpForm() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signupData, loading } = useSelector((state) => state.auth);
  const tempData = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : "";
  const [timer, setTimer] = useState(59); // 4 minutes and 58 seconds in total
  const [showResendButton, setShowResendButton] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer((prevTimer) => {
  //       if (prevTimer === 1) {
  //         clearInterval(interval);
  //         setShowResendButton(true);
  //       }
  //       return prevTimer - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    let reachingInCounter = null;
    reachingInCounter = setInterval(() => {
      setTimer(timer - 1 >= 0 ? timer - 1 : 0);
    }, 1000);
    if (timer === 0) {
      clearInterval(reachingInCounter);
      setShowResendButton(true);
    }
    return () => {
      if (reachingInCounter) {
        clearInterval(reachingInCounter);
      }
    };
  }, [timer, setTimer]);

  useEffect(() => {
    // Only allow access of this route when user has filled the signup form
    if (!signupData) {
      navigate("/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendOTP = useCallback(() => {
    // Logic to resend OTP
    setShowResendButton(false);
    setTimer(59);
    dispatch(
      registerMerchant(
        tempData.name,
        tempData.email || tempData.PhoneNumber,
        tempData.password,
        tempData.SignupType,
        navigate
      )
    );
    setOtp("");
  }, [setTimer]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours < 10 ? "0" + hours : hours} : ${
      minutes < 10 ? "0" + minutes : minutes
    } : ${seconds < 10 ? "0" + seconds : seconds}`;
  };

  const handleVerifyAndSignup = (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      toast.error("Please enter a valid OTP");
      return;
    }
    const { PID } = signupData;
    dispatch(verifyMerchantOTP(PID, otp, navigate));
    setOtp("");
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg py-10 px-5 w-[420px]">
          <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
          <h1 className="text-black text-3xl font-bold font-Open mt-8">
            Enter OTP
          </h1>
          <p className="text-black text-sm font-normal font-open leading-tight mt-4">
            4 digit code has been sent to{" "}
            <span>{tempData?.email || tempData?.PhoneNumber}</span>
          </p>
          <form onSubmit={handleVerifyAndSignup} className="mt-8">
            <div className="mr-20">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px] lg:w-[50px] border-0 bg-gray-100 rounded-md text-black text-2xl font-bold font-orbitron aspect-square text-center focus:border-0 focus:outline-1 focus:outline-sky-400"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />
            </div>

            {showResendButton ? (
              <div className="flex items-center justify-start mt-16">
                <button
                  onClick={handleResendOTP}
                  className="text-right text-sky-400 text-sm font-normal font-open"
                >
                  Resend OTP
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-start mt-16">
                <span className="text-sky-400 text-sm font-normal font-open">
                  {formatTime(timer)}
                </span>
              </div>
            )}
            <div className="mt-24">
              <button
                type="submit"
                className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default OtpForm;
