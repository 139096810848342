import React, { useState, useEffect } from "react";
import { ReactComponent as PhoneModelSvg } from "../assets/Images/pngwing 1.svg";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import logo from "../assets/Images/logo.png"
import coin from "../assets/Images/icon/Coin.png";
import COINS from "../assets/Images/COins.png";
import upload from "../assets/Images/icon/upload.png";
import { PiHeartbeatBold } from "react-icons/pi";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getBucketURL } from "../utils/regex";
import { AiOutlinePercentage } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { resetOffersData, updateOffersData } from "../slices/OffersSlice";
import {
  addCurrentProduct,
  editCurrentProduct,
  uploadProductLogo,
} from "../services/operations/productAPI";
import _ from "lodash";
import imageCompression from "browser-image-compression";

const PreviewCard = ({
  formData,
  setIsPhoneModel,
  formattedLocations,
  img,
}) => {
  const [isPhoneModel, setIsPhoneModelLocal] = useState(false);
  return (
    <div className="flex flex-col items-center text-center justify-center">
      {/* SVG Image Section */}
      <div className="relative">
        {/* Clickable div to switch phone model */}
        <div
          className="absolute z-[99999] w-[5.9em] h-[43em] right-[-1.5em] bottom-0"
          onClick={(e) => {
            e.preventDefault();
            setIsPhoneModelLocal(false);
            setIsPhoneModel(false);
          }}
        ></div>

        <PhoneModelSvg style={{ height: "43em", width: "22em" }} />
        <div className="absolute top-6 left-1 z-99 p-4 pl-4">
          <div className="flex flex-row justify-between items-center gap-5 w-full p-4">
            <div
              className="flex-2 p-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                // setIsPhoneModelLocal(true); // Update the local state
                // setIsPhoneModel(true);
              }}
            >
              <MdOutlineKeyboardArrowLeft />
            </div>
            <div className="flex-1 flex flex-row justify-between p-2 gap-4">
              <h1
                style={{ fontSize: "14px" }}
                className="text-black font-semibold font-open"
              >
                My AllesHealth Reward
              </h1>
              <h3
                style={{
                  fontSize: "12px",
                  color: "#3CA5DC",
                  marginTop: "2px",
                  marginLeft: "2px",
                }}
                className="font-normal font-open"
              >
                History
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{ width: "19.7em", paddingLeft: "21px" }}
          className="absolute bottom-8 left-3 z-99 p-1 pb-0 rounded-md flex-1"
        >
          <div
            style={{
              borderBottomLeftRadius: "10%",
              borderBottomRightRadius: "9%",
            }}
            className="bg-white rounded-md p-2 pb-4"
          >
            <div>
              {/* Image Section */}
              <div className="flex justify-center items-center mt-2 mb-2">
                {/* Display uploaded image */}
                {formData?.productImageLocation ? (
                  <img
                    src={img}
                    alt="Uploaded Image"
                    className="w-[10em] h-[5em] object-contain rounded-md"
                  />
                ) : (
                  // <div className="w-20 h-20 bg-gray-100 rounded-full overflow-hidden flex items-center justify-center">
                  // </div>
                  <div
                    style={{ fontSize: "200%", color: "#3CA5DC" }}
                    className="w-20 h-20 bg-gray-100 rounded-full overflow-hidden flex items-center justify-center"
                  >
                    <PiHeartbeatBold />
                  </div>
                )}
              </div>

              <div className="mb-2 flex flex-col justify-center items-center">
                {formData.productName ? (
                  <h2 style={{ wordWrap: "break-word" }} className="text-center text-black text-sm w-[15em] font-semibold font-open mb-1">
                    {formData.productName}
                  </h2>
                ) : (
                  <h2 className="text-black text-sm font-semibold font-open mb-1">
                    Product Name
                  </h2>
                )}
                <p className="text-zinc-950 text-xs font-semibold font-open">
                  10 Alles Coin
                </p>
              </div>

              <div className="mb-2">
                {formData.description ? (
                  <h2
                    className="text-neutral-700 text-xs font-normal font-open w-[22em] mx-auto"
                    style={{ wordWrap: "break-word" }}
                  >
                    {formData.description}
                    {/* {formData.description.length > 90
                      ? `${formData.description.substring(0, 90)}...`
                      : formData.description} */}
                  </h2>
                ) : (
                  <h2 className="text-neutral-700 text-sm font-normal font-open">
                    Product description
                  </h2>
                )}
              </div>
            </div>

            {/* <div className="mb-4 flex flex-row items-center justify-center gap-2 px-4">
              <button
                style={{
                  color: "#3CA5DC",
                  borderRadius: "2.5em",
                  fontSize: "10px",
                  cursor: "default",
                }}
                className="bg-sky-100 font-semibold font-open px-4 py-1 rounded-md"
              >
                Hospital
              </button>
              <button
                style={{
                  color: "#3CA5DC",
                  borderRadius: "2.5em",
                  fontSize: "10px",
                  cursor: "default",
                }}
                className="bg-sky-100 font-semibold font-open px-4 py-1 rounded-md"
              >
                Clinic
              </button>
              <button
                style={{
                  color: "#3CA5DC",
                  borderRadius: "2.5em",
                  fontSize: "10px",
                  cursor: "default",
                }}
                className="bg-sky-100 font-semibold font-open px-4 py-1 rounded-md"
              >
                Laboratory
              </button>
            </div> */}

            <div className="mb-2">
              {formData.typeOfOffer === "ONLINE" ? (
                <div className="flex flex-row items-center justify-around">
                  <h2 className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Redeemable at :
                  </h2>
                  <p
                    style={{ borderRadius: "2em" }}
                    className="offer-card__status text-[#86D511] bg-[#ECFFD0] text-sm px-4 rounded-md mb-6 mt-2"
                  >
                    Online
                  </p>
                </div>
              ) : (
                <>
                  <h2 className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Redeemable at :
                  </h2>

                  {/* Check if addressList is empty, if so, display dummy data */}
                  {formData.addressList.length === 0 ? (
                    <>
                      {[...Array(3)].map((_, index) => (
                        <div
                          key={index}
                          className="bg-sky-100 mx-4 font-semibold font-open px-4 py-2 mb-1 rounded-md flex flex-col justify-center text-left"
                        >
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-black font-semibold font-open"
                          >
                            Address {index + 1}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* Render only the first three addresses */}
                      {formData.addressList
                        .slice(0, 3)
                        .map((addressId, index) => (
                          <div
                            key={index}
                            className="bg-sky-100 font-semibold font-open px-4 py-2 mb-1 mx-4 rounded-md flex flex-col justify-center text-left"
                          >
                            <p
                              style={{ fontSize: "12px" }}
                              className="text-black font-semibold font-open"
                            >
                              {formattedLocations[addressId]
                                ? formattedLocations[addressId].length > 30
                                  ? `${formattedLocations[addressId].substring(
                                      0,
                                      30
                                    )}...`
                                  : formattedLocations[addressId]
                                : "Address Not Found"}
                            </p>
                          </div>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>

            <div className="mt-auto">
              {/* Back Button */}
              <button
                type="submit"
                style={{ color: "#3CA5DC", border: "1px solid #3CA5DC" }}
                className="bg-white w-full px-4 py-2 rounded-md mt-4 text-sm"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsPhoneModelLocal(true); // Update the local state
                  // setIsPhoneModel(true);
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewCard1 = ({ formData, setIsPhoneModel, img, company }) => {
  const [isPhoneModel, setIsPhoneModelLocal] = useState(false);
  return (
    <div className="flex flex-col items-center text-center justify-center">
      {/* SVG Image Section */}
      <div className="relative">
        {/* Clickable div to switch phone model */}
        <div
          className="absolute z-[99999] w-[6.1em] h-[43em] left-[-1.5em] bottom-0"
          onClick={(e) => {
            e.preventDefault();
            setIsPhoneModelLocal(true);
            setIsPhoneModel(true);
          }}
        ></div>

        <PhoneModelSvg style={{ height: "43em", width: "22em" }} />
        {/* Mobile Header */}
        <div className="absolute top-6 left-1 z-99 p-4 pl-4">
          <div className="flex flex-row justify-between items-center gap-5 w-full p-4">
            <div className="flex-2 p-2">
              <MdOutlineKeyboardArrowLeft />
            </div>
            <div className="flex-1 flex flex-row justify-between p-2 gap-4">
              <h1
                style={{ fontSize: "14px" }}
                className="text-black font-semibold font-open"
              >
                My AllesHealth Reward
              </h1>
              <h3
                style={{
                  fontSize: "12px",
                  color: "#3CA5DC",
                  marginTop: "2px",
                  marginLeft: "2px",
                }}
                className="font-normal font-open"
              >
                History
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{ width: "19.7em", paddingLeft: "21px" }}
          className="absolute bottom-8 left-3 z-99 p-1 pb-0 rounded-md flex-1"
        >
          <div
            style={{
              borderBottomLeftRadius: "10%",
              borderBottomRightRadius: "9%",
            }}
            className="bg-white rounded-md p-2 pb-4"
          >
            <div className="flex flex-col justify-center items-center">
              {/* Header (Coins) */}
              <div className="bg-gray-100 w-full p-5 py-6 mt-2 mb-3 flex flex-col justify-center items-center rounded-md">
                <h2
                  style={{ fontSize: "12px" }}
                  className="text-black font-semibold font-open"
                >
                  Family's AllesHealth Coins Account
                </h2>
                <div className="flex flex-row justify-center items-center">
                  <img
                    src={coin}
                    alt="coin icon"
                    className="w-10 h-10 object-contain"
                  />
                  <p className="text-zinc-950 pl-1 text-[35px] font-semibold font-open">
                    5000
                  </p>
                </div>
              </div>

              {/* Health Reward Section */}
              <div className="flex w-full flex-col px-1">
                <h1 className="text-zinc-950 text-sm font-semibold font-open mb-1 text-center">
                  Health Reward
                </h1>
                <hr
                  style={{ border: "2px solid #3CA5DC" }}
                  className="w-full"
                />

                {/* Clickable and Dynamic Card */}
                <div className="relative flex flex-col gap-0 mt-4">
                  <div className="absolute left-6 top-2">
                    <div
                      style={{
                        fontSize: "150%",
                        color: "#3CA5DC",
                        width: "1em",
                        height: "1em",
                      }}
                      className="p-1 bg-white rounded-full overflow-hidden flex items-center justify-center w-full h-full"
                    >
                      <IoIosInformationCircleOutline />
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsPhoneModelLocal(false); // Update the local state
                      // setIsPhoneModel(false);
                    }}
                    style={{
                      border: "2px dotted #3CA5DC",
                      borderBottom: "2px solid #3CA5DC",
                      borderBottomLeftRadius: "0%",
                      borderBottomRightRadius: "0%",
                    }}
                    className="bg-sky-100 flex flex-col justify-between bg-gray-100 rounded-md mx-4 cursor-pointer"
                  >
                    {/* Image Section */}
                    <div className="flex justify-center items-center my-4 px-5">
                      {/* Display uploaded image */}
                      {formData?.productImageLocation ? (
                        <img
                          src={img}
                          alt="Uploaded Image"
                          className="w-[8em] h-[5em] object-contain rounded-md"
                        />
                      ) : (
                        <div
                          style={{
                            border: "2px solid #3CA5DC",
                            fontSize: "200%",
                            color: "#3CA5DC",
                            width: "2em",
                            height: "2em",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Adding bottom shadow
                          }}
                          className="p-2 bg-white rounded-full overflow-hidden flex items-center justify-center"
                        >
                          <PiHeartbeatBold />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col px-5">
                      <h1
                        style={{ fontSize: "13px", wordWrap: "break-word" }}
                        className="text-zinc-950 text-sm font-bold font-open text-left"
                      >
                        {formData.productName &&
                        formData.productName.length <= 30
                          ? formData.productName
                          : formData.productName
                          ? `${formData.productName.substring(0, 30)}...`
                          : "Product Name"}
                        <span
                          style={{ fontSize: "12px" }}
                          className="text-zinc-950  font-normal font-open"
                        >
                          {" "}
                          for just
                        </span>
                      </h1>

                      {formData.standardPrice ? (
                        <h1
                          style={{ fontSize: "15px" }}
                          className="text-zinc-950 font-bold font-open text-left"
                        >
                          {formData.offerPrice
                            ? `Rs ${formData.offerPrice}`
                            : `Rs ${formData.standardPrice}`}{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "line-through",
                            }}
                            className="text-gray-400  font-normal font-open"
                          >
                            {formData.offerPrice &&
                              `Rs ${formData.standardPrice}`}
                          </span>
                        </h1>
                      ) : (
                        <h1
                          style={{ fontSize: "15px" }}
                          className="text-zinc-950 font-bold font-open text-left"
                        >
                          Price{" "}
                        </h1>
                      )}
                      <h1
                        style={{ fontSize: "10px" }}
                        className="text-gray-400  font-bold font-open text-left mt-2 mb-1"
                      >
                        Offered by {company?.CompanyName}
                      </h1>
                      <div className="flex flex-row items-center gap-4 mb-2">
                        <div className="flex flex-col">
                          <h1
                            style={{ fontSize: "10px" }}
                            className="text-gray-400 font-bold font-open text-left"
                          >
                            Using
                          </h1>
                          <h1
                            style={{ fontSize: "12px", color: "#3CA5DC" }}
                            className="font-bold font-open text-left"
                          >
                            10{" "}
                            <span style={{ fontSize: "12px" }}>AH Coins</span>
                          </h1>
                        </div>
                        <img
                          src={COINS}
                          alt="Uploaded Image"
                          className="w-10 h-10 object-contain mt-2"
                        />
                      </div>
                    </div>
                  </div>
                  {formData.discountAmount ? (
                    <div
                      style={{
                        border: "2px solid #3CA5DC",
                        borderTopLeftRadius: "0%",
                        borderTopRightRadius: "0%",
                        backgroundColor: "#3CA5DC",
                        fontSize: "20px",
                      }}
                      className="rounded-md text-white mx-4 font-bold py-2 mb-4"
                    >
                      {formData.discountType === "FREE" ||
                      (formData.discountType === "ACTUAL" &&
                        formData.standardPrice === formData.discountAmount)
                        ? "FREE"
                        : formData.discountType === "PERCENTAGE"
                        ? `${formData.discountAmount}% Off`
                        : `Rs ${formData.discountAmount} Off`}
                    </div>
                  ) : (
                    <div
                      style={{
                        border: "2px solid #3CA5DC",
                        borderTopLeftRadius: "0%",
                        borderTopRightRadius: "0%",
                        backgroundColor: "#3CA5DC",
                        fontSize: "20px",
                      }}
                      className="rounded-md text-white mx-4 font-bold py-2 mb-4"
                    >
                      0% Off
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-1 px-2 flex flex-row items-center justify-between gap-2">
              <div className="flex flex-col text-left">
                <h3
                  style={{ fontSize: "11px" }}
                  className=" text-black font-semibold font-open"
                >
                  Item(s) Selected: <span style={{ color: "#3CA5DC" }}>1</span>
                </h3>
                <h3
                  style={{ fontSize: "10px" }}
                  className="text-black font-semibold font-open"
                >
                  10 AH Coins
                </h3>
              </div>
              {/* Redeem Button */}
              <button
                type="submit"
                style={{
                  backgroundColor: "#3CA5DC",
                  border: "1px solid #3CA5DC",
                }}
                className="text-white text-xs px-4 py-2 rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  // Add your back logic here
                }}
              >
                Redeem
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateOffer = () => {
  const { company } = useSelector((state) => state.profile);
  const [isPhoneModel, setIsPhoneModel] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [PPID, setPPID] = useState("");
  const [image, setImage] = useState("");
  const { token } = useSelector((state) => state.auth);
  const formData1 = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    productImageLocation: company?.LogoLocation || "",
    productName: "",
    description: "",
    standardPrice: "",
    selectedOffer: "",
    offerPrice: "",
    discountType: "",
    discountAmount: "",
    typeOfOffer: "",
    addressList: [],
  });

  const [errors, setErrors] = useState({
    productImageLocation: "",
    productName: "",
    description: "",
    standardPrice: "",
    selectedOffer: "",
    offerPrice: "",
    discountType: "",
    discountAmount: "",
    typeOfOffer: "",
    addressList: [],
  });

  const [productApiResponse, setProductApiResponse] = useState({});

  useEffect(() => {
    if (formData1.isEdit === true) {
      setIsEdit(true);
      setPPID(formData1.PPID);
      const selectedAddresses = formData1.ProductAddresses.map((address) =>
        address.PCLID.toString()
      );
      setFormData({
        productImageLocation: formData1.productImageLocation || "",
        productName: formData1.ProductName || "",
        description: formData1.Description || "",
        standardPrice: String(formData1.StandardPrice) || 0,
        offerPrice: String(formData1.FinalAmount) || 0,
        discountType: formData1.DiscountType || "",
        discountAmount: String(formData1.Discount_Amount) || 0,
        typeOfOffer: formData1.TypeOfOffer || "",
        addressList: selectedAddresses || [],
        selectedOffer: formData1.DiscountType || "",
      });

      setProductApiResponse({
        productImageLocation: formData1.productImageLocation || "",
        productName: formData1.ProductName || "",
        description: formData1.Description || "",
        standardPrice: String(formData1.StandardPrice) || 0,
        offerPrice: String(formData1.FinalAmount) || 0,
        discountType: formData1.DiscountType || "",
        discountAmount: String(formData1.Discount_Amount) || 0,
        typeOfOffer: formData1.TypeOfOffer || "",
        addressList: selectedAddresses || [],
        selectedOffer: formData1.DiscountType || "",
      });
    }

    return () => {
      dispatch(resetOffersData());
    };
  }, []);

  useEffect(() => {
    if (formData?.productImageLocation) {
      getBucketURL(formData?.productImageLocation).then((res) => {
        setImage(res);
      });
    }
  }, [formData?.productImageLocation]);
  // Store users Branch locations
  const formattedLocations = {};

  company?.Locations.forEach((location) => {
    const formattedLocation = `${location.Branch_Name}, ${location.City}, ${location.State}, ${location.ZipCode}`;
    formattedLocations[location.ID] = formattedLocation;
  });

  function findDifferences(obj1, obj2) {
    const differences = {};
    for (const key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    }
    return differences;
  }

  // Function to handle checkbox click
  const handleAddressCheckboxClick = (index) => {
    // Check if the index is already selected
    const isSelected = formData.addressList.includes(index);

    // Toggle selection of address
    const selectedAddresses = isSelected
      ? formData.addressList.filter((item) => item !== index) // If already selected, remove it
      : [...formData.addressList, index]; // If not selected, add it

    setFormData((prev) => ({
      ...prev,
      addressList: selectedAddresses,
    }));
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const calculateOfferPrice = (price, discount) => {
    const parsedPrice = parseFloat(price);
    const parsedDiscount = parseFloat(discount);

    if (!isNaN(parsedPrice)) {
      if (formData.discountType === "PERCENTAGE") {
        if (!isNaN(parsedDiscount)) {
          const calculatedOfferPrice =
            parsedPrice * ((100 - parsedDiscount) / 100);
          setFormData((prev) => ({
            ...prev,
            offerPrice: calculatedOfferPrice.toFixed(2),
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            offerPrice: "", // Reset OfferPrice if discountPercentage is invalid
          }));
        }
      } else if (formData.discountType === "ACTUAL") {
        if (!isNaN(parsedDiscount)) {
          const calculatedOfferPrice = parsedPrice - parsedDiscount;
          setFormData((prev) => ({
            ...prev,
            offerPrice: calculatedOfferPrice.toFixed(2),
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            offerPrice: "", // Reset OfferPrice if discount is invalid
          }));
        }
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        offerPrice: "", // Reset OfferPrice if price is invalid
      }));
    }
  };

  function handleDiscountTypeChange(event) {
    setIsPhoneModel(true);
    const { name } = event.target;
    setErrors((prev) => ({
      ...prev,
      discountType: "",
    }));
    if (name === "ACTUAL") {
      setFormData((prev) => ({
        ...prev,
        selectedOffer: "ACTUAL",
        discountAmount: "0",
        discountType: "ACTUAL",
        offerPrice: formData.standardPrice,
      }));
    } else if (name === "FREE") {
      setFormData((prev) => ({
        ...prev,
        selectedOffer: "FREE",
        discountAmount: "100",
        discountType: "FREE",
        offerPrice: "0",
      }));
    } else if (name === "PERCENTAGE") {
      setFormData((prev) => ({
        ...prev,
        selectedOffer: "PERCENTAGE",
        discountAmount: "0",
        discountType: "PERCENTAGE",
        offerPrice: formData.standardPrice,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      discountType: "",
      discountAmount: "",
    }));
  }

  function handleStandardPriceChange(event) {
    const { name, value } = event.target;

    // Validate input to allow only numeric values (including doubles)
    const pattern = /^\d*\.?\d*$/;
    if (!pattern.test(value)) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    calculateOfferPrice(event.target.value, formData.discountAmount);
  }

  function handleDiscountChange(event) {
    const { name, value } = event.target;
    const { discountType, standardPrice } = formData;

    // Validate input based on discountType
    if (discountType === "PERCENTAGE") {
      const pattern = /^(100(\.00?)?|\d{0,2}(\.\d{1,2})?)$/;
      if (!pattern.test(value)) {
        return;
      }
    } else if (discountType === "ACTUAL") {
      // Prevent discountAmount from exceeding standardPrice
      if (parseInt(value) > parseInt(standardPrice)) {
        return;
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    calculateOfferPrice(formData.standardPrice, event.target.value);
  }

  function handleOfferTypeChange(event) {
    setIsPhoneModel(false);
    const { value } = event.target;
    if (value === "ONLINE") {
      // If typeOfOffer is "ONLINE", set selectedAddresses to an empty array
      setFormData((prev) => ({
        ...prev,
        typeOfOffer: value,
        addressList: [],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        typeOfOffer: value,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      typeOfOffer: "",
    }));
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    // setImage(file);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("partnerProductImage", file);
    try {
      const res = await uploadProductLogo(formData, token);
      setFormData((prev) => ({
        ...prev,
        productImageLocation: res?.logoUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        productImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  function submitHandler(event) {
    event.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      } else if (
        typeof formData[key] === "string" &&
        formData[key].trim() === ""
      ) {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      if (formData.discountType !== "PERCENTAGE") {
        setErrors((prev) => ({
          ...prev,
          discountType: "",
          discountAmount: "",
        }));
      }
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("Errors:", newErrors);
      return;
    }

    let diffObject = findDifferences(productApiResponse, formData);
    diffObject.PPID = PPID;

    if (isEdit) {
      if (diffObject.hasOwnProperty("standardPrice")) {
        diffObject.standardPrice = parseInt(diffObject.standardPrice);
      }
      if (diffObject.hasOwnProperty("discountAmount")) {
        diffObject.discountAmount = parseInt(diffObject.discountAmount);
      }
      if (diffObject.hasOwnProperty("offerPrice")) {
        diffObject.offerPrice = parseInt(diffObject.offerPrice);
      }
      dispatch(editCurrentProduct(token, diffObject, navigate));
    } else {
      const basicData = {
        productImageLocation: formData.productImageLocation,
        productName: formData.productName,
        description: formData.description,
        standardPrice: formData.standardPrice,
        offerPrice: parseInt(formData.offerPrice),
        discountType: formData.discountType,
        discountAmount: parseInt(formData.discountAmount),
        typeOfOffer: formData.typeOfOffer,
        addressList: formData.addressList,
      };
      dispatch(addCurrentProduct(token, basicData, navigate));
    }
  }

  return (
    <div className="bg-sky-100 min-h-screen py-2 px-4 sm:py-5 sm:px-10 scroll-smooth">
      <div className="flex lg:flex-row sm:flex-col flex-col gap-4">
        {/* Left Card: Direct Offerings for Patients */}
        <div className="flex-1 h-full rounded-md">
          <div className="pb-4">
            <h1 className="text-black text-xl font-semibold font-open">
              Direct Offerings for Patients
            </h1>
          </div>
          <div className="bg-white p-4 h-full rounded-md flex-1">
            <form>
              {/* Upload Product Image */}
              <div className="mb-6">
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Add Logo or Product Image
                  <sup className="text-red-600">*</sup>
                </p>
                <label
                  className={`w-full border-dashed ${
                    errors.productImageLocation
                      ? "border-red-500"
                      : "border-sky-400"
                  }  border-2 rounded-md p-2 flex flex-col items-center justify-center cursor-pointer bg-gray-100
                    `}
                >
                  {formData?.productImageLocation ? (
                    <>
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-30 h-20 object-contain"
                      />

                      <div className="px-3 py-1.5 bg-neutral-400 rounded-[29px] justify-center items-center indivne-flex mt-3">
                        <p className="text-gray-100 text-xs font-semibold font-open">
                          Change Image
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={upload}
                        alt="upload icon"
                        className="w-10 h-10 object-contain"
                      />
                      <span className="text-sky-400 text-xs font-normal font-open mt-1">
                        Upload Here
                      </span>
                      <span className="text-neutral-400 text-[11px] font-normal font-open mt-1">
                        File : JPG, PNG, SVG Max 20 Mb
                      </span>
                    </>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    name="productImageLocation"
                    value={undefined}
                    onChange={(e) => handleImageChange(e)}
                    style={{ display: "none" }}
                  />
                </label>
              </div>

              {/* Product Name */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Product Available to Sell
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="productName"
                    value={formData.productName}
                    onChange={handleOnChange}
                    maxLength={30}
                    placeholder="Input Product Name"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.productName ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* description */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    description
                    <sup className="text-red-600">*</sup>
                  </p>
                  <textarea
                    style={{ paddingBottom: "2.5rem" }}
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleOnChange}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPhoneModel(false);
                    }}
                    maxLength={300}
                    placeholder="PET scan is medical imaging technique that uses a special type of camera and radioactive tracer to produce three-dimensional images of the body."
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.description ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* standardPrice */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Standard Price
                    <sup className="text-red-600">*</sup>
                  </p>
                  <div className="flex flex-row">
                    <span
                      style={{ color: "#3CA5DC", fontSize: "150%" }}
                      className="h-full bg-sky-100 flex-1 p-4 flex items-center rounded-md justify-center"
                    >
                      <LiaRupeeSignSolid />
                    </span>
                    <input
                      type="number"
                      name="standardPrice"
                      value={formData.standardPrice}
                      onChange={handleStandardPriceChange}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPhoneModel(true);
                      }}
                      onKeyDown={(e) => {
                        // Prevent default behavior for up and down arrow keys
                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      maxLength={20}
                      placeholder="Input Price Here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                        errors.standardPrice ? "border-red-500" : ""
                      }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>

              {/* discountAmount section */}
              <div className="mb-6">
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Type of Discount Amount
                  <sup className="text-red-600">*</sup>
                </p>
                <div className="flex flex-row justify-start items-center gap-4">
                  <label className="flex flex-row gap-1">
                    <input
                      type="checkbox"
                      // value="PERCENTAGE"
                      checked={Boolean(formData.selectedOffer === "PERCENTAGE")}
                      name="PERCENTAGE"
                      onChange={handleDiscountTypeChange}
                      style={{
                        color: "#3CA5DC",
                        border: "1px solid #3CA5DC",
                        backgroundColor: "#3CA5DC",
                      }}
                      className="checked:bg-sky-100"
                    />

                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2 pl-2 pt-2">
                      Percentage
                    </p>
                  </label>
                  <label className="flex flex-row gap-1">
                    <input
                      type="checkbox"
                      // value="ACTUAL"
                      name="ACTUAL"
                      checked={Boolean(formData.selectedOffer === "ACTUAL")}
                      onChange={handleDiscountTypeChange}
                    />

                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2 pl-2 pt-2">
                      Price Reduction
                    </p>
                  </label>
                  <label className="flex flex-row gap-1">
                    <input
                      type="checkbox"
                      // value="FREE"
                      name="FREE"
                      checked={Boolean(formData.selectedOffer === "FREE")}
                      onChange={handleDiscountTypeChange}
                    />

                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2 pl-2 pt-2">
                      Free
                    </p>
                  </label>
                </div>

                {/* discount percentage */}
                {formData.discountType === "PERCENTAGE" && (
                  <>
                    <div className="flex flex-row">
                      <span
                        style={{ color: "#3CA5DC", fontSize: "150%" }}
                        className="h-full bg-sky-100 flex-1 p-4 flex items-center rounded-md justify-center"
                      >
                        <AiOutlinePercentage />
                      </span>
                      <input
                        type="number"
                        name="discountAmount"
                        value={formData.discountAmount}
                        onChange={handleDiscountChange}
                        onKeyDown={(e) => {
                          // Prevent default behavior for up and down arrow keys
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        placeholder="Input Discount Percentage Here"
                        className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                          errors.discountAmount ? "border-red-500" : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </div>
                  </>
                )}

                {/* Price Reduction */}
                {formData.discountType === "ACTUAL" && (
                  <>
                    <div className="flex flex-row">
                      <span
                        style={{ color: "#3CA5DC", fontSize: "150%" }}
                        className="h-full bg-sky-100 flex-1 p-4 flex items-center rounded-md justify-center"
                      >
                        <LiaRupeeSignSolid />
                      </span>
                      <input
                        type="number"
                        name="discountAmount"
                        value={formData.discountAmount}
                        onChange={handleDiscountChange}
                        onKeyDown={(e) => {
                          // Prevent default behavior for up and down arrow keys
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        placeholder="Input Discount Amount Here"
                        className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                          errors.discountAmount ? "border-red-500" : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* Offer Price */}
              <div className="mb-6">
                <h1 className="text-zinc-950 text-xs font-semibold font-open mb-2 mt-4">
                  Offer Price (Auto Calculated)
                </h1>
                <div className="flex flex-row">
                  <span
                    style={{ color: "#3CA5DC", fontSize: "150%" }}
                    className="h-full bg-sky-100 flex-1 p-4 flex items-center rounded-md justify-center"
                  >
                    <LiaRupeeSignSolid />
                  </span>
                  <input
                    type="text"
                    name="offerPrice"
                    value={formData.offerPrice}
                    // onClick={handleDiscountChange}
                    disabled={true}
                    placeholder="This field will be auto calculated"
                    className={`w-full placeholder:text-black text-black text-xs font-normal font-open bg-gray-200 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-not-allowed`}
                  />
                </div>
              </div>

              {/* Offer Type */}
              <div className="mb-6">
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Type of Offer
                  <sup className="text-red-600">*</sup>
                </p>
                <div className="flex flex-row justify-start items-center gap-4">
                  <label className="flex flex-row gap-2">
                    <input
                      type="checkbox"
                      value="ONLINE"
                      checked={formData.typeOfOffer === "ONLINE"}
                      onChange={handleOfferTypeChange}
                    />
                    <span>{formData.typeOfOffer === "ONLINE"}</span>
                    <p className="text-zinc-950 text-xs font-semibold font-open">
                      Online
                    </p>
                  </label>
                  <label className="flex flex-row gap-2">
                    <input
                      type="checkbox"
                      value="OFFLINE"
                      checked={formData.typeOfOffer === "OFFLINE"}
                      onChange={handleOfferTypeChange}
                    />
                    <span>{formData.typeOfOffer === "OFFLINE"}</span>
                    <p className="text-zinc-950 text-xs font-semibold font-open">
                      Offline
                    </p>
                  </label>
                </div>
              </div>

              {/* Address */}
              {formData.typeOfOffer === "OFFLINE" && (
                <div className="mb-6">
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Address
                    <sup className="text-red-600">*</sup>
                  </p>
                  {/* Render checkboxes for each address */}
                  <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                    {Object.keys(formattedLocations).map((locationId) => (
                      <label
                        key={locationId}
                        className="flex items-center gap-2 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={formData.addressList.includes(locationId)}
                          onChange={() =>
                            handleAddressCheckboxClick(locationId)
                          }
                          className="form-checkbox h-5 w-5 text-gray-600 rounded"
                        />
                        <span
                          className={`text-black w-full text-xs font-normal font-open bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          {formattedLocations[locationId].length > 40
                            ? formattedLocations[locationId].slice(0, 40) +
                              "..."
                            : formattedLocations[locationId]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                style={{ backgroundColor: "#3CA5DC" }}
                className="text-white w-full px-4 py-2 rounded-md mb-2"
                onClick={submitHandler}
              >
                Submit for Review
              </button>
            </form>
          </div>
        </div>
        {/* Right Card: Black Space */}
        <div className="flex-1 h-full rounded-md">
          <div className="pb-4">
            <h1 className="text-black text-xl font-semibold font-open">
              Preview (On Apps)
            </h1>
          </div>
          <div className="bg-white p-4 h-full rounded-md flex-1">
            {/* Right card content */}
            <div className="flex bg-gray-100 m-2 p-4 flex-col items-center text-center justify-center">
              <h1 className="text-black text-xl font-semibold font-open">
                This is how your offer will look to
              </h1>
              <h1 className="text-black text-xl font-semibold font-open">
                Patients on AllesHealth App
              </h1>
            </div>
            <div style={{ height: "43em" }} className="mt-2 w-full">
              <div
                style={{ marginLeft: "-3.5rem" }}
                className="relative flex justify-center"
              >
                <div
                  style={{ zIndex: isPhoneModel ? "99" : "1" }}
                  className={`absolute`}
                >
                  <PreviewCard1
                    formData={formData}
                    setIsPhoneModel={setIsPhoneModel}
                    img={image}
                    company={company}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "7.5rem",
                    zIndex: isPhoneModel ? "1" : "99",
                  }}
                  className={`absolute`}
                >
                  <PreviewCard
                    formData={formData}
                    setIsPhoneModel={setIsPhoneModel}
                    formattedLocations={formattedLocations}
                    img={image}
                  />
                </div>
              </div>
            </div>

            {/* Arrow btns to navigate */}
            <div className="w-full flex flex-row justify-center items-center gap-20 mt-4 mb-2">
              <div className="flex justify-start items-left mb-2">
                <div
                  style={{ fontSize: "200%", color: "#3CA5DC" }}
                  className="w-10 h-10 p-2 bg-sky-100 rounded-full overflow-hidden flex items-center justify-center"
                >
                  <div
                    className="flex-2 p-2 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPhoneModel(true);
                    }}
                  >
                    <MdOutlineKeyboardArrowLeft />
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-left mb-2">
                <div
                  style={{ fontSize: "200%", color: "#3CA5DC" }}
                  className="w-10 h-10 p-2 bg-sky-100 rounded-full overflow-hidden flex items-center justify-center"
                >
                  <div
                    className="flex-2 p-2 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPhoneModel(false);
                    }}
                  >
                    <MdOutlineKeyboardArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOffer;
