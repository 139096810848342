import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  company: localStorage.getItem("company")
    ? JSON.parse(localStorage.getItem("company"))
    : null,
  companyUsers: localStorage.getItem("companyUsers")
    ? JSON.parse(localStorage.getItem("companyUsers"))
    : null,
  logo: localStorage.getItem("logoURL")
    ? localStorage.getItem("logoURL")
    : null,
  loading: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setCompany(state, action) {
      state.company = action.payload;
      localStorage.setItem("company", JSON.stringify(action.payload));
    },
    setCompanyUsers(state, action) {
      state.companyUsers = action.payload;
      localStorage.setItem("companyUsers", JSON.stringify(action.payload));
    },
    setLogo(state, action) {
      state.logo = action.payload;
      localStorage.setItem("logoURL", action.payload);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setUser, setCompany, setCompanyUsers, setLogo, setLoading } =
  profileSlice.actions;

export default profileSlice.reducer;
