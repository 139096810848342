import React, { useEffect } from "react";
import check from "../assets/Images/icon/check.png";
import building from "../assets/Images/icon/building.png";
import error from "../assets/Images/icon/error.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateStripeTransactionStatus } from "../services/operations/membershipAPI";

function PaymentFailed() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const selectedPlan = localStorage.getItem("selectedPlan")
    ? JSON.parse(localStorage.getItem("selectedPlan"))
    : null;

  const stripeResponse = JSON.parse(localStorage.getItem("stripeResponse"));

  const updateTransactionState = () => {
    let data = {
      PTID: stripeResponse?.PartnerSubscription?.PTID,
      PCID: stripeResponse?.PartnerSubscription?.PCID,
      status_code: "FAILED",
    };
    dispatch(updateStripeTransactionStatus(data, token));
  };

  useEffect(() => {
    if (stripeResponse?.PartnerSubscription?.PTID) {
      console.log("Calling updateTransactionState for PaymentFailed");
      updateTransactionState();
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-full">
      <div className="bg-white p-10 text-center">
        <div>
          <h1 className="text-black text-2xl font-bold font-open">
            Payment Failed
          </h1>
          <p className="text-black text-xl font-normal font-open leading-snug mt-4">
            Payment failed. Please verify your payment details and try again.
          </p>
          <div className="relative bg-white rounded-lg border-4 border-sky-100 flex flex-col items-center justify-center my-10 p-10">
            <p className="text-zinc-500 text-xs font-medium font-inter">
              Membership
            </p>
            <h1 className="text-black text-5xl  font-semibold font-inter my-4">
              {selectedPlan?.price}
            </h1>
            <div className="flex items-center justify-center space-x-3">
              <img
                src={check}
                alt="check icon"
                className="w-6 h-6 object-contain"
              />
              <p className="text-black text-base font-normal font-open">
                Covers up-to {selectedPlan?.branches} branches or collection
                centers
              </p>
              {[...Array(selectedPlan?.branches)].map((_, i) => (
                <img
                  key={i}
                  src={building}
                  alt="building icon"
                  className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                />
              ))}
            </div>
            <div className="flex items-center justify-center space-x-3 mt-6 mb-4">
              <img
                src={check}
                alt="check icon"
                className="w-6 h-6 object-contain"
              />
              <p className="text-black text-base font-normal font-open">
                AllesHealth Commission Fee: {selectedPlan?.commissionFee}
              </p>
            </div>
            <div className="bg-white absolute -bottom-10 px-2">
              <img
                src={error}
                alt="error cross"
                className="w-20 h-20 object-contain "
              />
            </div>
          </div>
        </div>

        {/* Dashboard Button */}
        <button
          onClick={() => {
            navigate("/membership");
          }}
          className=" w-3/4 mt-5 bg-sky-500 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

export default PaymentFailed;
