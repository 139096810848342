import React, { useEffect } from "react";
import check from "../assets/Images/icon/check.png";
import building from "../assets/Images/icon/building.png";
import success from "../assets/Images/icon/success.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMembershipInfo,
  updateStripeTransactionStatus,
} from "../services/operations/membershipAPI";

function UpgradeSuccessful() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { membership, currentPlan, loading } = useSelector(
    (state) => state.membership
  );
  const stripeResponse = JSON.parse(localStorage.getItem("stripeResponse"));

  const updateTransactionState = async () => {
    let data = {
      PTID: stripeResponse?.PartnerSubscription?.PTID,
      PCID: stripeResponse?.PartnerSubscription?.PCID,
      status_code: "SUCCESSFUL",
      subscriptionId: membership?.SubscriptionID,
    };
    await dispatch(updateStripeTransactionStatus(data, token));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await dispatch(getMembershipInfo(token));
      }
    };
    fetchData();
  }, [token, dispatch]);

  useEffect(() => {
    if (membership) {
      updateTransactionState();
    }
  }, [membership]);

  return (
    <div className="flex items-center justify-center min-h-full">
      {loading ? (
        <div className="bg-white p-10 text-center">
          <div>
            <div className="bg-gray-300 rounded-full h-6 w-2/3 mx-auto animate-pulse"></div>
            <div className="bg-gray-300 rounded-full h-6 w-2/4 mx-auto animate-pulse mt-4"></div>
            <div className="relative bg-white rounded-lg border-4 border-gray-300 animate-pulse flex flex-col items-center justify-center my-10 p-10">
              <div className="bg-gray-300 rounded-full h-4 w-1/5 mx-auto animate-pulse"></div>
              <div className="bg-gray-300 rounded-full h-8 w-2/3 mx-auto animate-pulse my-4"></div>
              <div className="flex items-center justify-center space-x-3">
                <div className="bg-gray-300 rounded-full w-6 h-6 mx-auto animate-pulse"></div>
                <div className="bg-gray-300 rounded-full h-6 w-80 animate-pulse"></div>
                {[...Array(currentPlan?.branches)].map((_, i) => (
                  <div className="bg-gray-300 rounded-md w-4 h-4 sm:w-6 sm:h-6 mx-auto animate-pulse"></div>
                ))}
              </div>
              <div className="flex items-center justify-center space-x-3 mt-6 mb-4">
                <div className="bg-gray-300 rounded-full w-6 h-6 mx-auto animate-pulse"></div>
                <div className="bg-gray-300 rounded-full h-6 w-80 animate-pulse"></div>
              </div>
              <div className="bg-white absolute -bottom-10 px-2">
                <div className="bg-gray-300 rounded-full w-20 h-20 mx-auto animate-pulse"></div>
              </div>
            </div>
          </div>

          {/* Dashboard Button */}

          <div className=" w-3/4 mt-10 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open bg-gray-300 mx-auto h-10 animate-pulse"></div>
        </div>
      ) : (
        <div className="bg-white p-10 text-center">
          <div>
            <h1 className="text-black text-2xl font-bold font-open">
              Your Plan is Upgraded to Yearly.
            </h1>
            <p className="text-black text-xl font-normal font-open leading-snug mt-4">
              Yearly plan starts after your current one ends.
            </p>
            <div className="relative bg-white rounded-lg border-4 border-sky-100 flex flex-col items-center justify-center my-10 p-10">
              <p className="text-zinc-500 text-xs font-medium font-inter">
                Membership
              </p>
              <h1 className="text-black text-5xl  font-semibold font-inter my-4">
                {currentPlan?.price}
              </h1>
              <div className="flex items-center justify-center space-x-3">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  Covers up-to {currentPlan?.branches} branches or collection
                  centers
                </p>
                {[...Array(currentPlan?.branches)].map((_, i) => (
                  <img
                    key={i}
                    src={building}
                    alt="building icon"
                    className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                  />
                ))}
              </div>
              <div className="flex items-center justify-center space-x-3 mt-6 mb-4">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  AllesHealth Commission Fee: {currentPlan?.commissionFee}
                </p>
              </div>
              <div className="bg-white absolute -bottom-10 px-2">
                <img
                  src={success}
                  alt="success tick"
                  className="w-20 h-20 object-contain "
                />
              </div>
            </div>
          </div>

          {/* Dashboard Button */}
          <button
            onClick={() => {
              navigate("/dashboardAdmin");
            }}
            className=" w-3/4 mt-5 bg-sky-500 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
          >
            Go to Dashboard
          </button>
        </div>
      )}
    </div>
  );
}

export default UpgradeSuccessful;
