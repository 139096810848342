import { toast } from "react-hot-toast";
import { setLoading } from "../../slices/productSlice";
import { apiConnector } from "../apiConnector";
import { productEndpoints } from "../apis";
import { resetOffersData, updateOffersData } from "../../slices/OffersSlice";
import { setProduct } from "../../slices/productSlice";
import { get } from "lodash";
import { getBucketURL } from "../../utils/regex";

const {
  GET_PRODUCT_API,
  ADD_PRODUCT_API,
  EDIT_PRODUCT_API,
  UPLOAD_PRODUCT_IMAGE_API,
  MARK_SALES_AS_FULFILLED,
  UPLOAD_REPORT_TO_S3,
  SAVE_REPORT,
  SEND_REPORT,
  GET_REPORTS,
  SEND_NOTIFICATION,
} = productEndpoints;

// GET PRODUCTS
// export function getCurrentProducts(token) {
//   return async (dispatch) => {
//     // const toastId = toast.loading("Loading...");
//     dispatch(setLoading(true));
//     try {
//       const response = await apiConnector("GET", GET_PRODUCT_API, null, {
//         Authorization: `Bearer ${token}`,
//       });

//       if (!response?.status === 200) {
//         throw new Error(response?.data?.message);
//       }

//       console.log("GET PRODUCT RESPONSE: ", response);
//       const products = response?.data.map(async (product) => {
//         if (product?.productImageLocation === null) {
//           return product;
//         }

//         const logoURL = await getBucketURL(product?.productImageLocation);
//         product.logoURL = logoURL;
//         return product;
//       });

//       const updatedProducts = await Promise.all(products);
//       console.log('====================================');
//       console.log('UPDATED PRODUCTS: ', updatedProducts);
//       console.log('====================================');
//       dispatch(setProduct(updatedProducts));
//       localStorage.setItem("product", JSON.stringify(response?.data));
//     } catch (error) {
//       console.log("GET_PRODUCT_API ERROR............", error);
//       toast.error("Could Not Get Product Data");
//     }
//     dispatch(setLoading(false));
//     // toast.dismiss(toastId);
//   };
// }
export function getCurrentProducts(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_PRODUCT_API, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const products = response?.data.map(async (product) => {
        if (product?.productImageLocation === null) {
          return product;
        }

        const logoURL = await getBucketURL(product?.productImageLocation);
        product.logoURL = logoURL;

        // Add logoURL and ProductName to each object in the SalesSummaries array
        product.SalesSummaries = await Promise.all(
          product.SalesSummaries.map(async (summary) => {
            if (summary.PhysicalReport !== null) {
              const reportURL = await getBucketURL(summary.PhysicalReport);
              summary.reportURL = reportURL;
            }

            return {
              ...summary,
              logoURL: logoURL,
              ProductName: product.ProductName,
            };
          })
        );

        return product;
      });

      const updatedProducts = await Promise.all(products);
      dispatch(setProduct(updatedProducts));
      localStorage.setItem("product", JSON.stringify(response?.data));
    } catch (error) {
      console.log("GET_PRODUCT_API ERROR:", error);
      toast.error("Could Not Get Product Data");
    }
    dispatch(setLoading(false));
  };
}

// ADD PRODUCTS
export function addCurrentProduct(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_PRODUCT_API, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("ADD PRODUCT RESPONSE: ", response);
      toast.success("Product Added!");
      dispatch(getCurrentProducts(token));
      navigate("/dashboardAdmin");
    } catch (error) {
      console.log("ADD PRODUCT API ERROR :", error);
      toast.error(error?.response?.data?.message || "ADD PRODUCT Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// EDIT PRODUCT
export function editCurrentProduct(token, body, navigate) {
  console.log("====================================");
  console.log("EDIT PRODUCT BODY: ", body);
  console.log("====================================");
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", EDIT_PRODUCT_API, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("EDIT PRODUCT RESPONSE: ", response);
      dispatch(getCurrentProducts(token));
      dispatch(resetOffersData());
      toast.success("Product Edited!");
      navigate("/dashboardAdmin");
    } catch (error) {
      console.log("EDIT PRODUCT API ERROR :", error);
      toast.error(error?.response?.data?.message || "EDIT PRODUCT Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPLOAD PRODUCT IMAGE
export const uploadProductLogo = async (formData, token) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      UPLOAD_PRODUCT_IMAGE_API,
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD PRODUCT IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

// MARK SALES AS FULFILLED
export function markSalesAsFulfilled(token, orderId) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const body = { OrderID: orderId };
      const response = await apiConnector(
        "POST",
        MARK_SALES_AS_FULFILLED,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("MARK SALES AS FULFILLED Response: ", response);
      dispatch(getCurrentProducts(token));
      toast.success("Sales marked as fulfilled successfully!");
    } catch (error) {
      console.log("Error marking sales as fulfilled:", error);
      toast.error(
        error?.response?.data?.message || "Failed to mark sales as fulfilled."
      );
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPLOAD REPORT
export const uploadPatientReport = async (formData, token) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", UPLOAD_REPORT_TO_S3, formData, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD REPORT API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

// SAVE REPORT
export function saveReport(token, body, orderId) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        `${SAVE_REPORT}/${orderId}`,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getCurrentProducts(token));
      toast.success("Report saved successfully!");
    } catch (error) {
      console.log("Error saving report:", error);
      toast.error(error?.response?.data?.message || "Failed to save report.");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// SEND REPORT
export function sendReport(token, body, orderId) {
  console.log("====================================");
  console.log("SEND REPORT BODY: ", body);
  console.log("====================================");
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const data = {
        PhysicalReport: body.PhysicalReport,
        ReportType: body?.ReportType,
      };
      const response = await apiConnector(
        "POST",
        `${SEND_REPORT}/${orderId}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      const ReportID = response?.data?.ReportID;
      // dispatch(getCurrentProducts(token));
      // toast.success("Report sent successfully!");
      // sendNotification(token, body, orderId);
      dispatch(sendNotification(token, body, orderId, ReportID));
    } catch (error) {
      console.log("Error saving report:", error);
      toast.error(error?.response?.data?.message || "Failed to sent report.");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//SEND NOTIFICATION TO USER
export function sendNotification(token, body, orderId, ReportID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const data = {
        OrderID: orderId,
        PhysicalReport: body.PhysicalReport,
        ReportID: ReportID,
        ReportType: body?.ReportType,
      };
      const response = await apiConnector("POST", SEND_NOTIFICATION, data, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getCurrentProducts(token));
      toast.success("Report sent successfully!");
    } catch (error) {
      console.log("Error saving report:", error);
      toast.error(error?.response?.data?.message || "Failed to sent report.");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
// GET DECRYEPTED PDF
export const getDecryptedReport = async (data) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", GET_REPORTS, data);
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("GET DECRYEPTED API ERROR:", error);
    toast.error("Oops! PDF not found. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};
