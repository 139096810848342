import { toast } from "react-hot-toast";
import {
  setCompany,
  setCompanyUsers,
  setLoading,
  setLogo,
  setUser,
} from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { userEndpoints } from "../apis";
import { logout } from "./authAPI";
import { ACCOUNT_TYPE, MERCHANT_TYPE } from "../../utils/constants";
import { getBucketURL } from "../../utils/regex";

const { GET_USER_DETAILS_API } = userEndpoints;
const { GET_ALL_COMPANY_USERS_API, ADD_COMPANY_USERS_API, EDIT_COMPANY_USERS_API } = userEndpoints;

//Get User Details
export function getUserDetails(token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      console.log("GET_USER_DETAILS API RESPONSE............", response);

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const userImage = response?.data?.user?.image
        ? response?.data?.user?.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;

      const AccountStatus = response?.data?.companyDetails?.AccountStatus;

      dispatch(
        setUser({
          ...response?.data?.user,
          image: userImage,
          CountryCode: "+91",
        })
      );

      const result = {
        ...response?.data?.companyDetails,
        AccountStatus: AccountStatus,
      }
      getBucketURL(result?.LogoLocation).then((res) => {
        // localStorage.setItem("logoURL", res);
        dispatch(setLogo(res));
      });
      // localStorage.setItem("formData", JSON.stringify(result));
      dispatch(setCompany(result));

      if (response?.data?.user?.UserType === ACCOUNT_TYPE.SALESAGENT) {
        navigate("/partner-form");
      } else {
        if (AccountStatus !== MERCHANT_TYPE.PRIMARY_ACCOUNT) {
          toast.dismiss(toastId);
          dispatch(setLoading(false));
          return;
          // navigate("/reset-password");
        }
        else navigate("/dashboardAdmin");
      }

    } catch (error) {
      dispatch(logout(navigate));
      console.log("GET_USER_DETAILS API ERROR............", error);
      toast.error("Session Expired");
    }
    toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}

//VALIDATE SESSION
export function validateSession(token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      console.log("SESSION VALIDATE API RESPONSE............", response);

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      dispatch(logout(navigate));
      console.log("SESSION VALIDATE API ERROR............", error);
      toast.error("Session Expired");
    }
    toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}

export function getCompanyProfileDetails(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      const result = response?.data?.companyDetails;
      console.log(
        "GET_COMPANY_PROFILE_DETAILS_API RESPONSE............",
        result
      );
      getBucketURL(result?.LogoLocation).then((res) => {
        dispatch(setLogo(res));
      });

      dispatch(setCompany(result));
    } catch (error) {
      console.log("GET_COMPANY_PROFILE_DETAILS_API ERROR............", error);
      toast.error("Could Not Get Data");
    }
    // toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}

export function getCompanyUserDetails(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_COMPANY_USERS_API, null, {
        Authorization: `Bearer ${token}`,
      });

      const companyUsers = response?.data;

      dispatch(setCompanyUsers(companyUsers));

      // Store company users in local storage
      localStorage.setItem("companyUsers", JSON.stringify(companyUsers));

      const logoLocation = companyUsers?.LogoLocation;
      if (logoLocation) {
        const logoURL = await getBucketURL(logoLocation);
        dispatch(setLogo(logoURL));
        localStorage.setItem("logoURL", logoURL);
      }

    } catch (error) {
      console.log("GET_COMPANY_USER_DETAILS_API ERROR:", error);
      toast.error("Could Not Get Company User Details");
    }
    dispatch(setLoading(false));
  };
}

// ADD COMPANY USER
export function addNewCompanyUser(token, userData) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_COMPANY_USERS_API, userData, {
        Authorization: `Bearer ${token}`,
      });

      if (response?.error) {
        // throw new Error(response?.data?.message);
        toast.error(response?.data?.message);
      }

      toast.success("New company user added successfully!");

    } catch (error) {
      toast.error(error?.response?.data?.message)
      // console.log("ADD_COMPANY_USER_API ERROR:", error);
      // if (error?.response?.status === 409)
      //   toast.error("This email is already assigned to another user.")
      // else toast.error("Failed to add new company user");
    }
    dispatch(setLoading(false));
  };
}

// EDIT COMPANY USER
export function editCompanyUser(token, userData) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", EDIT_COMPANY_USERS_API, userData, {
        Authorization: `Bearer ${token}`,
      });

      if (response.error) {
        throw new Error(response?.data?.message);
      } else
        toast.success("Company user updated successfully!");
    } catch (error) {
      console.log("EDIT_COMPANY_USER_API ERROR:", error);
      toast.error("Failed to update company user!");
    }
    dispatch(setLoading(false));
  };
}