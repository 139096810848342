import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  registerMerchant,
  resetPassword,
  updateUserActiveStatus,
  verifyMerchantOTP,
} from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import resetLogo from "../assets/Images/resetPasswordLogo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MERCHANT_TYPE } from "../utils/constants";

function ResetPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.profile);
  const {user} = useSelector((state) => state.profile);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const Otp = localStorage.getItem("resetOtp");
  const isForgotPassword = localStorage.getItem("isForgotPassword");

  let token;
  if (company?.AccountStatus !== MERCHANT_TYPE.PRIMARY_ACCOUNT) {
    token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
  }
  if(isForgotPassword){ token = localStorage.getItem("resetToken");}
  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const { oldPassword, password, confirmPassword } = formData;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle Form Submission
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }

    let data = {};

    if (company?.AccountStatus !== MERCHANT_TYPE.PRIMARY_ACCOUNT) {
      data = {
        oldPassword: oldPassword,
        newPassword: password,
      };
    }
    if(isForgotPassword) {
      data = {
        Otp: Otp,
        newPassword: password,
      };
    }
    dispatch(resetPassword(token, data, navigate));

    if(!isForgotPassword){
      let Editdata = {
        PID: user.PID,
        ActiveStatus: "ACTIVATED"
      }
      dispatch(updateUserActiveStatus(token, Editdata, navigate));
    }
  };
  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg py-10 px-5 w-[420px]">
          <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
          <img
            src={resetLogo}
            alt="logo"
            className="w-64 h-48  object-contain mt-5"
          />

          <form onSubmit={handleOnSubmit} className="mt-8">
            {/* password */}
            <div className="flex flex-col">
              {!isForgotPassword && company?.AccountStatus !== MERCHANT_TYPE.PRIMARY_ACCOUNT && (
                <>
                  {/* Enter Old Password */}
                  <div className="relative flex-1 mb-4">
                    <label>
                      <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                        Enter Old Password
                      </p>
                      <input
                        className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                        required
                        type={showOldPassword ? "text" : "password"}
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleOnChange}
                        placeholder="Enter old Password"
                      />
                      <span
                        onClick={() => setShowOldPassword((prev) => !prev)}
                        className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
                      >
                        {showOldPassword ? (
                          <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                        ) : (
                          <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                        )}
                      </span>
                    </label>
                  </div>
                </>
              )}
              {/* Enter New Password */}
              <div className="relative flex-1 mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Enter New Password
                  </p>
                  <input
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleOnChange}
                    placeholder="Enter Password"
                  />
                  <span
                    onClick={() => setShowPassword((prev) => !prev)}
                    className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                    )}
                  </span>
                </label>
              </div>
              {/* Confirm Password */}
              <div className="relative flex-1">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Confirm Password
                  </p>
                  <input
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleOnChange}
                    placeholder="Confirm Password"
                  />
                  <span
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                    )}
                  </span>
                </label>
              </div>
            </div>

            <div className="mt-20">
              <button
                type="submit"
                className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordForm;
