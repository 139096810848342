import React, { useState, useEffect } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { AiOutlineExclamation } from "react-icons/ai";
import Modal from "react-modal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { MERCHANT_TYPE } from "../utils/constants";
import { IoEyeOutline } from "react-icons/io5";
import {
  addNewCompanyUser,
  editCompanyUser,
  getCompanyUserDetails,
} from "../services/operations/profileAPI";
import { updateUserActiveStatus } from "../services/operations/authAPI";
import { useNavigate } from "react-router-dom";

const UserPermission = () => {
  // MODAL part
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen_del, setIsOpen_del] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { company, loading: companyLoading } = useSelector((state) => state.profile);
  const { companyUsers, loading: companyUsersLoading } = useSelector((state) => state.profile);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [originalEmail, setOriginalEmail] = useState({});
  const [id, setId] = useState("");

  // MODAL part
  Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "40rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      backgroundColor: "white",
    },
  };
  Modal.setAppElement(document.getElementById("root"));

  function openModal() {
    setIsOpen(true);
  }
  function openModal_del() {
    setIsOpen_del(true);
  }

  function closeModal() {
    setId("");
    setIsEdit(false);
    // Clear formData state
    setFormData(
      Object.fromEntries(Object.keys(formData).map((key) => [key, null]))
    );
    setFormData(
      Object.fromEntries(Object.keys(originalEmail).map((key) => [key, null]))
    );
    setErrors(
      Object.fromEntries(Object.keys(errors).map((key) => [key, null]))
    );
    setIsOpen(false);
    dispatch(getCompanyUserDetails(token));
  }

  function closeModal_del() {
    // Clear formData state
    setFormData(
      Object.fromEntries(Object.keys(formData).map((key) => [key, null]))
    );
    setErrors(
      Object.fromEntries(Object.keys(errors).map((key) => [key, null]))
    );
    setIsOpen_del(false);
    dispatch(getCompanyUserDetails(token));
  }

  const handleEditEmployee = (emp) => {
    setIsEdit(true);
    setId(emp.PIDToEdit);
    // Store the original details before editing
    setOriginalEmail(emp.Email);
    // Set formData state with the data of the employee being edited
    setFormData({
      PIDToEdit: emp.PIDToEdit,
      Name: emp.Name,
      UserPosition: emp.UserPosition,
      Email: emp.Email,
      AccessLevel: emp.AccessLevel,
      ActiveStatus: emp.ActiveStatus,
    });
    // Open the modal
    openModal();
  };

  const handleDelEmployee = (emp) => {
    setFormData({
      PIDToEdit: emp.PIDToEdit,
      Name: emp.Name,
      Email: emp.Email,
    })
    openModal_del();
  }

  const [formData, setFormData] = useState({
    PIDToEdit: "",
    Name: "",
    UserPosition: "",
    Email: "",
    AccessLevel: "",
    ActiveStatus: "",
  });
  const [errors, setErrors] = useState({
    Name: "",
    UserPosition: "",
    Email: "",
    AccessLevel: "",
    ActiveStatus: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeleteUser = (PIDToEdit) => {
    let Editdata = {
      PID: PIDToEdit,
      ActiveStatus: "DELETED",
    };
    dispatch(updateUserActiveStatus(token, Editdata, navigate));
    toast.success("User Deleted Successfully");
    handleModalClose_del();
  };

  const handleAccessLevelChange = (accessLevel) => {
    setFormData({
      ...formData,
      AccessLevel: accessLevel,
    });
  };

  const submitHandler = async(event) => {
    event.preventDefault();
    const mandatoryFields = ["Name", "Email", "UserPosition", "AccessLevel"];
    const newErrors = {};
    mandatoryFields.forEach((key) => {
      const value = formData[key];
      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    // Compare formData with the original details
    const changedFields = { ...formData };
    // Check if email has changed
    if (formData.Email !== originalEmail) {
      changedFields.Email = formData.Email;
    } else {
      delete changedFields.Email;
    }

    // Remove ActiveStatus from changedFields
    delete changedFields.ActiveStatus;
    // Ensure PIDToEdit is always included in changedFields
    changedFields.PIDToEdit = formData.PIDToEdit;

    if (Object.keys(changedFields).length === 1) {
      toast.error("No changes detected.");
      handleModalClose();
      return;
    }

    if (isEdit) {
      await dispatch(editCompanyUser(token, changedFields));
    } else {
      await dispatch(addNewCompanyUser(token, changedFields));
    }

    handleModalClose();
  }

  const handleModalClose = () => {
    closeModal();
    dispatch(getCompanyUserDetails(token));
  };
  const handleModalClose_del = () => {
    closeModal_del();
    dispatch(getCompanyUserDetails(token));
  };

  useEffect(() => {
    dispatch(getCompanyUserDetails(token));
  }, []);

  useEffect(() => {
    if (companyUsers) {
      setData(
        companyUsers.map((emp) => ({
          PIDToEdit: emp.PID || "",
          Name: emp.Name || "",
          UserPosition: emp.UserPosition || "",
          AccessLevel: emp.AccountStatus || "",
          ActiveStatus: emp.ActiveStatus || "",
          Email: emp.Email || "",
        }))
      );
    }
  }, [companyUsers]);

  console.log("====================================");
  console.log(data);
  console.log("====================================");

  if (companyLoading || companyUsersLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    )
  }
  else {

    return (
      <div className="bg-sky-100 min-h-screen py-2 px-4 sm:py-5 sm:px-10 scroll-smooth">
        {/* ADD MODAL */}
        <div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Employee Modal"
          >
            {/* Modal Header */}
            <div className="m-5">
              <div className="flex flex-row justify-between items-center mb-6">
                <h2 className="text-black text-lg font-bold font-open pb-2">
                  Add New Employee
                </h2>
                <button
                  onClick={closeModal}
                  style={{ color: "#ACACAC", fontSize: "1.2em" }}
                  className="pb-2"
                >
                  <IoIosCloseCircleOutline />
                </button>
              </div>
              <form>
                {/* Employee Name */}
                <div className="mb-6">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      Name
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="Name"
                      value={formData.Name}
                      onChange={handleOnChange}
                      maxLength={30}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED"
                      }
                      placeholder="Input name here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${errors.EmployeeName ? "border-red-500" : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* Employee Position */}
                <div className="mb-6">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      Position
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="UserPosition"
                      value={formData.UserPosition}
                      onChange={handleOnChange}
                      maxLength={40}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED"
                      }
                      placeholder="Input department here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${errors.EmployeePosition ? "border-red-500" : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* Employee Email */}
                <div className="mb-6">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      Email
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="Email"
                      value={formData.Email}
                      onChange={handleOnChange}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED" ||
                        (company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER &&
                          formData?.AccessLevel === MERCHANT_TYPE.PRIMARY_ACCOUNT)
                      }
                      maxLength={40}
                      placeholder="Input email here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${errors.EmployeeEmail ? "border-red-500" : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* Access Level */}
                <div className="mb-2">
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Access Level <sup className="text-red-600">*</sup>
                  </p>
                  {formData?.AccessLevel === MERCHANT_TYPE.PRIMARY_ACCOUNT ? (
                    <>
                      <button
                        type="button"
                        disabled={true}
                        className={`w-full text-sm font-semibold px-4 py-4 rounded-md mb-2 text-[#3CA5DC] bg-sky-100`}
                      >
                        Primary Account
                      </button>
                    </>
                  ) : (
                    <>
                      {company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER &&
                        formData?.AccessLevel === MERCHANT_TYPE.ACCOUNT_OWNER ? (
                        <>
                          <button
                            type="button"
                            disabled={true}
                            className={`w-full text-sm font-semibold px-4 py-4 rounded-md mb-2 text-[#3CA5DC] bg-sky-100`}
                          >
                            Account Owner
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="flex gap-4">
                            <button
                              type="button"
                              className={`w-full text-sm font-semibold px-4 py-4 rounded-md mb-2 text-[#3CA5DC] ${formData.AccessLevel === "SuperAdmin"
                                ? "bg-sky-100"
                                : "bg-gray-100"
                                }`}
                              onClick={() =>
                                handleAccessLevelChange("SuperAdmin")
                              }
                              disabled={
                                company?.AccountStatus ===
                                MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED"
                              }
                            >
                              Account Owner
                            </button>
                            <button
                              type="button"
                              className={`w-full text-sm font-semibold px-4 py-4 rounded-md mb-2 text-[#3CA5DC] ${formData.AccessLevel === "Admin"
                                ? "bg-sky-100"
                                : "bg-gray-100"
                                }`}
                              onClick={() => handleAccessLevelChange("Admin")}
                              disabled={
                                company?.AccountStatus ===
                                MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED"
                              }
                            >
                              Administrator
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* Dynamic text based on Access Level */}
                {formData?.AccessLevel === MERCHANT_TYPE.PRIMARY_ACCOUNT ? (
                  <></>
                ) : (
                  <>
                    {formData?.AccessLevel === MERCHANT_TYPE.ADMINISTRATOR ||
                      formData.AccessLevel === null ? (
                      <div className="mb-6">
                        <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                          Administrator
                        </p>
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "1.5rem" }}
                        >
                          <li className="text-zinc-950 text-xs font-normal font-open mb-2">
                            List and Access offers
                          </li>
                          <li className="text-zinc-950 text-xs font-normal font-open mb-2">
                            Monitor Sales
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div className="mb-6">
                        <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                          Account Owner
                        </p>
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "1.5rem" }}
                        >
                          <li className="text-zinc-950 text-xs font-normal font-open mb-2">
                            Full Access of the account
                          </li>
                          <li className="text-zinc-950 text-xs font-normal font-open mb-2">
                            Can add or delete people
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                )}

                {/* Submit Button */}
                {company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || formData.ActiveStatus === "DELETED" || formData.ActiveStatus === "DEACTIVATED" ||
                  (company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER &&
                    formData.LocationType === "MAIN") ? (
                  <>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#3CA5DC" }}
                      className="text-white w-full px-4 py-2 rounded-md mt-4"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#3CA5DC" }}
                      className="text-white w-full px-4 py-2 rounded-md mt-4"
                      onClick={submitHandler}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR ||
                        (company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER &&
                          formData.LocationType === "MAIN")
                      }
                    >
                      Proceed
                    </button>
                  </>
                )}
              </form>
            </div>
          </Modal>
        </div>

        {/* DELETE MODAL */}
        <div>
          <Modal
            isOpen={modalIsOpen_del}
            onRequestClose={closeModal_del}
            style={customStyles}
            contentLabel="DeleteEmployee Modal"
          >
            {/* Modal Header */}
            <div className="m-5">
              <div className="flex flex-row justify-between items-center mb-6">
                <h2 className="text-black text-lg font-bold font-open pb-2">
                  Delete Employee
                </h2>
                <button
                  onClick={closeModal_del}
                  style={{ color: "#ACACAC", fontSize: "1.2em" }}
                  className="pb-2"
                >
                  <IoIosCloseCircleOutline />
                </button>
              </div>
              <div>
                <p className="text-black text-sm font-normal font-open mb-4">
                  Are you sure you want to delete this employee?
                </p>
                <p className="text-black text-sm font-normal font-open mb-4">
                  Employee Name: <span style={{ color: "#3CA5DC" }}>{formData.Name}</span>
                </p>
                <p className="text-black text-sm font-normal font-open mb-4">
                  Employee Email: <span style={{ color: "#3CA5DC" }}>{formData.Email}</span>
                </p>
                <button
                  type="button"
                  style={{ backgroundColor: "#EF4444" }}
                  className="text-white w-full px-4 py-2 rounded-md mt-4"
                  onClick={(e) => { handleDeleteUser(formData.PIDToEdit) }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: "#3CA5DC" }}
                  className="text-white w-full px-4 py-2 rounded-md mt-2"
                  onClick={closeModal_del}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </div>

        {/* Header */}
        {/* <div className="pb-6 pt-2 flex flex-row justify-between items-center">
                <h1 className="text-black text-xl font-semibold font-open">
                    User Permission
                </h1>
                <div className="flex text-white basis-1/4 justify-end">
                    <button
                        type="button"
                        className="bg-[#3CA5DC] font-normal text-sm py-2 flex flex-row gap-2 items-center justify-center rounded-md px-1 w-[12rem]"
                        onClick={openModal}
                    ><span><FaPlus /></span>Add New Employee
                    </button>
                </div>
            </div> */}

        {/* Branch Card */}
        {/* <div className="bg-white h-full rounded-md flex flex-row justify-between items-center px-4 py-4">
                <div className="flex basis-3/4 flex-row justify-start gap-4 items-center">
                    <div className="flex">
                        <img
                            src={BranchPic}
                            alt="Branch Pic"
                            className="w-30 h-20 object-contain"
                        />
                    </div>
                    <div className="flex flex-col">
                        <h1 className="text-black text-lg font-semibold font-open pb-2">
                            Clinic Wesword
                        </h1>
                        <p className="text-black text-sm font-normal font-open pb-2">
                            Al Thanyah 2, Hadaeq Mohammed Bin Rashid, Dubai
                        </p>
                        <p className="text-black text-sm font-normal font-open">
                            +89 0947582734
                        </p>
                    </div>
                </div>
            </div> */}

        {/* Employees */}
        <div className="pb-6 pt-2 flex flex-row justify-between items-center">
          <h1 className="text-black text-xl font-semibold font-open">
            Employees Registered
          </h1>
          <div className="flex text-white basis-1/4 justify-end">
            {/* Add New Employee */}
            {(company?.AccountStatus === MERCHANT_TYPE.PRIMARY_ACCOUNT ||
              company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER) && (
                <>
                  <button
                    type="button"
                    className="bg-[#3CA5DC] font-normal text-sm py-2 flex flex-row gap-2 items-center justify-center rounded-md px-1 w-[12rem]"
                    onClick={openModal}
                  >
                    <span>
                      <FaPlus />
                    </span>
                    Add New Employee
                  </button>
                </>
              )}
          </div>
        </div>
        <div className="bg-white h-full rounded-md flex-1 px-4">
          <div className="grid grid-cols-5 gap-4 justify-between items-center py-5 px-4">
            <h3 className="text-black text-sm font-semibold font-open">Name</h3>
            <h3 className="text-black text-sm font-semibold font-open">
              Position
            </h3>
            <h3 className="text-black text-sm font-semibold font-open">
              Access Level
            </h3>
            <h3 className="text-black text-sm font-semibold font-open">Status</h3>
            <h3 className="text-black text-center text-sm font-semibold font-open">
              Action
            </h3>
          </div>
          <hr />
          {data.map((emp) => (
            <>
              <div
                key={emp.PIDToEdit}
                className="grid grid-cols-5 gap-4 justify-between items-center py-5 px-4"
              >
                <p className="text-black text-sm font-normal font-open">
                  {emp.Name}
                </p>
                <p className="text-black text-sm font-normal font-open">
                  {emp.UserPosition}
                </p>
                <p className="text-black text-sm font-normal font-open">
                  {emp.AccessLevel === "Primary"
                    ? "Primary"
                    : emp.AccessLevel === "SuperAdmin"
                      ? "Account Owner"
                      : "Administrator"}
                </p>
                <div
                  className={`${emp.ActiveStatus === "ACTIVATED" && "text-green-500"}
                  ${emp.ActiveStatus === "PENDING" && "text-gray-500"}
                  ${emp.ActiveStatus === "DELETED" && "text-red-500"}
                  ${emp.ActiveStatus === "DEACTIVATED" && "text-red-500"}`}
                >
                  {emp.ActiveStatus === "ACTIVATED" && (
                    <>
                      <p className=" w-24 px-2.5 py-1 justify-center items-center gap-2.5 inline-flex bg-lime-100 rounded text-lime-500 text-xs font-normal font-open">
                        Activated
                      </p>
                    </>
                  )}
                  {emp.ActiveStatus === "PENDING" && (
                    <>
                      <p className=" w-24 px-2.5 py-1 justify-center items-center gap-2.5 inline-flex bg-gray-100 rounded text-gray-500 text-xs font-normal font-open">
                        Pending
                      </p>
                    </>
                  )}
                  {emp.ActiveStatus === "DEACTIVATED" &&
                    (
                      <>
                        <p className=" w-24 px-2.5 py-1 justify-center items-center gap-2.5 inline-flex bg-red-100 rounded text-rose-500 text-xs font-normal font-open">
                          Deactivated
                        </p>
                      </>
                    )}
                  {emp.ActiveStatus === "DELETED" &&
                    (
                      <>
                        <p className=" w-24 px-2.5 py-1 justify-center items-center gap-2.5 inline-flex bg-red-100 rounded text-rose-500 text-xs font-normal font-open">
                          Deleted
                        </p>
                      </>
                    )}
                </div>

                <div className="flex flex-row justify-center items-center">
                  {company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || emp.ActiveStatus === "DELETED" || emp.ActiveStatus === "DEACTIVATED" ? (
                    <button
                      type="button"
                      style={{ color: "#3CA5DC" }}
                      className="flex flex-row items-center justify-center gap-2 pr-4 pl-4"
                      onClick={() => handleEditEmployee(emp)}
                    >
                      <span>
                        <IoEyeOutline />
                      </span>
                      View
                    </button>
                  ) : (
                    <>
                      {company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER &&
                        (emp?.AccessLevel === MERCHANT_TYPE.ACCOUNT_OWNER ||
                          emp?.AccessLevel === MERCHANT_TYPE.PRIMARY_ACCOUNT) ? (
                        <>
                          <button
                            type="button"
                            style={{ color: "#3CA5DC" }}
                            className="flex flex-row items-center justify-center gap-2 pr-4 pl-4"
                            onClick={() => handleEditEmployee(emp)}
                          >
                            <span>
                              <MdEdit />
                            </span>
                            Edit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            style={{ color: "#3CA5DC" }}
                            className="flex flex-row items-center justify-center gap-2 pr-4 pl-4"
                            onClick={() => handleEditEmployee(emp)}
                          >
                            <span>
                              <MdEdit />
                            </span>
                            Edit
                          </button>
                          {company?.AccountStatus ===
                            MERCHANT_TYPE.ADMINISTRATOR ||
                            (company?.AccountStatus ===
                              MERCHANT_TYPE.ACCOUNT_OWNER &&
                              (emp?.AccessLevel === MERCHANT_TYPE.ACCOUNT_OWNER ||
                                emp?.AccessLevel ===
                                MERCHANT_TYPE.PRIMARY_ACCOUNT)) ||
                            (company?.AccountStatus ===
                              MERCHANT_TYPE.PRIMARY_ACCOUNT &&
                              emp?.AccessLevel ===
                              MERCHANT_TYPE.PRIMARY_ACCOUNT) ? (
                            <></>
                          ) : (
                            <button
                              type="button"
                              style={{
                                borderLeft: "1px solid #ACACAC",
                                color: "#DF535F",
                              }}
                              className="flex flex-row items-center justify-center gap-2 pl-4"
                              onClick={(e) => {
                                handleDelEmployee(emp);
                              }}
                            >
                              <span>
                                <RiDeleteBinLine />
                              </span>
                              Delete
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>
    );
  }
};

export default UserPermission;
