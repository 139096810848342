import React, { useState, useEffect } from "react";
import Faq from "../assets/Images/Capa_1.png";
import bgCircle from "../assets/Images/Ellipse 369.png";
import head from "../assets/Images/Head.png";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const FAQ = () => {
  return (
    <div
      style={{ overflowX: "hidden" }}
      className="bg-sky-100 min-h-screen py-2 px-4 sm:py-5 sm:px-10 scroll-smooth"
    >
      {/* Header */}
      <div className="pb-2 pt-2">
        <h1 className="text-black text-xl font-semibold font-open flex flex-row gap-4 items-center">
          FAQs
          <span className="mt-[-1em]">
            <img src={Faq} alt="FAQs" className="w-10 h-20 object-contain" />
          </span>
        </h1>
      </div>

      {/* Cards */}
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 justify-between items-center py-5">
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            What is the Commission Charge?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - Platform Commission charge will be as follows and will be
            automatically deducted from the total price.
            <ul style={{ listStyleType: "disc", marginLeft: "1.5rem" }}>
              <li>8% for Monthly Membership</li>
              <li>6% for Yearly Membership</li>
            </ul>
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            How many customers do you have on your platform?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - AllesHealth has a growing number of Patients on-track to reach 10
            Lacs patients by the end of 2024.
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            What is Membership Fee for?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - Membership fees cover the cost for Platform, Listing the Services
            on AllesHealth Platform and to continuously get increased Sales
            through AllesHealth.
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            How will we be notified when customers book a service?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-4">
            - For every purchase of one of your offerings, an email will be sent
            with confirmation. Current sales orders can also be found on Partner
            Portal at
          </h3>
          <h3
            style={{ overflow: "hidden" }}
            className="text-[#DF535F] text-sm font-semibold font-open p-4 px-8 pb-8"
          >
            <a href="https://partner.alleshealth.com">
              https://partner.alleshealth.com
            </a>
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            What will AllesHealth gain from this partnership?{" "}
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - AllesHealth works to improve lives of Patients and Carers and
            strives to provide the right information needed to make Patients
            lives better. We recognise that by partnering with Healthcare
            Service Providers we can achieve that mission.
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            Do we need to share the patient reports on your platform?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - NO, it is not required. Although AllesHealth can help you to
            improve your Digital Customer engagement. Please reach out to the
            Sales Representative for further discussion.
          </h3>
        </div>
        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            How will Healthcare Service Provider be paid?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - Payout will be every 15 days, with payments scheduled for the 15th
            and 30th of each month, directly to the provided Bank Account.
          </h3>
        </div>

        <div className="bg-white h-full rounded-md">
          <h3 className="text-[#8A8A8A] text-sm font-semibold font-open p-4 px-8 pt-8">
            What is the cost of Membership?
          </h3>
          <h3 className="text-[#3CA5DC] text-sm font-semibold font-open p-4 px-8 pb-8">
            - Monthly INR 250 and Yearly 2500 per customer site.
          </h3>
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-row justify-between items-center">
        {/* Contacts */}
        <div className="flex flex-col justify-center items-left text-left">
          <h1
            style={{ borderBottom: "2px solid black" }}
            className="text-black text-xl font-semibold font-open flex flex-row gap-4 items-center mb-2 pb-1"
          >
            Reach out to us at:
          </h1>
          <a
            href="mailto:info@alleshealth.com"
            className="flex flex-row gap-4 items-center text-black font-semibold pt-2"
          >
            <span className="text-xl">
              <MdEmail />
            </span>
            <span>info@alleshealth.com</span>
          </a>
          {/* <a href="tel:+917011154768" className="flex flex-row gap-4 items-center text-black font-semibold pt-2">
                        <span className="text-xl"><FaPhone /></span>
                        <span>+917011154768</span>
                    </a> */}
          <a
            href="https://t.me/alleshealth"
            className="flex flex-row gap-4 items-center text-black font-semibold pt-2"
          >
            <span className="text-xl">
              <FaTelegramPlane />
            </span>
            <span>@alleshealth</span>
          </a>
          <div className="flex flex-row gap-4 items-center text-black font-semibold pt-2">
            <span className="text-xl">
              <FaLocationDot />
            </span>
            <div>
              <a href="https://www.google.com/maps?q=Basel,%20Switzerland">
                Basel, Switzerland
              </a>
              <br />
              <a href="https://www.google.com/maps?q=Delhi,%20India">
                Delhi, India
              </a>
            </div>
          </div>
        </div>

        {/* PNG */}
        <div
          className="relative ml-[-1em]"
          style={{ transform: " translate(6.5em, 0px)" }}
        >
          <img
            src={bgCircle}
            alt="Footer Img"
            className="w-96 h-96 object-contain"
          />
          <img
            src={head}
            alt="Footer Img"
            className="w-56 h-56 object-contain absolute left-[6em] top-11"
          />
        </div>
      </div>
    </div>
  );
};
export default FAQ;
