import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import emailIcon from "../assets/Images/icon/email.png";
import phoneIcon from "../assets/Images/icon/phone.png";
import toast from "react-hot-toast";

function LoginForm() {
  const savedInfo = localStorage.getItem("savedInfo")
    ? JSON.parse(localStorage.getItem("savedInfo"))
    : "";
  const [loginType, setLoginType] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Email: savedInfo.Email ? savedInfo.Email : "",
    Phone: savedInfo.Phone ? savedInfo.Phone : "",
    Password: savedInfo ? savedInfo.Password : "",
    rememberMe: savedInfo ? savedInfo.RememberMe : false,
  });

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  const handleOnChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (loginType === "0") {
      if (!formData.Email.includes("@")) {
        toast.error("Please enter a valid email");
        return;
      }
    } else if (loginType === "1") {
      if (formData.Phone.length !== 10) {
        toast.error("Please enter a valid phone number");
        return;
      }
    }

    // Check the loginType and update the formData accordingly
    if (loginType === "0") {
      dispatch(
        login(
          formData.Email,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    } else if (loginType === "1") {
      dispatch(
        login(
          formData.Phone,
          formData.Password,
          loginType,
          formData.rememberMe,
          navigate
        )
      );
    }
  };

  return (
    <div className="bg-white rounded-lg py-10 px-5">
      <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
      <h1 className="text-black text-3xl font-bold font-Open mt-8">Welcome</h1>

      {/* tab button */}
      <div className="flex items-center space-x-6 mt-6">
        <Link to="/login" className="block">
          <div className="text-black text-sm font-semibold font-open">
            Existing AllesHealth Partner
            <div className="w-full h-1 bg-gradient-to-br from-blue-500 to-cyan-300 rounded mt-2"></div>
          </div>
        </Link>
        <Link to="/signup" className="block">
          <div className="text-neutral-400 text-sm font-semibold font-open">
            New AllesHealth Partner
            <div className="w-full h-1 bg-sky-100 rounded mt-2"></div>
          </div>
        </Link>
      </div>

      {/* login type - email/phone number  */}
      <div className="justify-start items-start gap-3.5 inline-flex mt-8">
        <button
          className={`py-1 px-5 rounded-3xl ${
            loginType === "0"
              ? "bg-sky-500 text-sky-100"
              : "bg-sky-100 text-sky-400"
          } justify-center items-center inline-flex text-xs font-semibold font-open`}
          onClick={() => handleLoginTypeChange("0")}
        >
          Email
        </button>
        <button
          className={`py-1 px-5 rounded-3xl ${
            loginType === "1"
              ? "bg-sky-500 text-sky-100"
              : "bg-sky-100 text-sky-400"
          } justify-center items-center inline-flex text-xs font-semibold font-open`}
          onClick={() => handleLoginTypeChange("1")}
        >
          Phone Number
        </button>
      </div>

      {/* login form */}
      <div className="mt-6">
        <form onSubmit={handleOnSubmit}>
          {loginType === "0" ? (
            <>
              {/* Email */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Your Email
                  </p>
                  <div className="flex items-center">
                    <div className="bg-sky-100 rounded-l-md p-3 border-none">
                      <img
                        src={emailIcon}
                        alt="email"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="text"
                      name="Email"
                      value={loginType === "0" ? formData.Email : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Email here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-r-md p-4 border-none focus:outline-none focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              {/* Phone Number */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Your Phone Number
                  </p>
                  <div className="flex items-center">
                    <div className="bg-sky-100 rounded-l-md p-3 border-none">
                      <img
                        src={phoneIcon}
                        alt="phoneIcon"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="tel"
                      inputMode="tel"
                      maxLength={10}
                      name="Phone"
                      value={loginType === "1" ? formData.Phone : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Phone Number here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-r-md p-4 border-none focus:outline-none focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>
            </>
          )}

          {/* password */}
          <div className="relative">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Password
              </p>
              <input
                className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                required
                type={showPassword ? "text" : "password"}
                name="Password"
                value={formData.Password}
                onChange={handleOnChange}
                placeholder="Input your password here"
              />
            </label>
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
          </div>

          {/* remember me */}
          <div className="flex items-center justify-start mt-3 ml-2">
            <label
              className="relative flex cursor-pointer items-center"
              htmlFor="rememberMe"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                defaultChecked={formData.rememberMe}
                onChange={handleOnChange}
                className="before:content[''] peer relative h-4 w-4 border-2 border-sky-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
              />
              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth={1}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </label>

            <p className="text-sky-400 text-xs font-semibold font-open ml-2">
              Remember Me
            </p>
          </div>

          <div className="flex justify-end mt-2 mb-6">
            <Link
              to="/forgot-password"
              className="text-sky-400 text-xs font-semibold font-open"
            >
              Forgot Password?
            </Link>
          </div>

          {/* login button */}
          <div className="">
            <button
              type="submit"
              className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
