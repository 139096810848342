import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestStripePayment } from "../services/operations/membershipAPI";
import Constant from "../config/Constant";

function PaymentProcessing() {
  console.log("====================================");
  console.log("SUCCESS_URL", Constant.SUCCESS_URL);
  console.log("====================================");
  const { token } = useSelector((state) => state.auth);
  const { user, company } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [stripeResponse, setStripeResponse] = useState("");
  const selectedPlan = localStorage.getItem("selectedPlan")
    ? JSON.parse(localStorage.getItem("selectedPlan"))
    : null;

  const handleRequestStripePayment = async () => {
    let data = {
      email: user?.Email,
      customerName: user?.Name,
      companyName: company?.CompanyName,
      description: company?.ShortDescription,
      companyID: company?.PCID,
      success_url: Constant.SUCCESS_URL,
      cancel_url: Constant.CANCEL_URL,
      package: selectedPlan?.type === "Monthly Payment" ? "Monthly" : "Yearly",
    };

    const response = await requestStripePayment(token, data);
    setStripeResponse(response);
    localStorage.setItem("stripeResponse", JSON.stringify(response));
  };

  useEffect(() => {
    handleRequestStripePayment();
  }, [token]);

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Payment Processing</h2>
      <div style={styles.loader}></div>
      <p style={styles.text}>Your payment is being processed...</p>
      <p style={styles.note}>
        To ensure a successful transaction, please refrain from closing or
        refreshing this page.
      </p>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  loader: {
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite",
    marginBottom: "20px",
  },
  text: {
    fontSize: "16px",
  },
  note: {
    fontSize: "14px",
    marginTop: "20px",
    fontStyle: "italic",
    color: "red",
  },
  // Keyframes for loader animation
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
};

export default PaymentProcessing;
