import { set, update } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tick from "../assets/Images/tick.png";

function OfferCard({
  product,
  UpdateFormData,
  isSubscribed,
  selectedCard,
  setSelectedCard,
}) {
  const navigate = useNavigate();
  // check if card is selected
  const isSelected = selectedCard?.PPID === product?.PPID;
  const handleCardClick = () => {
    setSelectedCard(selectedCard === product ? null : product);
  };
  return (
    <div
      className={`bg-white rounded-lg p-5 cursor-pointer ${
        isSelected ? "border-2  border-sky-400" : ""
      } `}
      onClick={handleCardClick}
    >
      <div>
        <div className="flex flex-row justify-between">
          <div>
            {product?.logoURL ? (
              <img
                src={product.logoURL}
                alt="product"
                className="w-20 h-10 object-contain"
              />
            ) : (
              <svg
                fill="#0ea5e9"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                className="w-20 h-10 object-contain"
              >
                <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z"></path>
                <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z"></path>
              </svg>
            )}
          </div>
          <div
            className="text-sky-400 text-sm font-normal text-right font-open underline cursor-pointer"
            onClick={() => {
              UpdateFormData(product);
            }}
          >
            Edit
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-black text-sm font-semibold font-open mt-2 h-5">
            {product?.ProductName}
          </div>
          {isSelected && (
            <img src={tick} alt="" className="w-4 h-4 object-contain" />
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center space-x-2 text-center mt-3">
          <div>
            {product?.ProductStatus === "PENDING" && (
              <div className="px-3 py-1 bg-gray-100 rounded-2xl text-zinc-500 text-[10px] font-normal font-open">
                Pending Approval
              </div>
            )}
            {product?.ProductStatus === "APPROVED" && (
              <div className="px-3 py-1 bg-lime-100 rounded-2xl text-lime-600 text-[10px] font-normal font-open">
                Approved
              </div>
            )}
            {product?.ProductStatus === "REJECTED" && (
              <div className="px-3 py-1 bg-orange-100 rounded-2xl text-amber-500 text-[10px] font-normal font-open">
                Rejected With Comments
              </div>
            )}
            {product?.ProductStatus === "HALT" && (
              <div className="px-3 py-1 bg-red-100 rounded-2xl text-red-500 text-[10px] font-normal font-open">
                Halted
              </div>
            )}
          </div>
          {!isSubscribed && (
            <button
              className="px-3 py-1 bg-rose-500 rounded-3xl text-neutral-50 text-[10px] font-semibold font-open"
              onClick={() => navigate("/membership")}
            >
              Go Premium
            </button>
          )}
        </div>
        <hr className="my-3" />
        <div className="flex flex-row items-center justify-between">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Price of the offer
          </div>
          <div className="text-right text-black text-xs font-normal font-open">
            INR {product?.FinalAmount}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mt-2">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Discount offered
          </div>
          <div className="text-right text-black text-xs font-normal font-open">
            {product.DiscountType === "FREE"
              ? "100%"
              : product.DiscountType === "PERCENTAGE"
              ? `${product.Discount_Amount}%`
              : `Rs ${product.Discount_Amount}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferCard;
