import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/Images/logo.png";
import resetLogo from "../assets/Images/resetPasswordLogo.png";
import { useNavigate } from "react-router-dom";

function ResetPasswordSuccessForm() {
  const { signupData, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg py-10 px-5 w-[420px]">
          <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
          <img
            src={resetLogo}
            alt="logo"
            className="w-64 h-48  object-contain mt-5"
          />

          <div className="text-sky-400 text-3xl font-bold font-open mt-10">
            Success
          </div>
          <div className="w-96 text-gray-500 text-xs font-normal font-open leading-relaxed mt-5">
            You have successfully changed your password, please log in again
            using the new password.
          </div>

          <div className="mt-20">
            <button
              onClick={() => navigate("/login")}
              className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
            >
              Back to Login Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordSuccessForm;
