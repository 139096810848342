import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function PdfComp({ pdf }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pages = Array.from(new Array(numPages), (el, index) => (
    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={500} />
  ));

  return (
    <div style={{ width: "600px", height: "400px", overflow: "auto" }}>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {pages}
      </Document>
    </div>
  );
}

export default PdfComp;
