import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar, { SidebarItem } from "../components/SideBar";
import { FaIdCard } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { MdContactPhone } from "react-icons/md";
import { FaRegFolderClosed } from "react-icons/fa6";
import { FaUserShield } from "react-icons/fa";
import { ACCOUNT_TYPE, MERCHANT_TYPE } from "../utils/constants";
import { useEffect } from "react";
import { getMembershipInfo } from "../services/operations/membershipAPI";
import { getCompanyProfileDetails } from "../services/operations/profileAPI";
import { getCurrentProducts } from "../services/operations/productAPI";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  const { company } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const { loading: profileLoading } = useSelector((state) => state.profile);
  const { loading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      dispatch(getMembershipInfo(token));
      dispatch(getCompanyProfileDetails(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token) dispatch(getCurrentProducts(token));
  }, [token]);

  if (authLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  } else if (user?.UserType === ACCOUNT_TYPE.SALESAGENT) {
    return (
      <>
        <div className="flex flex-col h-screen w-full">
          <Navbar />
          <div className="h-[calc(100vh-3.5rem)] overflow-auto bg-sky-100">
            <Outlet />
          </div>
        </div>
      </>
    );
  }

  return (
    // <div className="relative flex min-h-[calc(100vh-3.5rem)]">
    //   <Navbar />
    //   <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto">
    //     <div className="mx-auto w-11/12 max-w-[1000px] py-10">
    //       <Outlet />
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="flex h-full">
        <Sidebar>
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col items-center justify-center">
              <SidebarItem
                icon={<RxDashboard />}
                text="Sales Dashboard"
                to="/dashboardAdmin"
              />
              <SidebarItem icon={<FaIdCard />} text="Membership" to="/membership" />
              <SidebarItem
                icon={<FaRegFolderClosed />}
                text="Branch Management"
                to="/branchManagement"
              />
              <SidebarItem
                icon={<FaUserShield />}
                text="User Permission"
                to="/userPermission"
              />
            </div>
            <div className="flex flex-col items-center justify-center mt-auto text-sm font-normal">
              <SidebarItem
                icon={<MdContactPhone />}
                text="Contact Us / FAQs"
                to="/faq"
              />
              {(company?.AccountStatus === MERCHANT_TYPE.PRIMARY_ACCOUNT || company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER) && (
                <>
                  <SidebarItem
                    icon={<AiOutlineUser />}
                    text="Profile"
                    to="/profile"
                    active={true}
                    alert={true}
                  />
                </>
              )}
            </div>
          </div>
        </Sidebar>
        <div className="flex flex-col h-screen w-full">
          <Navbar />
          <div className="h-[calc(100vh-3.5rem)] overflow-auto bg-sky-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
