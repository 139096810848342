import Constant from "../config/Constant";
const BASE_URL = Constant.DEFAULT_APP_URL;
// AUTH ENDPOINTS
export const authEndpoints = {
  LOGIN_API: BASE_URL + "/newPartnerManagement/partnerUserlogin",
  REGISTER_API: BASE_URL + "/newPartnerManagement/registerMerchant",
  VERIFY_OTP_API: BASE_URL + "/newPartnerManagement/verifyMerchantOTP",
  UPLOAD_IMAGE_API: BASE_URL + "/newPartnerManagement/upload-company-logo",
  FETCH_SALESAGENT_INFO_API:
    BASE_URL + "/newPartnerManagement/getSalesAgentName/",
  REGISTER_COMPANY_API:
    BASE_URL + "/newPartnerManagement/registerPartnerCompany",
  FORGOT_PASSWORD_API: BASE_URL + "/newPartnerManagement/sendForgotPasswordOTP",
  VERIFY_FORGOT_PASSWORD_OTP_API:
    BASE_URL + "/newPartnerManagement/verifyForgotPasswordOTP",
  RESET_PASSWORD_API:
    BASE_URL + "/newPartnerManagement/changePasswordWithVerification",
  UPDATE_USER_ACTIVE_STATUS_API:
    BASE_URL + "/newPartnerManagement/updateUserActiveStatus",
};

// USER ENDPOINTS
export const userEndpoints = {
  GET_USER_DETAILS_API: BASE_URL + "/newPartnerManagement/getAccountInfo",
  GET_ALL_COMPANY_USERS_API:
    BASE_URL + "/newPartnerManagement/UserManagementGetAllCompanyUsers",
  ADD_COMPANY_USERS_API:
    BASE_URL + "/newPartnerManagement/UserManagementAddUser",
  EDIT_COMPANY_USERS_API:
    BASE_URL + "/newPartnerManagement/UserManagementEditUser",
};

// DASHBOARD ENDPOINTS
export const dashboardEndpoints = {
  SEND_PARTNER_INVITE_API: BASE_URL + "/newPartnerManagement/sendPartnerInvite",
  UPLOAD_IMAGE_API: BASE_URL + "/newPartnerManagement/upload-company-logo",
  EDIT_COMPANY_BASIC_INFO_API:
    BASE_URL + "/newPartnerManagement/editPartnerCompanyInfo",
  EDIT_COMPANY_LOCATION_INFO_API:
    BASE_URL + "/newPartnerManagement/editPartnerCompanyLocation",
  ADD_COMPANY_BRANCH_API: BASE_URL + "/newPartnerManagement/addNewBranch",
  DELETE_COMPANY_BRANCH_API: BASE_URL + "/newPartnerManagement/deleteBranch/",
  UPDATE_COMPANY_BRANCH_API:
    BASE_URL + "/newPartnerManagement/editPartnerCompanyLocation",
};

// MEMBERSHIP ENDPOINTS
export const membershsipEndpoints = {
  STRIPE_REQUEST_PAYMENT_API:
    BASE_URL + "/newPartnerManagement/requestStripePayment2",
  STRIPE_TRANSACTION_STATUS_UPDATE_API:
    BASE_URL + "/newPartnerManagement/updateTransactionStatus",
  GET_MEMBERSHIP_INFO_API:
    BASE_URL + "/newPartnerManagement/getCurrentStripeCustomerInfo",
  UPGRADE_MEMBERSHIP_PLAN_API:
    BASE_URL + "/newPartnerManagement/upgradeToYearly",
};

// PRODUCT ENDPOINTS
export const productEndpoints = {
  GET_PRODUCT_API:
    BASE_URL + "/newPartnerManagement/getCurrentPartnerProductsInfo",
  ADD_PRODUCT_API: BASE_URL + "/newPartnerManagement/addPartnerProduct",
  EDIT_PRODUCT_API: BASE_URL + "/newPartnerManagement/editPartnerProduct",
  UPLOAD_PRODUCT_IMAGE_API:
    BASE_URL + "/newPartnerManagement/uploadProductImage",
  MARK_SALES_AS_FULFILLED:
    BASE_URL + "/newPartnerManagement/updateOrderStatusToFulfilled",
  UPLOAD_REPORT_TO_S3: BASE_URL + "/report/uploadReportThroughLab",
  SAVE_REPORT: BASE_URL + "/report/saveReport",
  SEND_REPORT: BASE_URL + "/report/saveAndSendReportToAHMember",
  GET_REPORTS: BASE_URL + "/report/getDecryptReportByPartner",
  SEND_NOTIFICATION: BASE_URL + "/notifications/sendHealthReportNotification",
};
