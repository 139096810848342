import { useEffect, useState } from "react";
import { sendInvite } from "../services/operations/dashboardAPI";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function PartnerForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    CompanyName: "",
    ReferenceOfficerName: "",
    Email: "",
  });
  const [errors, setErrors] = useState({
    CompanyName: "",
    ReferenceOfficerName: "",
    Email: "",
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "", // Clear the error when user starts typing
    }));
  }

  function submitHandler(event) {
    event.preventDefault();

    // Basic validation
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      } else if (
        typeof formData[key] === "string" &&
        formData[key].trim() === ""
      ) {
        // Check if the field is a string and its trimmed length is zero
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      // Set errors and prevent form submission
      setErrors(newErrors);
      return;
    }

    // Continue with form submission if validation passes
    console.log(formData);
    dispatch(sendInvite(formData, navigate));
  }

  return (
    <>
      {/* component */}
      <div className="font-open p-5 sm:p-10 scroll-smooth">
        <div className="bg-white py-6 px-10 rounded-lg max-w-sm sm:max-w-md mx-auto">
          <div className="sm:text-[18px] text-[18px] font-semibold text-left text-black mb-5">
            New AllesHealth Partner
          </div>
          <form onSubmit={submitHandler} className="">
            <div className="">
              {/* Company Name */}
              <div className="mb-3">
                <label className="w-full">
                  <p className="mb-2 text-[0.875rem] leading-[1.375rem] font-semibold">
                    Company Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="CompanyName"
                    value={formData.CompanyName}
                    onChange={handleOnChange}
                    placeholder="ABC Company"
                    className={`w-full bg-gray-100 rounded-md py-[8px] px-[12px] border ${
                      errors.CompanyName ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* ReferenceOfficerName */}
              <div className="mb-3">
                <label className="w-full">
                  <p className="mb-2 text-[0.875rem] leading-[1.375rem] font-semibold">
                    Reference Officer Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="ReferenceOfficerName"
                    value={formData.ReferenceOfficerName}
                    onChange={handleOnChange}
                    placeholder="John Doe"
                    className={`w-full bg-gray-100 rounded-md py-[8px] px-[12px] border ${
                      errors.ReferenceOfficerName ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Email */}
              <div className="mb-3">
                <label className="w-full">
                  <p className="mb-2 text-[0.875rem] leading-[1.375rem] font-semibold">
                    Email
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleOnChange}
                    placeholder="example@example.com"
                    className={`w-full bg-gray-100 rounded-md py-[8px] px-[12px] border ${
                      errors.Email ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              <div className="flex justify-center my-6">
                <button
                  type="submit"
                  className="bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 text-white  font-semibold py-[8px] px-[12px] rounded-md w-3/4 shadow-md"
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PartnerForm;
