import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Agreement({ setShowAgreement }) {
  const { user, company } = useSelector((state) => state.profile);
  const selectedPlan = localStorage.getItem("selectedPlan")
    ? JSON.parse(localStorage.getItem("selectedPlan"))
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    agree1: false,
    agree2: false,
    agree3: false,
  });

  const handleOnChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  function handleAgreeAndConfirm() {
    if (!formData.agree1 || !formData.agree2 || !formData.agree3) {
      toast.error("Please accept all terms and conditions.");
      return;
    }
    setShowAgreement(false);
    navigate("/payment-processing");
  }
  return (
    <div className="w-11/12 mx-auto mt-10">
      <div className="bg-white overflow-auto p-5 flex flex-col items-center justify-between min-h-[calc(100vh-9rem)]">
        <div>
          {/* Memorandum of Agreement */}
          <h1 className="text-black text-2xl font-bold font-open text-center">
            Memorandum of Understanding (MoU)
          </h1>

          {/* divider */}
          <div className="w-full border-b-2 border-[#F3F5F6] my-6"></div>

          {/* Terms & Conditions */}
          <h1 className="text-sky-400 text-lg font-bold font-open">
            Terms & Conditions
          </h1>

          {/* scrollable content */}
          <div className="overflow-y-scroll scroll-smooth mt-4 h-[calc(100vh-35rem)] min-h-40 px-7">
            <div className=" text-black text-md font-normal font-open leading-snug">
              <div>
                This Memorandum of Understanding (the "MoU") is entered into on{" "}
                <span className="text-sky-500 font-semibold">
                  {moment().format("YYYY-MM-DD")}
                </span>{" "}
                between:
              </div>
              <div className="mt-3">
                <strong>
                  Swiss AllesHealth India Pvt Ltd, (Platform Provider)
                </strong>{" "}
                a company incorporated under the laws of India, having its
                registered office at A3/1, Third Floor, Paschim Vihar, New
                Delhi, 110063 (hereinafter referred to as "Swiss AllesHealth
                India Pvt Ltd")
              </div>
              <div className="mt-3">and</div>
              <div className="mt-3">
                <strong>
                  {" "}
                  <span className="text-sky-500 font-semibold">
                    {company?.CompanyName}
                  </span>{" "}
                  (Service Partner)
                </strong>{" "}
                a company incorporated under the laws of India.
              </div>
              <div className="mt-4">
                Collectively referred to as the "Parties" and individually as a
                "Party".
              </div>

              <div className="mt-5">
                NOW, THEREFORE, the Parties hereby agree as follows:
              </div>

              <div className="ml-10 my-4">
                <ol className="list-decimal">
                  <li>
                    <strong>Listing of Offerings:</strong> Platform Provider
                    agrees to list the offerings of the Service Partner on its
                    patient platform - AllesHealth Patient App. The offerings
                    may include, but are not limited to, services, Lab tests,
                    treatments, procedures, consultations, and any other
                    relevant healthcare offerings provided by the Service
                    Partner.
                  </li>
                  <li className="mt-3">
                    <strong>Reporting and Payout:</strong> Platform Provider
                    shall collect the payment from the patients on behalf of the
                    Service Provider for the converted leads generated through
                    the AllesHealth platform. Platform Provider will provide the
                    Service Partner with a monthly report detailing the number
                    of leads generated and the corresponding commission payable.
                    Platform Provider will make the payment, after deduction of
                    the agreed commission + GST,{" "}
                    <strong>within 7 (seven) to 21 (twenty one) days</strong>{" "}
                    from the date of the transaction completion. The payment
                    shall be made in the INR currency as agreed upon by the
                    Parties, in the bank account provided by the Service
                    Partner. Additional admin and bank charges may apply.
                    Commission charges may differ in the future.
                    <br />
                    <br />
                    In future, such service reports will be made available
                    through a Partner Platform (provided by AllesHealth), where
                    the Service Provider will get log-in details and will be
                    able to self check the reporting and payment related
                    information. A communication will be sent to the Service
                    Provider with relevant information in due time.
                  </li>
                  <li className="mt-3">
                    <strong>Term of Agreement: </strong> This MoU shall come
                    into effect on the Effective Date and shall remain valid
                    indefinitely unless terminated earlier in accordance with
                    the provisions of Clause 6.
                  </li>
                  <li className="mt-3">
                    <strong>Termination</strong> Either Party may terminate this
                    MoU by providing a written notice of termination to the
                    other Party at least 90 (ninety) days prior to the intended
                    termination date. Upon termination, any outstanding
                    commissions due to Swiss AllesHealth India Pvt Ltd shall
                    remain payable by the Service Provider.
                  </li>
                  <li className="mt-3">
                    <strong>Confidentiality:</strong> The Parties agree to
                    maintain the confidentiality of any proprietary or
                    confidential information shared between them during the
                    course of this collaboration. Such information shall not be
                    disclosed to any third party without the prior written
                    consent of the disclosing Party.
                  </li>
                  <li className="mt-3">
                    <strong>Responsibility of the Provider: </strong>The Service
                    Partner agrees to provide high-quality services to customers
                    / leads generated through AllesHealth platform.
                    Additionally, the Service Partner also agrees not to offer
                    direct additional services outside of the AllesHealth
                    platform. Non-adherence to this agreement or to the quality
                    standards will be considered as a breach of contract and
                    will result in delisting from the AllesHealth platform and
                    may result in legal proceedings.
                  </li>
                  <li className="mt-3">
                    <strong>Liability:</strong> Platform Provider only acts as a
                    lead generator and will not be liable for the quality of
                    services provided by the Service Provider. Any disputes
                    arising due to services provided by the Service Provider to
                    customers shall be the sole responsibility of the Service
                    Provider.
                  </li>
                  <li className="mt-3">
                    <strong>Entire Agreement:</strong> This MoU constitutes the
                    entire agreement between the Parties and supersedes any
                    prior discussions, representations, or agreements, whether
                    oral or written, relating to its subject matter.
                  </li>
                  <li className="mt-3">
                    <strong>Governing Law and Jurisdiction:</strong> This MoU
                    shall be governed by and construed in accordance with the
                    laws of India. Any disputes arising out of or in connection
                    with this MoU shall be subject to the exclusive jurisdiction
                    of the courts located in New Delhi, India.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          {/* divider */}
          <div className="w-full border-b-2 border-[#F3F5F6] mt-2 mb-4"></div>
        </div>
        <div>
          {/* Agree check box */}
          <div>
            <div className="flex items-start justify-start ml-2">
              <label
                className="relative flex cursor-pointer items-center"
                htmlFor="rememberMe"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  id="agree1"
                  name="agree1"
                  defaultChecked={formData.agree1}
                  onChange={handleOnChange}
                  className="before:content[''] peer relative h-4 w-4 border-2 border-sky-400 cursor-pointer appearance-none transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg  before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400  hover:before:opacity-10"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth={1}
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </label>

              <p className="text-black text-xs font-semibold font-open ml-2">
                I, <strong>{user?.Name}</strong>, representing{" "}
                <strong>{company?.CompanyName}</strong>, hereby agree to list my
                products and services on the AllesHealth Platform. I commit to
                offering lawful, accurate, and true services to patients through
                the platform. I understand that AllesHealth bears no
                responsibility for the quality or accuracy of the services I
                provide. By participating as a partner on the AllesHealth
                Platform, I affirm my commitment to delivering services in
                accordance with all relevant laws and regulations.
              </p>
            </div>
            <div className="flex items-start justify-start mt-3 ml-2">
              <label
                className="relative flex cursor-pointer items-center"
                htmlFor="rememberMe"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  id="agree2"
                  name="agree2"
                  defaultChecked={formData.agree2}
                  onChange={handleOnChange}
                  className="before:content[''] peer relative h-4 w-4 border-2 border-sky-400 cursor-pointer appearance-none transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg  before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400  hover:before:opacity-10"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth={1}
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </label>

              <p className="text-black text-xs font-semibold font-open ml-2">
                I, <strong>{user?.Name}</strong>, representing{" "}
                <strong>{company?.CompanyName}</strong>, acknowledge that the
                provision of inaccurate services on the AllesHealth Platform may
                result in a breach of contract. I understand the potential legal
                consequences associated with such a breach, and I am aware that
                legal action might be taken against me in the event of service
                inaccuracies. By agreeing to be a partner on the AllesHealth
                Platform, I take full responsibility for the accuracy and
                lawfulness of the services I offer, recognizing the potential
                legal ramifications of any deviations from this commitment.
              </p>
            </div>
            <div className="flex items-start justify-start mt-3 ml-2">
              <label
                className="relative flex cursor-pointer items-center"
                htmlFor="rememberMe"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  id="agree3"
                  name="agree3"
                  defaultChecked={formData.agree3}
                  onChange={handleOnChange}
                  className="before:content[''] peer relative h-4 w-4 border-2 border-sky-400 cursor-pointer appearance-none transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg  before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400  hover:before:opacity-10"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth={1}
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </label>

              <p className="text-black text-xs font-semibold font-open ml-2">
                I, <strong>{user?.Name}</strong>, representing{" "}
                <strong>{company?.CompanyName}</strong>, hereby acknowledge and
                agree to the AllesHealth Platform's fee structure, which
                includes an {selectedPlan?.commissionFee} platform fee for every
                completed transaction, along with applicable Goods and Services
                Tax (GST). I understand that the {selectedPlan?.commissionFee}{" "}
                platform fee will be calculated based on the total value of the
                transaction, and GST will be added to the total fee amount. I
                commit to honoring this fee arrangement, including the inclusion
                of GST, and recognize that it contributes to the ongoing
                maintenance and improvement of the AllesHealth Platform,
                allowing for the continued provision of valuable services to
                both partners and patients.
              </p>
            </div>
          </div>
          {/* Buttons */}
          <div className="flex items-center justify-center space-x-3 mt-5">
            <button
              onClick={() => setShowAgreement(false)}
              className="bg-sky-100 rounded-lg py-3 text-center text-sky-400 text-base font-semibold font-open w-1/2"
            >
              No, Cancel
            </button>
            <button
              onClick={handleAgreeAndConfirm}
              className="bg-sky-400 rounded-lg py-3 text-center text-white text-base font-semibold font-open w-1/2"
            >
              Agree and Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreement;
