export const membershipPlans = [
  {
    id: "1",
    type: "Monthly Payment",
    price: "INR 250",
    branches: 2,
    commissionFee: "8%",
  },
  {
    id: "2",
    type: "Yearly Payment",
    price: "INR 2,500",
    branches: 3,
    commissionFee: "6%",
  },
];
