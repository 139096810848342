import React, { useEffect } from "react";
import PrivateRoute from "./components/PrivateRoute";
import OpenRoute from "./components/OpenRoute";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import PartnerForm from "./pages/PartnerForm";
import Error from "./pages/Error";
import Dashboard from "./pages/Dashboard";
import RegistrationForm from "./pages/RegistrationForm";
import CreateOfferDashboard from "./pages/CreateOfferDashboard";
import OffersDashboard from "./pages/OffersDashboard";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import Membership from "./pages/Membership";
import Otp from "./pages/Otp";
import { ACCOUNT_TYPE, MERCHANT_TYPE } from "./utils/constants";
import { useDispatch, useSelector } from "react-redux";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentProcessing from "./pages/PaymentProcessing";
import { validateSession } from "./services/operations/profileAPI";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordOtp from "./pages/ForgotPasswordOtp";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordSuccess from "./pages/ResetPasswordSuccess";
import UpgradeSuccessful from "./pages/UpgradeSuccessful";
import DashboardAdmin from "./pages/DashboardAdmin";
import CreateOffer from "./pages/CreateOffer";
import BranchManagement from "./pages/BranchManagement";
import UserPermission from "./pages/UserPermission";
import FAQ from "./pages/FAQ";
function App() {
  const { user } = useSelector((state) => state.profile);
  const { company } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      dispatch(validateSession(token, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Open Route - for Only Non Logged in User */}
        <Route
          path="login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />

        <Route
          path="signup"
          element={
            <OpenRoute>
              <Signup />
            </OpenRoute>
          }
        />

        <Route
          path="verify-otp"
          element={
            <OpenRoute>
              <Otp />
            </OpenRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="verify-forgot-password-otp"
          element={
            <OpenRoute>
              <ForgotPasswordOtp />
            </OpenRoute>
          }
        />
        <Route
          path="reset-password"
          element={
            <OpenRoute>
              <ResetPassword />
            </OpenRoute>
          }
        />
        <Route
          path="reset-password-success"
          element={
            <OpenRoute>
              <ResetPasswordSuccess />
            </OpenRoute>
          }
        />

        <Route
          path="registration-form"
          element={
            <OpenRoute>
              <RegistrationForm />
            </OpenRoute>
          }
        />

        {/* Private Route - for Only Logged in User */}
        <Route
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          {/* Route only for Merchant */}
          {user?.UserType === ACCOUNT_TYPE.MERCHANT && (
            <>
              {/* {(company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR || company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER) && (
                <>
                  <Route
                    path="reset-password"
                    element={
                        <ResetPassword />
                    }
                  />
                </>
              )} */}
              <Route path="dashboardAdmin" element={<DashboardAdmin />} />
              <Route path="createOffer" element={<CreateOffer />} />
              <Route path="branchManagement" element={<BranchManagement />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="userPermission" element={<UserPermission />} />
              <Route path="membership" element={<Membership />} />
              <Route
                path="payment-successful"
                element={<PaymentSuccessful />}
              />
              <Route
                path="upgrade-successful"
                element={<UpgradeSuccessful />}
              />
              <Route path="payment-failed" element={<PaymentFailed />} />
              {(company?.AccountStatus === MERCHANT_TYPE.PRIMARY_ACCOUNT || company?.AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER) && (
                <>
                  <Route path="profile" element={<Profile />} />
                </>
              )}
            </>
          )}
          {/* Route only for Sales Agent */}
          {user?.UserType === ACCOUNT_TYPE.SALESAGENT && (
            <>
              <Route path="partner-form" element={<PartnerForm />} />
            </>
          )}
        </Route>
        <Route
          path="payment-processing"
          element={
            <PrivateRoute>
              <PaymentProcessing />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="payment-successful"
          element={
            <PrivateRoute>
              <Success />
            </PrivateRoute>
          }
        />
        <Route
          path="payment-failed"
          element={
            <PrivateRoute>
              <Failed />
            </PrivateRoute>
          }
        /> */}

        {/* 404 Page */}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
