import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { login, sendOtp } from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import emailIcon from "../assets/Images/icon/email.png";
import phoneIcon from "../assets/Images/icon/phone.png";
import { setSignupData } from "../slices/authSlice";
import { registerMerchant } from "../services/operations/authAPI";
import { toast } from "react-hot-toast";

function SignupForm() {
  const [signupType, setSignupType] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignupTypeChange = (type) => {
    setSignupType(type);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    PhoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle Form Submission
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }

    if (signupType === "0") {
      if (!formData.email.includes("@")) {
        toast.error("Please enter a valid email");
        return;
      }
    } else if (signupType === "1") {
      // check phone number length
      if (formData.PhoneNumber.length !== 10) {
        toast.error("Please enter a valid phone number");
        return;
      }
    }

    if (signupType === "0") {
      dispatch(
        registerMerchant(
          formData.name,
          formData.email,
          password,
          signupType,
          navigate
        )
      );
    } else if (signupType === "1") {
      dispatch(
        registerMerchant(
          formData.name,
          formData.PhoneNumber,
          password,
          signupType,
          navigate
        )
      );
    }

    // Reset
    setFormData({
      name: "",
      email: "",
      PhoneNumber: "",
      password: "",
      confirmPassword: "",
    });
  };

  return (
    <div className="bg-white rounded-lg py-10 px-5">
      <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
      {/* <h1 className="text-black text-3xl font-bold font-open mt-8">Welcome</h1> */}

      {/* tab button */}
      <div className="flex items-center space-x-6 mt-6">
        <Link to="/login" className="block">
          <div className=" text-neutral-400 text-sm font-semibold font-open">
            Existing AllesHealth Partner
            <div className="w-full h-1 bg-sky-100  rounded mt-2"></div>
          </div>
        </Link>
        <Link to="/signup" className="block">
          <div className="text-black text-sm font-semibold font-open">
            New AllesHealth Partner
            <div className="w-full h-1 bg-gradient-to-br from-blue-500 to-cyan-300 rounded mt-2"></div>
          </div>
        </Link>
      </div>

      {/* login type - email/phone number  */}
      <div className="justify-start items-start gap-3.5 inline-flex mt-8">
        <button
          className={`py-1 px-5 rounded-3xl ${
            signupType === "0"
              ? "bg-sky-500 text-sky-100"
              : "bg-sky-100 text-sky-400"
          } justify-center items-center inline-flex text-xs font-semibold font-open`}
          onClick={() => handleSignupTypeChange("0")}
        >
          Email
        </button>
        <button
          className={`py-1 px-5 rounded-3xl ${
            signupType === "1"
              ? "bg-sky-500 text-sky-100"
              : "bg-sky-100 text-sky-400"
          } justify-center items-center inline-flex text-xs font-semibold font-open`}
          onClick={() => handleSignupTypeChange("1")}
        >
          Phone Number
        </button>
      </div>

      {/* login form */}
      <div className="mt-6">
        <form onSubmit={handleOnSubmit}>
          <div className="mb-4">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Your Name
              </p>
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleOnChange}
                placeholder="Input your Name here"
                // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
              />
            </label>
          </div>
          {signupType === "0" ? (
            <>
              {/* Email */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Your Email
                  </p>
                  <div className="flex items-center">
                    <div className="bg-sky-100 rounded-l-md p-3 border-none">
                      <img
                        src={emailIcon}
                        alt="email"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleOnChange}
                      placeholder="Input your Email here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-r-md p-4 border-none focus:outline-none focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              {/* Phone Number */}
              <div className="mb-4">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Your Phone Number
                  </p>
                  <div className="flex items-center">
                    <div className="bg-sky-100 rounded-l-md p-3 border-none">
                      <img
                        src={phoneIcon}
                        alt="phoneIcon"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="tel"
                      inputMode="tel"
                      maxLength={10}
                      name="PhoneNumber"
                      value={formData.PhoneNumber}
                      onChange={handleOnChange}
                      placeholder="Input your Phone Number here"
                      // className={`focus:outline-none focus:border-transparent placeholder:text-base`}
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-r-md p-4 border-none focus:outline-none focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>
            </>
          )}

          {/* password */}
          <div className="mb-4 flex items-center gap-x-4">
            <label className="relative flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Password
              </p>
              <input
                className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                required
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleOnChange}
                placeholder="Enter Password"
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </label>
            {/* confirm password */}
            <label className="relative flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Confirm Password
              </p>
              <input
                className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border-none focus:outline-none focus:border-transparent`}
                required
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleOnChange}
                placeholder="Confirm Password"
              />
              <span
                onClick={() => setShowConfirmPassword((prev) => !prev)}
                className="absolute right-4 top-12 transform -translate-y-1/2 z-10 cursor-pointer"
              >
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                ) : (
                  <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                )}
              </span>
            </label>
          </div>

          {/* Signup button */}
          <div className="mt-12">
            <button
              type="submit"
              className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
            >
              Signup
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
