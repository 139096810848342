import Modal from "react-modal";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

export default function ModalComponent({
  show,
  handleClose,
  heading,
  children,
  headerView,
  rightTopBarTile,
  backgroundColor,
  outSideModalPress,
}) {
  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      shouldCloseOnOverlayClick={outSideModalPress}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    borderRadius: "10px",
  },
};
