// This will prevent non-authenticated users from accessing this route
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ACCOUNT_TYPE } from "../utils/constants";

function PrivateRoute({ children }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  if (token !== null) {
    // if (user?.UserType === ACCOUNT_TYPE.SALESAGENT) {
    //   return <Navigate to="/dashboard/partner-form" />;
    // } else {
    //   return children;
    // }
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;
