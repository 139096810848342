import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { dashboardEndpoints } from "../apis";
import { setLoading } from "../../slices/profileSlice";

const {
  SEND_PARTNER_INVITE_API,
  UPLOAD_IMAGE_API,
  EDIT_COMPANY_BASIC_INFO_API,
  EDIT_COMPANY_LOCATION_INFO_API,
  ADD_COMPANY_BRANCH_API,
  DELETE_COMPANY_BRANCH_API,
  UPDATE_COMPANY_BRANCH_API,
} = dashboardEndpoints;

//SEND INVITE
export function sendInvite(formData) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        SEND_PARTNER_INVITE_API,
        {
          ...formData,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        }
      );

      if (!response.status === 200) {
        throw new Error(response.data.message);
      }

      toast.success("Invite Sent");
    } catch (error) {
      console.log("SEND INVITE API ERROR", error);
      toast.error("Invite Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPLOAD IMAGE
export const uploadCompanyLogo = async (formData, token) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", UPLOAD_IMAGE_API, formData, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

//EDIT COMPANY BASIC INFO
export function editPartnerCompanyBasicInfo(token, data, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        EDIT_COMPANY_BASIC_INFO_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Saved Successfully");
    } catch (error) {
      console.log("EDIT PARTNER COMPANY BASIC API ERROR............", error);
      toast.error(
        error?.response?.data?.message ||
          "Failed to update company basic information"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//EDIT COMPANY LOCATION INFO
export function editPartnerCompanyLocationInfo(token, data, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        EDIT_COMPANY_LOCATION_INFO_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      console.log("EDIT PARTNER COMPANY LOCATION API ERROR............", error);
      toast.error(
        error?.response?.data?.message ||
          "Failed to update company location information"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//ADD COMPANY BRANCH
export function addCompanyBranch(token, data) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ADD_COMPANY_BRANCH_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // toast.success("Branch Added Successfully");
    } catch (error) {
      console.log("ADD COMPANY BRANCH API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Failed to add company branch"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//DELETE COMPANY BRANCH
export function deleteCompanyBranch(token, branchId) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_COMPANY_BRANCH_API + branchId,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Branch Deleted Successfully");
    } catch (error) {
      console.log("DELETE COMPANY BRANCH API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Failed to delete company branch"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//UPDATE COMPANY BRANCH
export function updateCompanyBranch(token, data) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        UPDATE_COMPANY_BRANCH_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // toast.success("Branch Updated Successfully");
    } catch (error) {
      console.log("UPDATE COMPANY BRANCH API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Failed to update company branch"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// export async function getMembershipPlans(token) {
//   const toastId = toast.loading("Loading...");
//   let result = [];
//   try {
//     const response = await apiConnector("GET", GET_MEMBERSHIP_PLAN_API, null, {
//       Authorization: `Bearer ${token}`,
//     });
//     console.log("GET_MEMBERSHIP_PLAN_API API RESPONSE............", response);
//     result = response?.data?.data;
//   } catch (error) {
//     console.log("GET_MEMBERSHIP_PLAN_API API ERROR............", error);
//     toast.error("Could Not Get Data");
//   }
//   toast.dismiss(toastId);
//   return result;
// }
