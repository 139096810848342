import React, { useEffect } from "react";
import backgroundImage from "../assets/Images/background.png";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import OtpForm from "./OtpForm";
import { getUserDetails } from "../services/operations/profileAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AntdCarousel from "./AntdCarousel";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordOtp from "../pages/ForgotPasswordOtp";
import ForgotPasswordOtpForm from "./ForgotPasswordOtpForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccessForm from "./ResetPasswordSuccessForm";

function Template({ formType }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      dispatch(getUserDetails(token, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* component */}
      <div
        className="bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="h-screen flex items-center justify-center md:justify-around">
          <div className="hidden md:flex">
            <AntdCarousel />
          </div>

          <div className="">
            {formType === "resetPassword" ? (
              <ResetPasswordForm />
            ) : formType === "resetPasswordSuccess" ? (
              <ResetPasswordSuccessForm />
            ) : null}
            {formType === "signup" ? (
              <SignupForm />
            ) : formType === "login" ? (
              <LoginForm />
            ) : formType === "otp" ? (
              <OtpForm />
            ) : formType === "forgotPassword" ? (
              <ForgotPasswordForm />
            ) : formType === "forgotPasswordOtp" ? (
              <ForgotPasswordOtpForm />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Template;
