import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import upload from "../assets/Images/icon/upload.png";
import deleteIcon from "../assets/Images/icon/delete.png";
import { getCompanyProfileDetails } from "../services/operations/profileAPI";
import {
  addCompanyBranch,
  deleteCompanyBranch,
  editPartnerCompanyBasicInfo,
  updateCompanyBranch,
} from "../services/operations/dashboardAPI";
import toast from "react-hot-toast";
import { uploadCompanyLogo } from "../services/operations/dashboardAPI";
import { getBucketURL } from "../utils/regex";
import { IoAddOutline } from "react-icons/io5";
import ProfileMembershipCard from "../components/ProfileMembershipCard";
import imageCompression from "browser-image-compression";

function Profile() {
  const { user, loading: profileLoading } = useSelector(
    (state) => state.profile
  );
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const { company, logo } = useSelector((state) => state.profile);
  const [image, setImage] = useState("");
  const [locationApiResponse, setLocationApiResponse] = useState(
    company?.Locations
  );
  const [imageChanged, setImageChanged] = useState(false);
  const [deletedBranches, setDeletedBranches] = useState([]);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    CompanyName: company?.CompanyName || "",
    LogoLocation: company?.LogoLocation || "",
    PanCardNumber: company?.PanCardNumber || "",
    GSTIN: company?.GSTIN || "",
    PhoneCountryCode: company?.PhoneCountryCode || "+91",
    PhoneNumber: company?.PhoneNumber || "",
    Website: company?.Website || "",
    mainEmail: company?.mainEmail || "",
    Locations: company?.Locations || [],
    ShortDescription: company?.ShortDescription || "",
    BankName: company?.BankName || "",
    BankAccountNumber: company?.BankAccountNumber || "",
    IFSC_ID: company?.IFSC_ID || "",
    AllesHealthSalesAgentID: company?.AllesHealthSalesAgentID || "",
    Latitude: company?.Latitude || "",
    Longitude: company?.Longitude || "",
    TypeOfBusiness: company?.TypeOfBusiness || "",
  });
  const [errors, setErrors] = useState({
    CompanyName: "",
    LogoLocation: "",
    PanCardNumber: "",
    PhoneNumber: "",
    mainEmail: "",
    Locations: [],
    BankName: "",
    BankAccountNumber: "",
    IFSC_ID: "",
    TypeOfBusiness: "",
  });

  // Function to handle adding a new branch
  const addNewBranch = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Locations: [
        ...prevFormData?.Locations,
        {
          Branch_Name: "",
          PremiseStreetAddress: "",
          City: "",
          State: "",
          ZipCode: "",
          Latitude: "0",
          Longitude: "0",
        },
      ],
    }));
  };

  // Function to handle deleting a branch
  const handleDeleteCompanyBranch = (branchID, branchName) => {
    if (branchID) {
      setDeletedBranches([...deletedBranches, branchID]);
    }
    setFormData((prev) => ({
      ...prev,
      Locations: prev.Locations.filter(
        (branch) => branch.Branch_Name !== branchName
      ),
    }));
  };

  // Function to handle changes in primary location fields
  const handlePrimaryBranchChange = (event) => {
    const { name, value } = event.target;
    let maxLength;
    switch (name) {
      case "ZipCode":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // Check if the value is numeric and enforce maximum length
    if (event.target.type === "number") {
      // For input type number, handle non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      if (!isNaN(numericValue) && numericValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          Locations: [
            {
              ...prev?.Locations[0],
              [name]: numericValue,
            },
            ...prev?.Locations.slice(1),
          ],
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          Locations: [
            {
              ...prev?.Locations[0],
              [name]: value,
            },
            ...prev?.Locations.slice(1),
          ],
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  // Function to handle changes in additional location fields
  const handleAdditionalBranchChange = (e, index, fieldName) => {
    const { name, value } = e.target;
    let maxLength;
    switch (name) {
      case "ZipCode":
        maxLength = 6;
        break;
      // case "ZipCode":
      //   maxLength = 6;
      //   break;
      // case "BankAccountNumber":
      //   maxLength = 14;
      //   break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }
    const updatedBranches = [...formData?.Locations];
    const updatedBranch = { ...updatedBranches[index] };

    // Check if the value is numeric and enforce maximum length
    if (e.target.type === "number") {
      // For input type number, handle non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      if (!isNaN(numericValue) && numericValue.length <= maxLength) {
        updatedBranch[fieldName] = numericValue;
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        updatedBranch[fieldName] = value;
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
    updatedBranches[index] = updatedBranch;
    setFormData({
      ...formData,
      Locations: updatedBranches,
    });
  };

  // Function to handle changes in basic form fields
  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "PhoneNumber":
        maxLength = 10;
        break;
      case "ZipCode":
        maxLength = 6;
        break;
      case "BankAccountNumber":
        maxLength = 14;
        break;

      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // Check if the value is numeric and enforce maximum length
    if (event.target.type === "number") {
      // For input type number, handle non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      if (!isNaN(numericValue) && numericValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  }

  // Function to compare two arrays of objects and return the differences
  function compareArrays(arr1, arr2) {
    const differences = [];

    // Iterate through each object in arr1
    arr1.forEach((obj1) => {
      // Find corresponding object in arr2 based on ID or some unique identifier
      const obj2 = arr2.find((o) => o.ID === obj1.ID);

      // If corresponding object not found or properties are different, add to differences
      if (!obj2 || !objectsAreEqual(obj1, obj2)) {
        differences.push(
          removeProperties(obj2 || obj1, ["PCID", "Updated_At", "Created_At"])
        );
      }
    });

    // Check objects in arr2 that are not present in arr1
    arr2.forEach((obj2) => {
      const obj1 = arr1.find((o) => o.ID === obj2.ID);
      if (!obj1) {
        differences.push(
          removeProperties(obj2, ["PCID", "Updated_At", "Created_At"])
        );
      }
    });

    return differences;
  }

  // Function to check if two objects are equal based on their properties
  function objectsAreEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  // Function to remove specified properties from an object
  function removeProperties(obj, propertiesToRemove) {
    const newObj = { ...obj };
    propertiesToRemove.forEach((prop) => delete newObj[prop]);
    return newObj;
  }

  async function updateLocationGeolocation(data) {
    const updatedLocations = await Promise.all(
      data?.map(async (item) => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              item?.PremiseStreetAddress
            )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
          );
          const data = await response.json();
          const { lat, lng } = data.results[0]?.geometry.location;
          return {
            ...item,
            Latitude: lat.toString(),
            Longitude: lng.toString(),
          };
        } catch (error) {
          console.error("Error fetching geolocation:", error);
          return item; // Return original location if there's an error
        }
      })
    );

    return updatedLocations;
  }

  useEffect(() => {
    if (company) {
      setFormData({
        CompanyName: company?.CompanyName || "",
        LogoLocation: company?.LogoLocation || "",
        PanCardNumber: company?.PanCardNumber || "",
        GSTIN: company?.GSTIN || "",
        PhoneCountryCode: company?.PhoneCountryCode || "+91",
        PhoneNumber: company?.PhoneNumber || "",
        Website: company?.Website || "",
        mainEmail: company?.mainEmail || "",
        Locations: company?.Locations || [],
        ShortDescription: company?.ShortDescription || "",
        BankName: company?.BankName || "",
        BankAccountNumber: company?.BankAccountNumber || "",
        IFSC_ID: company?.IFSC_ID || "",
        AllesHealthSalesAgentID: company?.AllesHealthSalesAgentID || "",
        Latitude: company?.Latitude || "0",
        Longitude: company?.Longitude || "0",
        TypeOfBusiness: company?.TypeOfBusiness || "",
      });
      setLocationApiResponse(company?.Locations);
      setImage(logo);
    }
  }, [company, logo]);

  // handle image change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    // setImage(file);
    uploadImage(compressedFile);
  };

  // Upload image to aws
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("partnerCompanyLogo", file);
    try {
      const res = await uploadCompanyLogo(formData, token);
      setFormData((prev) => ({
        ...prev,
        LogoLocation: res?.logoUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        LogoLocation: "",
      }));
      setImageChanged(true);
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  async function submitHandler(event) {
    event.preventDefault();
    setIsSaveButtonClicked(true);
    const mandatoryFields = [
      "CompanyName",
      "LogoLocation",
      "PanCardNumber",
      "PhoneNumber",
      "mainEmail",
      "BankName",
      "BankAccountNumber",
      "IFSC_ID",
      "TypeOfBusiness",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    let differenceArray = compareArrays(
      locationApiResponse,
      formData?.Locations
    );

    const updatedDifferenceArrayWithGeoLocation =
      await updateLocationGeolocation(differenceArray);

    // Validate each location
    const locationErrors = updatedDifferenceArrayWithGeoLocation.reduce(
      (errors, location, index) => {
        if (!location.Branch_Name || location.Branch_Name.trim() === "") {
          errors[index] = {
            ...errors[index],
            Branch_Name: "Branch Name is required",
          };
        }
        if (
          !location.PremiseStreetAddress ||
          location.PremiseStreetAddress.trim() === ""
        ) {
          errors[index] = {
            ...errors[index],
            PremiseStreetAddress: "Street Address is required",
          };
        }
        if (!location.City || location.City.trim() === "") {
          errors[index] = { ...errors[index], City: "City is required" };
        }
        if (!location.State || location.State.trim() === "") {
          errors[index] = { ...errors[index], State: "State is required" };
        }
        if (!location.ZipCode || location.ZipCode.trim() === "") {
          errors[index] = { ...errors[index], ZipCode: "Zipcode is required" };
        }
        return errors;
      },
      {}
    );

    if (Object.keys(locationErrors).length > 0) {
      updatedDifferenceArrayWithGeoLocation.forEach((location, index) => {
        if (locationErrors[index]) {
          // Set errors for specific fields
          setErrors((prevErrors) => ({
            ...prevErrors,
            [`${index}.Branch_Name`]: locationErrors[index].Branch_Name,
            [`${index}.PremiseStreetAddress`]:
              locationErrors[index].PremiseStreetAddress,
            [`${index}.City`]: locationErrors[index].City,
            [`${index}.State`]: locationErrors[index].State,
            [`${index}.ZipCode`]: locationErrors[index].ZipCode,
          }));
        }
      });
      toast.error("Please fill all the required fields");
      console.log("locationErrors", locationErrors);
      return;
    }

    updatedDifferenceArrayWithGeoLocation.forEach((branch) => {
      if (branch?.ID) {
        if (deletedBranches.includes(branch?.ID)) {
          dispatch(deleteCompanyBranch(token, branch?.ID));
        } else {
          dispatch(updateCompanyBranch(token, branch));
        }
      } else {
        dispatch(addCompanyBranch(token, branch));
      }
    });

    let basicData = {
      CompanyName: formData.CompanyName,
      PanCardNumber: formData.PanCardNumber,
      GSTIN: formData.GSTIN,
      PhoneCountryCode: formData.PhoneCountryCode,
      PhoneNumber: formData.PhoneNumber,
      Website: formData.Website,
      ShortDescription: formData.ShortDescription,
      BankName: formData.BankName,
      BankAccountNumber: formData.BankAccountNumber,
      IFSC_ID: formData.IFSC_ID,
      TypeOfBusiness: formData.TypeOfBusiness,
    };

    if (imageChanged) {
      basicData.LogoLocation = formData.LogoLocation;
    }

    console.log("basicData", basicData);

    dispatch(editPartnerCompanyBasicInfo(token, basicData, navigate));
    setIsEdit(false);
  }

  useEffect(() => {
    if (isEdit) {
      dispatch(getCompanyProfileDetails(token));
    }
  }, []);

  if (profileLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="mx-10 my-5 scroll-smooth">
      <div className="flex items-center justify-between">
        <h1 className="text-black text-xl font-semibold font-open">Profile</h1>

        {/* buttons */}
        {isEdit ? (
          <div className="flex items-center space-x-4">
            <button
              onClick={() => {
                setErrors({
                  CompanyName: "",
                  LogoLocation: "",
                  PanCardNumber: "",
                  PhoneNumber: "",
                  mainEmail: "",
                  StreetAddress: "",
                  City: "",
                  State: "",
                  ZipCode: "",
                  BankName: "",
                  BankAccountNumber: "",
                  IFSC_ID: "",
                });
                setIsEdit(false);
                dispatch(getCompanyProfileDetails(token));
              }}
              className="px-3 py-2 rounded shadow justify-center items-center gap-2.5 inline-flex text-sky-400  bg-sky-100 text-sm font-normal font-open"
            >
              Cancel Changes
            </button>
            <button
              onClick={submitHandler}
              className="px-3 py-2 rounded shadow justify-center items-center gap-2.5 inline-flex bg-lime-500 text-sky-100 text-sm font-normal font-open"
            >
              Save Data
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                setIsEdit(true);
                setIsSaveButtonClicked(false);
              }}
              className="px-3 py-2 rounded shadow justify-center items-center gap-2.5 inline-flex bg-sky-400 text-sky-100 text-sm font-normal font-open"
            >
              Edit Data
            </button>
          </div>
        )}
      </div>

      {/* content */}
      <div className="flex flex-col justify-between mt-4 sm:mt-10 sm:flex-row sm:space-x-10">
        <div className="sm:w-1/2">
          {/* Upload Company Logo */}
          <div className="mb-6">
            <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
              Company Logo
              {isEdit && <sup className="text-red-600">*</sup>}
            </p>
            <label
              className={`w-full border-dashed ${
                errors.LogoLocation ? "border-red-500" : "border-sky-400"
              }  border-2 rounded-md p-4 flex flex-col items-center justify-center ${
                isEdit
                  ? "cursor-pointer bg-white"
                  : "cursor-default bg-gray-100"
              }`}
            >
              {formData?.LogoLocation ? (
                <>
                  <img
                    src={image}
                    alt="company logo"
                    className="w-30 h-20 object-contain"
                  />

                  {isEdit && (
                    <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex mt-3">
                      <p className="text-sky-400 text-xs font-semibold font-open">
                        Change Logo
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <img
                    src={upload}
                    alt="upload icon"
                    className="w-10 h-10 object-contain"
                  />
                  <span className="text-sky-400 text-xs font-normal font-open mt-1">
                    Upload Here
                  </span>
                  <span className="text-neutral-400 text-[11px] font-normal font-open mt-1">
                    File : JPG, PNG, SVG Max 20 Mb
                  </span>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                name="LogoLocation"
                value={undefined}
                onChange={(e) => handleImageChange(e)}
                style={{ display: "none" }}
                disabled={!isEdit}
              />
            </label>
          </div>
          {/* Dropdown Menu */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Type of Business
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <select
                name="TypeOfBusiness"
                value={formData.TypeOfBusiness}
                onChange={handleOnChange}
                disabled={!isEdit}
                className={`w-full text-black text-xs font-normal font-open ${
                  !isEdit ? "bg-gray-100" : " bg-white"
                }  rounded-md p-4 border  ${
                  errors.TypeOfBusiness ? "border-red-500" : ""
                } text-sky-400 focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              >
                <option className="text-black" value="">
                  Select an Option
                </option>
                <option className="text-black" value="LABORATORY">
                  Lab Test
                </option>
                <option className="text-black" value="CLINIC/HOSPITAL">
                  Treatment
                </option>
                <option className="text-black" value="STORES">
                  Merchandise
                </option>
                <option className="text-black" value="OTHERS">
                  Others
                </option>
              </select>
            </label>
          </div>
          {/* Pan Card Number */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Pan Card Number
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <input
                type="text"
                name="PanCardNumber"
                value={formData.PanCardNumber}
                onChange={handleOnChange}
                disabled={!isEdit}
                maxLength={10}
                placeholder="Input Company's Pan Card Number here (10 Characters)"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : "bg-gray-100"
                } rounded-md p-4 border ${
                  errors.PanCardNumber ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Regular GSTIN  (If Applicable) */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Regular GSTIN (If Applicable)
                {/*{isEdit && <sup className="text-red-600">*</sup>} */}
              </p>
              <input
                type="text"
                name="GSTIN"
                value={formData.GSTIN}
                onChange={handleOnChange}
                disabled={!isEdit}
                maxLength={14}
                placeholder="Input your GSTIN Number here (14 Characters)"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border ${
                  errors.GSTIN ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Phone Number */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Phone Number
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <input
                type="number"
                name="PhoneNumber"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => {
                  e.preventDefault();
                  e.target.blur();
                }}
                value={formData.PhoneNumber}
                onChange={handleOnChange}
                maxLength={10}
                disabled={user?.LoginType === "1" ? true : false || !isEdit}
                placeholder="Input your Phone Number here (10 Digits)"
                className={`w-full text-black text-xs font-normal font-open  ${
                  user?.LoginType === "1" || !isEdit
                    ? "bg-gray-100"
                    : " bg-white"
                } rounded-md p-4 border ${
                  errors.PhoneNumber ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Email* */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Email
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <input
                type="text"
                name="mainEmail"
                value={formData.mainEmail}
                onChange={handleOnChange}
                disabled={user?.LoginType === "0" ? true : false || !isEdit}
                placeholder="MyCompany@company.com"
                className={`w-full text-black text-xs font-normal font-open ${
                  user?.LoginType === "0" || !isEdit
                    ? "bg-gray-100"
                    : " bg-white"
                } rounded-md p-4 border ${
                  errors.mainEmail ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>

          {/* Website */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Website
                {/*{isEdit && <sup className="text-red-600">*</sup>} */}
              </p>
              <input
                type="text"
                name="Website"
                value={formData.Website}
                onChange={handleOnChange}
                disabled={!isEdit}
                placeholder="Input your address here"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border   focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>

          {/* Primary Location */}
          {/* Street Address* */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Street Address
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <input
                type="text"
                name="PremiseStreetAddress"
                value={formData?.Locations[0]?.PremiseStreetAddress}
                onChange={handlePrimaryBranchChange}
                disabled={!isEdit}
                placeholder="Input your address here"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border ${
                  formData?.Locations[0]?.PremiseStreetAddress === "" &&
                  isSaveButtonClicked
                    ? "border-red-500"
                    : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* City State Pincode  */}
          <div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              {/* City */}
              <div className="mb-6 md:w-1/2">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    City
                    {isEdit && <sup className="text-red-600">*</sup>}
                  </p>
                  <input
                    type="text"
                    name="City"
                    value={formData?.Locations[0]?.City}
                    onChange={handlePrimaryBranchChange}
                    disabled={!isEdit}
                    placeholder="Input your City here"
                    className={`w-full text-black text-xs font-normal font-open  ${
                      isEdit ? "bg-white" : " bg-gray-100"
                    } rounded-md p-4 border  ${
                      formData?.Locations[0]?.City === "" && isSaveButtonClicked
                        ? "border-red-500"
                        : ""
                    }   focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* State */}
              <div className="mb-6 md:w-1/2">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    State
                    {isEdit && <sup className="text-red-600">*</sup>}
                  </p>
                  <input
                    type="text"
                    name="State"
                    value={formData?.Locations[0]?.State}
                    onChange={handlePrimaryBranchChange}
                    disabled={!isEdit}
                    placeholder="Input your State here"
                    className={`w-full text-black text-xs font-normal font-open  ${
                      isEdit ? "bg-white" : " bg-gray-100"
                    } rounded-md p-4 border  ${
                      formData?.Locations[0]?.State === "" &&
                      isSaveButtonClicked
                        ? "border-red-500"
                        : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Pincode */}
              <div className="mb-6 md:w-1/2">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Pincode
                    {isEdit && <sup className="text-red-600">*</sup>}
                  </p>
                  <input
                    type="number"
                    name="ZipCode"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    value={formData?.Locations[0]?.ZipCode}
                    onChange={handlePrimaryBranchChange}
                    maxLength={6}
                    disabled={!isEdit}
                    placeholder="Input your Pincode here"
                    className={`w-full text-black text-xs font-normal font-open  ${
                      isEdit ? "bg-white" : " bg-gray-100"
                    } rounded-md p-4 border  ${
                      formData?.Locations[0]?.ZipCode === "" &&
                      isSaveButtonClicked
                        ? "border-red-500"
                        : ""
                    }   focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
            </div>
          </div>
          {/* Additional Branches */}
          <div className="mb-6">
            {formData?.Locations?.slice(1)?.map((branch, index) => {
              return (
                <div key={branch?.ID}>
                  {/* Branch Name* */}
                  <div className="mb-6">
                    <div className="mb-4 flex items-center justify-between">
                      <p className="text-zinc-950 text-xs font-semibold font-open">
                        Additional Branch / Collection Center # {index + 1}
                      </p>
                      {isEdit && (
                        <>
                          <button
                            className="hover:scale-110 transition duration-200 ease-in-out"
                            onClick={() => {
                              handleDeleteCompanyBranch(
                                branch?.ID,
                                branch?.Branch_Name
                              );
                            }}
                          >
                            <img
                              src={deleteIcon}
                              alt="delete icon"
                              className="w-6 h-6 object-contain"
                            />
                          </button>
                        </>
                      )}
                    </div>
                    <label>
                      <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                        Branch Name
                        {isEdit && <sup className="text-red-600">*</sup>}
                      </p>
                      <input
                        type="text"
                        name="Branch_Name"
                        value={branch?.Branch_Name}
                        onChange={(e) =>
                          handleAdditionalBranchChange(
                            e,
                            index + 1,
                            "Branch_Name"
                          )
                        }
                        placeholder={`Branch Name ${index + 1}`}
                        maxLength={30}
                        disabled={!isEdit}
                        className={`w-full text-black text-xs font-normal font-open  ${
                          isEdit ? "bg-white" : "bg-gray-100"
                        } rounded-md p-4 border ${
                          branch?.Branch_Name === "" && isSaveButtonClicked
                            ? "border-red-500"
                            : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </label>
                    <p className="text-sky-400 text-xs font-normal font-open mt-1">
                      30 Characters limit
                    </p>
                  </div>
                  {/* Street Address* */}
                  <div className="mb-6">
                    <label>
                      <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                        Street Address
                        {isEdit && <sup className="text-red-600">*</sup>}
                      </p>
                      <input
                        type="text"
                        name="PremiseStreetAddress"
                        value={branch?.PremiseStreetAddress}
                        onChange={(e) =>
                          handleAdditionalBranchChange(
                            e,
                            index + 1,
                            "PremiseStreetAddress"
                          )
                        }
                        disabled={!isEdit}
                        placeholder="Input your address here"
                        className={`w-full text-black text-xs font-normal font-open  ${
                          isEdit ? "bg-white" : " bg-gray-100"
                        } rounded-md p-4 border ${
                          branch?.PremiseStreetAddress === "" &&
                          isSaveButtonClicked
                            ? "border-red-500"
                            : ""
                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </label>
                  </div>
                  {/* City State Pincode  */}
                  <div>
                    <div className="flex flex-col md:flex-row md:space-x-2">
                      {/* City */}
                      <div className="mb-6 md:w-1/2">
                        <label>
                          <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                            City
                            {isEdit && <sup className="text-red-600">*</sup>}
                          </p>
                          <input
                            type="text"
                            name="City"
                            value={branch?.City}
                            onChange={(e) =>
                              handleAdditionalBranchChange(e, index + 1, "City")
                            }
                            disabled={!isEdit}
                            placeholder="Input your City here"
                            className={`w-full text-black text-xs font-normal font-open  ${
                              isEdit ? "bg-white" : " bg-gray-100"
                            } rounded-md p-4 border ${
                              branch?.City === "" && isSaveButtonClicked
                                ? "border-red-500"
                                : ""
                            } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          />
                        </label>
                      </div>

                      {/* State */}
                      <div className="mb-6 md:w-1/2">
                        <label>
                          <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                            State
                            {isEdit && <sup className="text-red-600">*</sup>}
                          </p>
                          <input
                            type="text"
                            name="State"
                            value={branch?.State}
                            onChange={(e) =>
                              handleAdditionalBranchChange(
                                e,
                                index + 1,
                                "State"
                              )
                            }
                            disabled={!isEdit}
                            placeholder="Input your State here"
                            className={`w-full text-black text-xs font-normal font-open  ${
                              isEdit ? "bg-white" : " bg-gray-100"
                            } rounded-md p-4 border ${
                              branch?.State === "" && isSaveButtonClicked
                                ? "border-red-500"
                                : ""
                            } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          />
                        </label>
                      </div>

                      {/* Pincode */}
                      <div className="mb-6 md:w-1/2">
                        <label>
                          <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                            Pincode
                            {isEdit && <sup className="text-red-600">*</sup>}
                          </p>
                          <input
                            type="number"
                            name="ZipCode"
                            onKeyDown={(e) => {
                              if (
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            value={branch?.ZipCode}
                            onChange={(e) =>
                              handleAdditionalBranchChange(
                                e,
                                index + 1,
                                "ZipCode"
                              )
                            }
                            maxLength={6}
                            disabled={!isEdit}
                            placeholder="Input your Pincode here"
                            className={`w-full text-black text-xs font-normal font-open  ${
                              isEdit ? "bg-white" : " bg-gray-100"
                            } rounded-md p-4 border ${
                              branch?.ZipCode === "" && isSaveButtonClicked
                                ? "border-red-500"
                                : ""
                            } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* add additional branches */}
          {isEdit && (
            <button
              className="border border-sky-400 border-dashed rounded-md w-full p-4 flex items-center justify-center"
              onClick={addNewBranch}
            >
              <IoAddOutline className="text-sky-400 mr-2" />
              <p className="text-sky-400 text-xs font-semibold font-open">
                Add Branch / Collection Centre
              </p>
            </button>
          )}
        </div>

        <div className="sm:w-1/2">
          {/* Bank Account (Will  be used for Payouts)  */}
          <h1 className="text-zinc-950 text-sm font-semibold font-open mb-5">
            Bank Account (Will be used for Payouts)
          </h1>
          {/* Bank Name */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Bank Name
                {isEdit && <sup className="text-red-600">*</sup>}
              </p>
              <input
                type="text"
                name="BankName"
                value={formData.BankName}
                onChange={handleOnChange}
                disabled={!isEdit}
                placeholder="Input your bank name here"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border ${
                  errors.BankName ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Account Number and IFSC Code */}
          <div className="flex flex-col md:flex-row md:space-x-2">
            {/* Account Number */}
            <div className="mb-6 md:w-1/2">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Account Number
                  {isEdit && <sup className="text-red-600">*</sup>}
                </p>
                <input
                  type="number"
                  name="BankAccountNumber"
                  value={formData.BankAccountNumber}
                  onChange={handleOnChange}
                  disabled={!isEdit}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  placeholder="Input your account number here"
                  className={`w-full text-black text-xs font-normal font-open  ${
                    isEdit ? "bg-white" : " bg-gray-100"
                  } rounded-md p-4 border ${
                    errors.BankAccountNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* IFSC Code */}
            <div className="mb-6 md:w-1/2">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  IFSC Code
                  {isEdit && <sup className="text-red-600">*</sup>}
                </p>
                <input
                  type="text"
                  name="IFSC_ID"
                  value={formData.IFSC_ID}
                  onChange={handleOnChange}
                  disabled={!isEdit}
                  maxLength={11}
                  placeholder="Input your IFSC code here"
                  className={`w-full text-black text-xs font-normal font-open  ${
                    isEdit ? "bg-white" : " bg-gray-100"
                  } rounded-md p-4 border ${
                    errors.IFSC_ID ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </div>
          {/* AllesHealth Sales Agent ID* */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                AllesHealth Sales Agent ID
              </p>
              <input
                type="text"
                name="AllesHealthSalesAgentID"
                value={formData.AllesHealthSalesAgentID}
                onChange={handleOnChange}
                disabled={true}
                placeholder="Not Submitted"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border ${
                  errors.AllesHealthSalesAgentID ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          {/* Short Description of Services offered for Patients */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Short Description of Services offered for Patients
              </p>
              <textarea
                type="text"
                name="ShortDescription"
                value={formData.ShortDescription}
                onChange={handleOnChange}
                disabled={!isEdit}
                placeholder="Input your description here"
                className={`w-full text-black text-xs font-normal font-open  ${
                  isEdit ? "bg-white" : " bg-gray-100"
                } rounded-md p-4 border ${
                  errors.ShortDescription ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>

          <ProfileMembershipCard />
        </div>
      </div>
    </div>
  );
}

export default Profile;
