import { toast } from "react-hot-toast";
import { setLoading, setSignupData, setToken } from "../../slices/authSlice";
import { setUser, setCompany, setLogo } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { authEndpoints } from "../apis";
import { ACCOUNT_TYPE, MERCHANT_TYPE } from "../../utils/constants";
import { setIsSubscribed, setMembership } from "../../slices/membershipSlice";
import { setProduct } from "../../slices/productSlice";

const {
  LOGIN_API,
  REGISTER_API,
  VERIFY_OTP_API,
  UPLOAD_IMAGE_API,
  FETCH_SALESAGENT_INFO_API,
  REGISTER_COMPANY_API,
  FORGOT_PASSWORD_API,
  VERIFY_FORGOT_PASSWORD_OTP_API,
  RESET_PASSWORD_API,
  UPDATE_USER_ACTIVE_STATUS_API,
} = authEndpoints;

//LOGIN
export function login(Identifier, Password, LoginType, RememberMe, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          Email: Identifier,
          Password,
          LoginType,
        };
      } else {
        requestBody = {
          Phone: Identifier,
          Password,
          LoginType,
        };
      }

      const response = await apiConnector("POST", LOGIN_API, requestBody);

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      if (RememberMe) {
        if (LoginType === "0") {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Email: Identifier, Password, RememberMe })
          );
        } else {
          localStorage.setItem(
            "savedInfo",
            JSON.stringify({ Phone: Identifier, Password, RememberMe })
          );
        }
      } else {
        localStorage.removeItem("savedInfo");
      }

      const AccountStatus = response?.data?.companyDetails?.AccountStatus;
      const IsPassChangeNeeded = response?.data?.user?.IsPassChangeNeeded;

      if (
        response?.data?.companyDetails === null &&
        response?.data?.user?.UserType !== ACCOUNT_TYPE.SALESAGENT
      ) {
        toast("Please Complete Your Profile Details");
        localStorage.setItem("tempToken", response?.data?.AccessToken);
        localStorage.setItem("tempUser", JSON.stringify(response?.data?.user));
        navigate("/registration-form");
      } else {
        toast.success("Login Successfull");
        dispatch(setToken(response?.data?.AccessToken));
        const userImage = response?.data?.user?.image
          ? response?.data?.user?.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;

        // Add AccountStatus directly to userData before dispatching to Redux
        const userData = {
          ...response?.data?.user,
          IsPassChangeNeeded: IsPassChangeNeeded,
        };
        const companyData = {
          ...response?.data?.companyDetails,
          AccountStatus: AccountStatus,
        }

        dispatch(setUser({ ...userData, image: userImage }));
        dispatch(setCompany(companyData));

        // Check AccountStatus and navigate user accordingly
        if (AccountStatus === MERCHANT_TYPE.ACCOUNT_OWNER || AccountStatus === MERCHANT_TYPE.ADMINISTRATOR) {
          if (IsPassChangeNeeded) { navigate("/reset-password"); } // Navigate to reset-password
          else { navigate("/dashboardAdmin"); }
        } else if (response?.data?.user?.UserType === ACCOUNT_TYPE.SALESAGENT) {
          navigate("/partner-form");
        } else {
          navigate("/dashboardAdmin");
        }
      }
    } catch (error) {
      console.log("LOGIN API ERROR :", error);
      toast.error(error?.response?.data?.message || "Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//REGISTER
export function registerMerchant(
  name,
  Identifier,
  password,
  SignupType,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (SignupType === "0") {
        requestBody = {
          name,
          email: Identifier,
          password,
          // AccountStatus: MERCHANT_TYPE.PRIMARY_ACCOUNT,
        };
      } else {
        requestBody = {
          name,
          PhoneNumber: Identifier,
          password,
          // AccountStatus: MERCHANT_TYPE.PRIMARY_ACCOUNT,
        };
      }

      const response = await apiConnector("POST", REGISTER_API, requestBody);

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("OTP Sent Successfully");

      // Hardcode AccountStatus as PRIMARY_ACCOUNT for now
      const AccountStatus = MERCHANT_TYPE.PRIMARY_ACCOUNT;

      const localStorageData = {
        ...requestBody,
        AccountStatus,
        SignupType: SignupType,
      };

      localStorage.setItem("tempData", JSON.stringify(localStorageData));

      const userData = {
        // Add hardcoded AccountStatus to user data
        ...response?.data?.user,
        AccountStatus: AccountStatus,
      };

      dispatch(setSignupData(userData));
      navigate("/verify-otp");
    } catch (error) {
      console.log("REGISTER USER API ERROR............", error);
      toast.error(error?.response?.data?.message || "Could not send otp");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//VERIFY OTP
export function verifyMerchantOTP(PID, Otp, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", VERIFY_OTP_API, {
        PID,
        Otp,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("OTP Verified Successfully");
      localStorage.setItem("tempToken", response?.data?.user?.AccessToken);
      localStorage.setItem("tempUser", JSON.stringify(response?.data?.user));
      localStorage.removeItem("tempData");
      navigate("/registration-form");
    } catch (error) {
      console.log("OTP VERFICATION API ERROR", error);
      toast.error("OTP Verfication Failed :", error?.response?.data?.message);
      navigate("/signup");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPLOAD IMAGE
export const uploadCompanyLogo = async (formData, token, navigate) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", UPLOAD_IMAGE_API, formData, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

//FETCH SALES AGENT INFO
export const verifySalesAgent = async (id, token) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      FETCH_SALESAGENT_INFO_API + id,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (!response.status === 200) {
      throw new Error(response?.data?.message);
    }

    result = response?.data;
  } catch (error) {
    console.log("FETCH_SALESAGENT_INFO_API API ERROR", error);
    result = error?.response?.data?.message;
  }
  toast.dismiss(toastId);
  return result;
};

//REGISTER PARTNER COMPANY
export function registerPartnerCompany(token, data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", REGISTER_COMPANY_API, data, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      dispatch(setToken(localStorage.getItem("tempToken")));
      const res = JSON.parse(localStorage.getItem("tempUser"));
      const userImage = res?.image
        ? res?.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${res?.Name}`;
      dispatch(setUser({ ...res, image: userImage }));
      toast.success("Company Registered Successfully");
      navigate("/membership");
      localStorage.removeItem("tempUser");
      localStorage.removeItem("tempToken");
    } catch (error) {
      console.log("REGISTER COMPANY API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Failed to register company"
      );
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//FORGOT PASSWORD
export function sendForgotPasswordOtp(Identifier, LoginType, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          email: Identifier,
          // LoginType,
        };
      } else {
        requestBody = {
          PhoneNumber: Identifier,
          // LoginType,
        };
      }

      const response = await apiConnector(
        "POST",
        FORGOT_PASSWORD_API,
        requestBody
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const localStorageData = {
        ...requestBody,
        LoginType: LoginType,
      };

      toast.success("OTP Sent Successfully");
      localStorage.setItem("tempData", JSON.stringify(localStorageData));
      navigate("/verify-forgot-password-otp");
    } catch (error) {
      console.log("SEND FORGOT PASSWORD OTP API", error);
      toast.error(
        error?.response?.data?.message ||
        "Failed to send otp for password reset"
      );
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//VERIFY FORGOT PASSOWRD OTP
export function verifyForgotPasswordOTP(Identifier, Otp, LoginType, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      let requestBody;
      if (LoginType === "0") {
        requestBody = {
          email: Identifier,
          Otp,
        };
      } else {
        requestBody = {
          PhoneNumber: Identifier,
          Otp,
        };
      }
      const response = await apiConnector(
        "POST",
        VERIFY_FORGOT_PASSWORD_OTP_API,
        requestBody
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("OTP Verified Successfully");
      localStorage.setItem("resetToken", response?.data?.AccessToken);
      localStorage.setItem("resetOtp", Otp);
      localStorage.setItem("isForgotPassword", true);
      localStorage.removeItem("tempData");
      navigate("/reset-password");
    } catch (error) {
      console.log("OTP VERFICATION API ERROR", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      // navigate("/forgot-password");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//RESET PASSWORD
export function resetPassword(token, data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", RESET_PASSWORD_API, data, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Password Reset Successfully");
      localStorage.removeItem("resetToken");
      localStorage.removeItem("resetOtp");
      navigate("/reset-password-success");
    } catch (error) {
      console.log("RESET PASSWORD API ERROR", error);
      toast.error(error?.response?.data?.message || "Failed to reset password");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE_USER_ACTIVE_STATUS
export function updateUserActiveStatus(token, data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    const isForgotPassword = localStorage.getItem("isForgotPassword");
    if (!isForgotPassword) {
      try {
        const response = await apiConnector("POST", UPDATE_USER_ACTIVE_STATUS_API, data, {
          Authorization: `Bearer ${token}`,
        });

        if (!response?.status === 200) {
          throw new Error(response?.data?.message);
        }
      } catch (error) {
        console.log("RESET PASSWORD API ERROR", error);
        toast.error(error?.response?.data?.message || "Failed to change Account Status");
      }
    }
    localStorage.removeItem("isForgotPassword");
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  }
}

//LOGOUT
export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(setCompany(null));
    dispatch(setLogo(null));
    dispatch(setMembership(null));
    dispatch(setIsSubscribed(null));
    dispatch(setProduct([]));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    localStorage.removeItem("logoURL");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("isSubscribed");
    localStorage.removeItem("stripeResponse");
    localStorage.removeItem("product");
    toast.success("Logged Out");
    navigate("/");
  };
}
