import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { membershsipEndpoints } from "../apis";
import {
  setMembership,
  setCurrentPlan,
  setIsSubscribed,
  setLoading,
} from "../../slices/membershipSlice";
import { membershipPlans } from "../../data/plans";

const {
  STRIPE_REQUEST_PAYMENT_API,
  STRIPE_TRANSACTION_STATUS_UPDATE_API,
  GET_MEMBERSHIP_INFO_API,
  UPGRADE_MEMBERSHIP_PLAN_API,
} = membershsipEndpoints;

//REQUEST STRIPE PAYMENT
export async function requestStripePayment(token, data) {
  let result = null;
  try {
    const response = await apiConnector(
      "POST",
      STRIPE_REQUEST_PAYMENT_API,
      data,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    // console.log("REQUEST STRIPE API RESPONSE............", response);

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }
    result = response?.data;
    window.location.href = response?.data?.url;
  } catch (error) {
    console.log("REQUEST STRIPE API ERROR............", error);
  }
  return result;
}

//UPDATE STRIPE TRANSACTION STATUS
export function updateStripeTransactionStatus(data, token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        STRIPE_TRANSACTION_STATUS_UPDATE_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "STRIPE_TRANSACTION_STATUS_UPDATE_API RESPONSE............",
        response
      );
      // dispatch(getMembershipInfo(token));
    } catch (error) {
      console.log(
        "STRIPE_TRANSACTION_STATUS_UPDATE_API ERROR............",
        error
      );
    }
    // toast.dismiss(toastId);
    // dispatch(setLoading(false));
  };
}

//GET MEMBERSHIP INFO
export function getMembershipInfo(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_MEMBERSHIP_INFO_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      // if (!response?.status === 200) {
      //   throw new Error(response?.data?.message);
      // }
      console.log("GET_MEMBERSHIP_INFO_API RESPONSE.........", response);

      let activeSubscriptionFound = false;

      for (const subscription of response.data.subscriptions) {
        if (subscription.status === "active") {
          const renewalDate = subscription.currentPeriodEnd;
          dispatch(setMembership(subscription));
          dispatch(setIsSubscribed(true));
          if (subscription.items[0].interval === "month") {
            dispatch(
              setCurrentPlan({
                ...membershipPlans[0],
                billingDate: renewalDate,
              })
            );
          } else {
            dispatch(
              setCurrentPlan({
                ...membershipPlans[1],
                billingDate: renewalDate,
              })
            );
          }
          activeSubscriptionFound = true;
          break;
        }
      }

      if (!activeSubscriptionFound) {
        dispatch(setIsSubscribed(false));
      }
    } catch (error) {
      console.log(
        "GET_MEMBERSHIP_INFO API ERROR............",
        error?.response?.data?.message || error?.message
      );
    }
    // toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}

// UPGRADE MEMBERSHIP PLAN
export function upgradeMembershipPlan(token, data, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        UPGRADE_MEMBERSHIP_PLAN_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(
        "GET_MEMBERSHIP_INFO_API UPGRADE_MEMBERSHIP_PLAN_API RESPONSE.........",
        response
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success(response?.data?.message);
      dispatch(getMembershipInfo(token));
      navigate("/upgrade-successful");
    } catch (error) {
      console.log("UPGRADE_MEMBERSHIP_PLAN_API ERROR............", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
    // toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}
