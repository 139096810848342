import React, { useState } from "react";
import check from "../assets/Images/icon/check.png";
import building from "../assets/Images/icon/building.png";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { upgradeMembershipPlan } from "../services/operations/membershipAPI";
import ModalComponent from "./Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function SubscribedUser() {
  const { loading, membership, currentPlan } = useSelector(
    (state) => state.membership
  );

  console.log("====================================");
  console.log(loading);
  console.log("====================================");
  const { token } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    let data = {
      subscriptionIdToUpgrade: membership?.subscriptionId,
    };
    dispatch(upgradeMembershipPlan(token, data, navigate));
  };
  return (
    <>
      <div className="m-10 flex flex-col justify-between h-[calc(100vh-9rem)]">
        <div>
          {/* Membership */}
          <h1 className="text-black text-sm sm:text-xl font-semibold font-open ">
            Membership
          </h1>

          {/* Primary Card */}
          <div className="flex items-center space-x-4 mt-4">
            {/* Purchsed Card */}
            <div
              className={`relative border-4 border-lime-400  bg-gradient-to-br from-blue-400 to-cyan-400 shadow-lg rounded-lg p-4 sm:p-4 w-1/2 flex flex-col items-center justify-center`}
            >
              {/* Selected label and green tick */}
              <div className="absolute top-4 right-4 bg-green-100 text-lime-600 text-xs font-medium font-inter px-2 py-1 rounded-xl flex items-center gap-1">
                <span className="hidden sm:flex">Selected</span>
                <FaCheckCircle />
              </div>
              <p className="text-white text-xs font-medium font-inter mt-4">
                {currentPlan?.type}
              </p>
              <h1 className="text-white text-7xl font-semibold my-4">
                {currentPlan?.price}
              </h1>
              <h1 className="text-white text-xs font-medium font-inter">
                Next Billing Date :{" "}
                {moment(currentPlan?.billingDate).format("DD MMM YYYY")}
              </h1>
            </div>

            {/* Purchased Details */}
            <div className="w-1/2">
              <div className="flex items-center space-x-3">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  Covers up-to {currentPlan?.branches} branches or collection
                  centers
                </p>
                {[...Array(currentPlan?.branches)].map((_, i) => (
                  <img
                    key={i}
                    src={building}
                    alt="building icon"
                    className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                  />
                ))}
              </div>

              <div className="flex items-center space-x-3 mt-4">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  AllesHealth Commission Fee: {currentPlan?.commissionFee}
                </p>
              </div>
            </div>
          </div>

          {/* Secondary Card  */}
          <div className="flex items-center space-x-4 mt-8">
            <div
              className={`relative border border-black bg-slate-200 shadow-lg rounded-lg p-4 sm:p-4 w-1/2 flex flex-col items-center justify-center`}
            >
              <p className="text-black text-xs font-medium font-inter mt-4">
                {currentPlan?.type === "Monthly Payment"
                  ? "Yearly Payment"
                  : "Monthly Payment"}
              </p>
              <h1 className="text-black text-7xl font-semibold font-inter my-4">
                {currentPlan?.price === "INR 250" ? "INR 2,500" : "INR 250"}
              </h1>
            </div>
            {/* Purchased Details */}
            <div className="w-1/2">
              <div className="flex items-center space-x-3">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  Covers up-to {currentPlan?.branches === 2 ? 3 : 2} branches or
                  collection centers
                </p>
                {[...Array(currentPlan?.branches === 2 ? 3 : 2)].map((_, i) => (
                  <img
                    key={i}
                    src={building}
                    alt="building icon"
                    className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                  />
                ))}
              </div>

              <div className="flex items-center space-x-3 mt-4">
                <img
                  src={check}
                  alt="check icon"
                  className="w-6 h-6 object-contain"
                />
                <p className="text-black text-base font-normal font-open">
                  AllesHealth Commission Fee:{" "}
                  {currentPlan?.commissionFee === "6%" ? "8%" : "6%"}
                </p>
              </div>
            </div>
          </div>

          {/* {currentPlan?.type === "Monthly Payment" && (
            <div className="mt-12 max-w-xs mx-auto">
              <button
                onClick={() => setShowModal(true)}
                className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
              >
                Upgrade to Yearly Plan
              </button>
            </div>
          )} */}
        </div>

        {/* more info email  */}
        <div>
          <div className="text-zinc-950 text-xs sm:text-sm font-normal font-open">
            Got more collection centres? Contact us to get the customised
            pricing for your business.
          </div>

          <a
            href="mailto:info@alleshealth.com"
            className="text-sky-500 text-xs sm:text-sm"
          >
            info@alleshealth.com
          </a>
        </div>
      </div>
      <ModalComponent show={showModal}>
        <div>
          <div className="bg-white text-center max-w-screen-xl">
            <div className="flex justify-center items-center">
              <h1 className="text-black text-2xl font-bold font-open flex-grow">
                Upgrade to Yearly Plan
              </h1>
              <div
                onClick={() => setShowModal(false)}
                className="cursor-pointer"
              >
                <AiOutlineCloseCircle className="text-slate-300 w-7 h-7" />
              </div>
            </div>
            <div className="px-10">
              <div className="bg-white rounded-lg border-4 border-sky-100 flex flex-col items-center justify-center my-5 p-5">
                <p className="text-zinc-500 text-xs font-medium font-inter">
                  Membership
                </p>
                <h1 className="text-black text-5xl  font-semibold font-inter my-4">
                  INR 2,500
                </h1>
                <div className="flex items-center justify-center space-x-3">
                  <img
                    src={check}
                    alt="check icon"
                    className="w-6 h-6 object-contain"
                  />
                  <p className="text-black text-base font-normal font-open">
                    Covers up-to 3 branches or collection centers
                  </p>
                  {[...Array(3)].map((_, i) => (
                    <img
                      key={i}
                      src={building}
                      alt="building icon"
                      className="w-4 h-4 sm:w-6 sm:h-6 object-contain"
                    />
                  ))}
                </div>
                <div className="flex items-center justify-center space-x-3 mt-6 mb-4">
                  <img
                    src={check}
                    alt="check icon"
                    className="w-6 h-6 object-contain"
                  />
                  <p className="text-black text-base font-normal font-open">
                    AllesHealth Commission Fee: 6%
                  </p>
                </div>
              </div>

              <div className="bg-neutral-50 flex justify-between items-center py-3 px-5 rounded-md shadow">
                <div className="text-zinc-500 text-sm font-semibold font-open">
                  Next Payment Date
                </div>
                <div className="text-right text-black text-sm font-normal font-open">
                  {moment().add(1, "years").format("DD MMM YYYY")}
                </div>
              </div>

              <div className="px-5 py-3 mt-3 bg-sky-100 rounded-lg">
                <div className="text-sky-400 text-xs font-semibold font-open text-left">
                  Message: <br />
                  Your Subscription will be updated to Yearly Plan at the End of
                  the Current Plan.
                </div>
              </div>
            </div>

            {/* Upgrade Button */}
            {loading ? (
              <div className="flex items-start justify-center gap-3 mt-5">
                <div className="spinner2"></div>
                We are upgrading your plan. Please hold on.
              </div>
            ) : (
              <button
                onClick={handleUpgradePlan}
                className="w-3/4 mt-5 bg-sky-500 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
                disabled={loading}
              >
                Upgrade Plan
              </button>
            )}
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

export default SubscribedUser;
