import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  registerMerchant,
  sendForgotPasswordOtp,
  verifyForgotPasswordOTP,
  verifyMerchantOTP,
} from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";

function ForgotPasswordOtpForm() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signupData, loading } = useSelector((state) => state.auth);
  const tempData = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : "";

  useEffect(() => {
    if (!tempData) {
      navigate("/forgot-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendOTP = () => {
    dispatch(
      sendForgotPasswordOtp(
        tempData.email || tempData.PhoneNumber,
        tempData.LoginType,
        navigate
      )
    );

    setOtp("");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      toast.error("Please enter a valid OTP");
      return;
    }
    dispatch(
      verifyForgotPasswordOTP(
        tempData.email || tempData.PhoneNumber,
        otp,
        tempData.LoginType,
        navigate
      )
    );

    setOtp("");
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg py-10 px-5 w-[420px]">
          <img src={logo} alt="logo" className="w-60 h-16 object-contain" />
          <h1 className="text-black text-3xl font-bold font-Open mt-8">
            Enter OTP
          </h1>
          <p className="text-black text-sm font-normal font-open leading-tight mt-4">
            4 digit code has been sent to{" "}
            <span>{tempData?.email || tempData?.PhoneNumber}</span>
          </p>
          <form onSubmit={handleOnSubmit} className="mt-8">
            <div className="mr-20">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px] lg:w-[50px] border-0 bg-gray-100 rounded-md text-black text-2xl font-bold font-orbitron aspect-square text-center focus:border-0 focus:outline-1 focus:outline-sky-400"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />
            </div>

            <div className="mt-16">
              <span className="text-gray-500 text-xs font-normal font-open">
                If you didn't receive code!{" "}
              </span>
              <span
                onClick={handleResendOTP}
                className="text-sky-400 text-xs font-normal font-open underline cursor-pointer"
              >
                Resend Code
              </span>
            </div>
            <div className="mt-24">
              <button
                type="submit"
                className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordOtpForm;
