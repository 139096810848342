import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Images/logo.png";
import upload from "../assets/Images/icon/upload.png";
import toast from "react-hot-toast";
import { AlertCircle, CheckCircle } from "lucide-react";
import {
  registerPartnerCompany,
  uploadCompanyLogo,
  verifySalesAgent,
} from "../services/operations/authAPI";
import imageCompression from "browser-image-compression";
import { set } from "lodash";

function RegistrationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("tempToken");
  const user = JSON.parse(localStorage.getItem("tempUser"));
  const [agentDetails, setAgentDetails] = useState(null);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    CompanyName: "",
    LogoLocation: "",
    PanCardNumber: "",
    GSTIN: "",
    PhoneCountryCode: "+91",
    PhoneNumber: user?.Phone || "",
    Website: "",
    mainEmail: user?.Email || "",
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
    ShortDescription: "",
    BankName: "",
    BankAccountNumber: "",
    IFSC_ID: "",
    AllesHealthSalesAgentID: "",
    Latitude: "",
    Longitude: "",
    TypeOfBusiness: "",
  });
  const [errors, setErrors] = useState({
    CompanyName: "",
    LogoLocation: "",
    PanCardNumber: "",
    PhoneNumber: "",
    mainEmail: "",
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
    BankName: "",
    BankAccountNumber: "",
    IFSC_ID: "",
    TypeOfBusiness: "",
  });
  const getGeolocation = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          formData.StreetAddress
        )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
      );
      const data = await response.json();

      if (data.results && data.results[0]?.geometry?.location) {
        const { lat, lng } = data.results[0]?.geometry.location;
        console.log("Geolocation:", lat, lng);
        return { lat, lng };
      } else {
        console.error("Geolocation data not found in API response");
        return null;
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null;
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const mandatoryFields = [
      "CompanyName",
      "LogoLocation",
      "PanCardNumber",
      "PhoneNumber",
      "mainEmail",
      "StreetAddress",
      "City",
      "State",
      "ZipCode",
      "BankName",
      "BankAccountNumber",
      "IFSC_ID",
      "TypeOfBusiness",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    const geolocation = await getGeolocation();
    if (geolocation !== null) {
      const { lat, lng } = geolocation;
      setFormData((prev) => ({
        ...prev,
        Latitude: lat.toString(),
        Longitude: lng.toString(),
      }));
    } else {
      toast.error("Please provide a valid address.");
      setFormData((prev) => ({
        ...prev,
        Latitude: "",
        Longitude: "",
      }));
      setErrors((prev) => ({
        ...prev,
        StreetAddress: "Invalid address",
        City: "Invalid address",
        State: "Invalid address",
        ZipCode: "Invalid address",
      }));
      return;
    }
  };

  useEffect(() => {
    if (formData.Latitude && formData.Longitude) {
      dispatch(registerPartnerCompany(token, formData, navigate));
    }
  }, [formData.Latitude, formData.Longitude]);

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "PhoneNumber":
        maxLength = 10;
        break;
      case "ZipCode":
        maxLength = 6;
        break;
      // case "BankAccountNumber":
      //   maxLength = 14;
      //   break;

      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // Check if the value is numeric and enforce maximum length
    if (event.target.type === "number") {
      // For input type number, handle non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      if (!isNaN(numericValue) && numericValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      // For other types, handle validation without removing non-numeric characters
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  }

  const verifySalesAgentDetails = async () => {
    try {
      const res = await verifySalesAgent(formData?.AllesHealthSalesAgentID);
      setAgentDetails(res);
    } catch (error) {
      console.log("Error occured while verifying sales agent", error);
    }
  };
  useEffect(() => {
    if (formData?.AllesHealthSalesAgentID.length === 7) {
      verifySalesAgentDetails();
    }
  }, [formData?.AllesHealthSalesAgentID]);

  useEffect(() => {
    if (!token) {
      console.log("Token not found! Redirecting to login page.");
      navigate("/login");
    }
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    // setImage(file);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("partnerCompanyLogo", file);
    try {
      const res = await uploadCompanyLogo(formData, token);
      setFormData((prev) => ({
        ...prev,
        LogoLocation: res?.logoUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        LogoLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" bg-sky-100 h-full p-5 sm:p-10 scroll-smooth">
      <div className="bg-white rounded-md shadow-lg px-6 py-3 sm:py-6 sm:px-12 max-w-screen-md mx-auto">
        <div className="flex item-center justify-between">
          <h1 className="text-black text-sm sm:text-lg font-semibold font-open">
            New AllesHealth Partner
          </h1>
          {/* alleshealth logo */}
          <img
            src={logo}
            alt="alleshealth logo"
            className="w-24 h-8 sm:w-36 sm:h-10 object-contain"
          />
        </div>

        <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>
        <div>
          <form onSubmit={submitHandler} className="">
            {/* Company Name  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Company Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="CompanyName"
                  value={formData.CompanyName}
                  onChange={handleOnChange}
                  placeholder="ABC Company"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.CompanyName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Upload Company Logo */}
            <div className="mb-6">
              <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                Upload Logo
                <sup className="text-red-600">*</sup>
              </p>
              <label
                className={`w-full border-dashed ${
                  errors.LogoLocation ? "border-red-500" : "border-neutral-400"
                }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
              >
                {formData?.LogoLocation ? (
                  <>
                    <img
                      src={image}
                      alt="upload icon"
                      className="w-30 h-20 object-contain"
                    />

                    <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center indivne-flex mt-3">
                      <p className="text-sky-400 text-xs font-semibold font-open">
                        Change Logo
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={upload}
                      alt="upload icon"
                      className="w-10 h-10 object-contain"
                    />
                    <span className="text-sky-400 text-xs font-normal font-open mt-1">
                      Upload Here
                    </span>
                    <span className="text-neutral-400 text-[11px] font-normal font-open mt-1">
                      File : JPG, PNG, SVG Max 20 Mb
                    </span>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="LogoLocation"
                  // name="image"
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            {/* Dropdown Menu */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Type of Business
                  <sup className="text-red-600">*</sup>
                </p>
                <select
                  name="TypeOfBusiness"
                  value={formData.TypeOfBusiness}
                  onChange={handleOnChange}
                  // disabled={!isEdit}
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border  ${
                    errors.TypeOfBusiness ? "border-red-500" : ""
                  } text-sky-400 focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                >
                  <option className="text-black" value="">
                    Select an Option
                  </option>
                  <option className="text-black" value="LABORATORY">
                    Lab Test
                  </option>
                  <option className="text-black" value="CLINIC/HOSPITAL">
                    Treatment
                  </option>
                  <option className="text-black" value="STORES">
                    Merchandise
                  </option>
                  <option className="text-black" value="OTHERS">
                    Others
                  </option>
                </select>
              </label>
            </div>
            {/* Pan Card Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Pan Card Number
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="PanCardNumber"
                  value={formData.PanCardNumber}
                  onChange={handleOnChange}
                  maxLength={10}
                  placeholder="Input Company's Pan Card Number here (10 Characters)"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.PanCardNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Regular GSTIN  (If Applicable) */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Regular GSTIN (If Applicable)
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="GSTIN"
                  value={formData.GSTIN}
                  onChange={handleOnChange}
                  maxLength={14}
                  placeholder="Input your GSTIN Number here (14 Characters)"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Phone Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="number"
                  name="PhoneNumber"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.PhoneNumber}
                  onChange={handleOnChange}
                  maxLength={10}
                  disabled={user?.Phone ? true : false}
                  placeholder="Input your Phone Number here (10 Digits)"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Email* */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="mainEmail"
                  value={formData.mainEmail}
                  onChange={handleOnChange}
                  disabled={user?.Email ? true : false}
                  placeholder="MyCompany@company.com"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.mainEmail ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Website */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Website
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="Website"
                  value={formData.Website}
                  onChange={handleOnChange}
                  placeholder="Input your address here"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Street Address* */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Street Address
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="StreetAddress"
                  value={formData.StreetAddress}
                  onChange={handleOnChange}
                  placeholder="Input your address here"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.StreetAddress ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* City State Pincode  */}
            <div>
              <div className="flex flex-col md:flex-row md:space-x-2">
                {/* City */}
                <div className="mb-6 md:w-1/2">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      City
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="City"
                      value={formData.City}
                      onChange={handleOnChange}
                      placeholder="Input your City here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                        errors.City ? "border-red-500" : ""
                      }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* State */}
                <div className="mb-6 md:w-1/2">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      State
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="text"
                      name="State"
                      value={formData.State}
                      onChange={handleOnChange}
                      placeholder="Input your State here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                        errors.State ? "border-red-500" : ""
                      }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>

                {/* Pincode */}
                <div className="mb-6 md:w-1/2">
                  <label>
                    <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                      Pincode
                      <sup className="text-red-600">*</sup>
                    </p>
                    <input
                      type="number"
                      name="ZipCode"
                      onKeyDown={(e) => {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      value={formData.ZipCode}
                      onChange={handleOnChange}
                      maxLength={6}
                      placeholder="Input your Pincode here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                        errors.ZipCode ? "border-red-500" : ""
                      }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </label>
                </div>
              </div>
            </div>
            {/* Short Description of Services offered for Patients */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Short Description of Services offered for Patients
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <textarea
                  type="text"
                  name="ShortDescription"
                  value={formData.ShortDescription}
                  onChange={handleOnChange}
                  placeholder="Input your description here"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Bank Account (Will  be used for Payouts)  */}
            <h1 className="text-zinc-950 text-sm font-semibold font-open mb-5">
              Bank Account (Will be used for Payouts)
            </h1>
            {/* Bank Name */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  Bank Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="BankName"
                  value={formData.BankName}
                  onChange={handleOnChange}
                  placeholder="Input your bank name here"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                    errors.BankName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Account Number and IFSC Code */}
            <div className="flex flex-col md:flex-row md:space-x-2">
              {/* Account Number */}
              <div className="mb-6 md:w-1/2">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Account Number
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="number"
                    name="BankAccountNumber"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    value={formData.BankAccountNumber}
                    onChange={handleOnChange}
                    placeholder="Input your account number here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.BankAccountNumber ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* IFSC Code */}
              <div className="mb-6 md:w-1/2">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    IFSC ID
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="IFSC_ID"
                    value={formData.IFSC_ID}
                    onChange={handleOnChange}
                    placeholder="Input your IFSC ID here (11 Characters)"
                    maxLength={11}
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.IFSC_ID ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
            </div>
            {/* AllesHealth Sales Agent ID* */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                  AllesHealth Sales Agent ID
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="text"
                  name="AllesHealthSalesAgentID"
                  value={formData.AllesHealthSalesAgentID}
                  onChange={handleOnChange}
                  maxLength={7}
                  placeholder="Sales Agent ID"
                  className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
                {agentDetails !== null && (
                  <>
                    {agentDetails?.Name ? (
                      <div className="flex items-center mt-3">
                        <CheckCircle size={15} className="text-green-400" />
                        <p className="text-green-400 text-xs font-semibold font-open ml-2">
                          {agentDetails?.Name}
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center mt-3">
                        <AlertCircle size={15} className="text-red-500" />
                        <p className="text-red-500 text-xs font-semibold font-open ml-2">
                          {agentDetails}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </label>
            </div>
            {/* Confirm Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-sky-400 rounded-lg text-center text-white text-xs font-semibold font-open py-4 px-32 sm:px-44 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
