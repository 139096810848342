import React, { useState } from "react";

import subscriptionImage from "../assets/Images/subscription.png";
import reachMorePatients from "../assets/Images/icon/reachMorePatients.png";
import saveUpYourMarketingCost from "../assets/Images/icon/saveUpYourMarketingCost.png";
import marketYourBusiness from "../assets/Images/icon/marketYourBusiness.png";
import saveOnReferralsFees from "../assets/Images/icon/saveOnReferralsFees.png";
import boostYourSales from "../assets/Images/icon/boostYourSales.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

function ProfileMembershipCard() {
  const navigate = useNavigate();
  const { isSubscribed, currentPlan } = useSelector(
    (state) => state.membership
  );
  return (
    <div>
      {isSubscribed ? (
        <div className=" bg-sky-400 rounded-xl py-10">
          {/* Text */}
          <div className="flex flex-col items-center justify-center">
            <p className="text-sky-100 text-xs font-medium font-inter">
              {currentPlan?.type}
            </p>
            <h1 className="text-neutral-50 text-7xl font-semibold font-inter my-2">
              {currentPlan?.price}
            </h1>
            <p className="text-sky-100 text-xs font-medium font-inter">
              Next Billing Date :{" "}
              {moment(currentPlan?.billingDate).format("DD MMM YYYY")}
            </p>
          </div>

          {/* Buttons */}
          {/* {currentPlan?.type === "Monthly Payment" && (
            <div className="flex items-center justify-around mt-10 space-x-5 mx-5">
              <button
                onClick={() => navigate("/membership")}
                className="bg-sky-100 rounded-lg text-center text-sky-400 text-xs font-semibold font-open py-2 w-2/3"
              >
                Upgrade to Yearly Subscription
              </button>
            </div>
          )} */}
        </div>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h1 className="text-center text-black text-base font-bold font-open">
            Currently you don't have Membership with us
          </h1>
          <img
            src={subscriptionImage}
            alt=""
            className=" w-56 h-36 object-contain mx-auto my-4"
          />
          <h1 className="text-center text-sky-400 text-xl font-bold font-open">
            Perks of getting Membership
          </h1>

          <div className=" font-open mt-4">
            <div className="flex items-center justify-center space-x-4">
              <div className="flex items-center">
                <img
                  src={reachMorePatients}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <p className="text-sky-400 text-xs font-normal font-open ml-1">
                  Reach more Patients
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={saveUpYourMarketingCost}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <p className="text-sky-400 text-xs font-normal font-open ml-1">
                  Save up your marketing cost
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center space-x-4 my-2">
              <div className="flex items-center">
                <img
                  src={marketYourBusiness}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <p className="text-sky-400 text-xs font-normal font-open ml-1">
                  Market your business
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={saveOnReferralsFees}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <p className="text-sky-400 text-xs font-normal font-open ml-1">
                  Save on referral fees
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <img
                src={boostYourSales}
                alt=""
                className="w-6 h-6 object-contain"
              />
              <p className="text-sky-400 text-xs font-normal font-open ml-1">
                Boost your sales
              </p>
            </div>
          </div>
          <div className="w-2/3 mx-auto mt-4">
            <button
              onClick={() => navigate("/membership")}
              className="w-full bg-sky-500 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-lg py-3 text-sky-100 text-sm font-semibold font-open shadow-sky-400 shadow-lg"
            >
              Get Premium Membership
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileMembershipCard;
