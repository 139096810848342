// This will prevent authenticated users from accessing this route
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ACCOUNT_TYPE, MERCHANT_TYPE } from "../utils/constants";

function OpenRoute({ children }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { company } = useSelector((state) => state.profile);

  if (token === null) {
    return children;
  } else {
    if (user?.UserType === ACCOUNT_TYPE.SALESAGENT) {
      return <Navigate to="/partner-form" />;
    } else {
      if (company?.AccountStatus !== MERCHANT_TYPE.PRIMARY_ACCOUNT){
        return children;
      }
      else return <Navigate to="/dashboardAdmin" />;
    }

    // return <Navigate to="/dashboard/partner-form" />
  }
}

export default OpenRoute;
