import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OfferCard from "../components/OfferCard";
import NoOffer from "../components/NoOffer";
import { updateOffersData } from "../slices/OffersSlice";
import salesImage from "../assets/Images/sales.png";
import SalesCard from "../components/SalesCard";
import ModalComponent from "../components/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import uploadDocument from "../assets/Images/icon/uploadReport.png";
import { pdfjs } from "react-pdf";
import {
  getDecryptedReport,
  markSalesAsFulfilled,
  saveReport,
  sendReport,
  uploadPatientReport,
} from "../services/operations/productAPI";
import toast from "react-hot-toast";
import { setReportPath } from "../slices/productSlice";
import PdfComp from "../components/PdfComp";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function DashboardAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const {
    product,
    reportPath,
    loading: productLoading,
  } = useSelector((state) => state.product);
  const {
    isSubscribed,
    currentPlan,
    loading: membershipLoading,
  } = useSelector((state) => state.membership);

  const [selectedCard, setSelectedCard] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [showConfimationModal, setShowConfimationModal] = useState(false);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [reportType, setReportType] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchDecryptedReport = async () => {
    try {
      const data = {
        PhysicalReport: selectedSales?.PhysicalReport,
        ReportID: selectedSales?.ReportID,
        OrderID: selectedSales?.OrderID,
      };

      console.log("====================================");
      console.log("data", data);
      console.log("====================================");
      const res = await getDecryptedReport(data);
      console.log("====================================");
      console.log("res", res);
      console.log("====================================");
      setPdfUrl(res?.filePath);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedSales !== null && showPDFModal) {
      fetchDecryptedReport();
    }
  }, [selectedSales]);

  if (productLoading || membershipLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (isEmpty(product)) {
    return <NoOffer isSubscribed={isSubscribed} />;
  }

  const UpdateFormData = (data) => {
    let Data = {
      ...data,
      isEdit: true,
    };
    dispatch(updateOffersData(Data));
    navigate("/createOffer");
  };

  function handleButtonClick(e) {
    setReportType(e.target.value);
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if a file is selected

    // Check if the file size is more than 10 MB
    if (file.size > 10 * 1024 * 1024) {
      toast.error("File exceeds 10MB limit.");
      e.target.value = null;
      return;
    }

    // setSelectedFile(file);
    uploadReport(file);
    e.target.value = null;
  };

  const markAsComplete = () => {
    dispatch(markSalesAsFulfilled(token, selectedSales.OrderID));
    setShowConfimationModal(false);
  };

  const uploadReport = async (file) => {
    const formData = new FormData();
    formData.append("documents", file);
    try {
      const res = await uploadPatientReport(formData, token);
      dispatch(setReportPath(res?.report_URL));
      setSelectedFile(file);
      // setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const saveReportInDB = () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }
    if (!reportType) {
      toast.error("Please select a category");
      return;
    }
    const data = {
      PhysicalReport: reportPath,
      ReportType: reportType,
    };
    dispatch(saveReport(token, data, selectedSales.OrderID));
    setShowReportModal(false);
    setSelectedFile(null);
    setReportType("");
    setSelectedSales({});
  };

  const saveAndSendReport = () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }
    if (!reportType) {
      toast.error("Please select a category");
      return;
    }
    const data = {
      PhysicalReport: reportPath,
      ReportType: reportType,
      ReportID: selectedSales?.ReportID,
    };
    dispatch(sendReport(token, data, selectedSales.OrderID));
    setShowReportModal(false);
    setSelectedFile(null);
    setReportType("");
    setSelectedSales({});
  };

  const sendReportToPatient = (salesData) => {
    if (!salesData?.IsReportUploaded) {
      toast.error("Please upload a report to send");
      return;
    }
    const data = {
      PhysicalReport: salesData?.PhysicalReport,
      ReportType: salesData?.ReportType,
      ReportID: salesData?.ReportID,
    };
    dispatch(sendReport(token, data, salesData?.OrderID));
  };

  return (
    <>
      <div className="bg-sky-100 min-h-screen py-2 px-4 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
        {/* HEADER */}
        <div className="flex flex-row justify-between items-center">
          {/* TITLE */}
          <h1 className="text-black text-xl font-semibold font-open">
            Your Offerings on AllesHealth Platform
          </h1>

          {/* Get Premium Membership Label */}
          {!isSubscribed && (
            <div className="bg-rose-500 rounded-3xl justify-center items-center inline-flex space-x-1 p-2 mr-32">
              <p className="text-neutral-50 text-xs font-semibold font-open">
                Get Premium Membership
              </p>
              <div className="w-2 h-2 bg-neutral-50 rounded-full" />
              <p className="text-neutral-50 text-xs font-semibold font-open">
                Products will be in Pending stage
              </p>
            </div>
          )}

          {/* BUTTONS */}
          <div className="flex flex-row items-center space-x-4">
            {!isSubscribed && (
              <button
                type="button"
                className="px-3 py-2 bg-rose-500 rounded shadow justify-center items-center gap-2.5 inline-flex hover:shadow-md transition duration-300 ease-in-out"
                onClick={() => {
                  navigate("/membership");
                }}
              >
                <div className="text-sky-100 text-sm font-normal font-open">
                  Go Premium
                </div>
              </button>
            )}
            <button
              type="button"
              className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex hover:shadow-md transition duration-300 ease-in-out"
              onClick={() => {
                navigate("/createOffer");
              }}
            >
              <div className="text-sky-100 text-sm font-normal font-open">
                Create Offer
              </div>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
          {product?.map((product) => (
            <OfferCard
              key={product.PPID}
              product={product}
              UpdateFormData={UpdateFormData}
              isSubscribed={isSubscribed}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          ))}
        </div>
        <div>
          <div>
            {selectedCard === null ? (
              <>
                <h1 className="text-black text-xl font-semibold font-open mt-10">
                  Sales
                </h1>
                {product &&
                product.some((prod) => prod.SalesSummaries.length > 0) ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
                    {product.map((product) => {
                      if (product.SalesSummaries.length > 0) {
                        return product.SalesSummaries.map((sales) => (
                          <SalesCard
                            key={sales.OrderID}
                            sales={sales}
                            logoURL={product.logoURL}
                            selectedCard={selectedCard}
                            currentPlan={currentPlan}
                            showReportModal={showReportModal}
                            setShowReportModal={setShowReportModal}
                            setShowPDFModal={setShowPDFModal}
                            selectedSales={selectedSales}
                            setSelectedSales={setSelectedSales}
                            sendReportToPatient={sendReportToPatient}
                            setShowConfimationModal={setShowConfimationModal}
                          />
                        ));
                      }
                      return null;
                    })}
                  </div>
                ) : (
                  <>
                    <div className="bg-white mt-5 p-10">
                      <img
                        src={salesImage}
                        alt=""
                        className="w-96 h-64 object-contain mx-auto"
                      />
                      <h1 className="text-black text-lg font-normal font-open mt-6 text-center">
                        If there are sales generated for your offerings, those
                        will be listed here. <br />
                        Come back here to check again.
                      </h1>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="flex flex-row items-center mt-10">
                  <h1 className="text-black text-xl font-semibold font-open ">
                    Sales ({selectedCard?.ProductName})
                  </h1>
                  <div
                    className="ml-5 text-sky-400 text-base font-normal font-open underline cursor-pointer"
                    onClick={() => setSelectedCard(null)}
                  >
                    See for All Products
                  </div>
                </div>
                {selectedCard?.SalesSummaries?.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
                    {selectedCard?.SalesSummaries?.map((sales) => (
                      <SalesCard
                        key={sales.OrderID}
                        sales={sales}
                        selectedCard={selectedCard}
                        currentPlan={currentPlan}
                        showReportModal={showReportModal}
                        setShowReportModal={setShowReportModal}
                        setShowPDFModal={setShowPDFModal}
                        selectedSales={selectedSales}
                        setSelectedSales={setSelectedSales}
                        sendReportToPatient={sendReportToPatient}
                        setShowConfimationModal={setShowConfimationModal}
                      />
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="bg-white mt-5 p-10">
                      <img
                        src={salesImage}
                        alt=""
                        className="w-96 h-64 object-contain mx-auto"
                      />
                      <h1 className="text-black text-lg font-normal font-open mt-6 text-center">
                        If there are sales generated for your offerings, those
                        will be listed here. <br />
                        Come back here to check again.
                      </h1>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent show={showReportModal}>
        <div className="bg-white text-center w-[300px] sm:w-[500px] md:w-[750px]">
          <div
            onClick={() => {
              setSelectedFile(null);
              setShowReportModal(false);
            }}
            className="cursor-pointer absolute top-5 right-5"
          >
            <AiOutlineCloseCircle className="text-slate-300 w-7 h-7" />
          </div>
          <div className="flex justify-center items-center">
            <img
              src={selectedSales?.logoURL}
              alt="sales"
              className="w-14 h-14 object-contain"
            />
            <h1 className="text-black text-sm sm:text-base font-semibold font-open ml-2">
              {selectedSales?.ProductName}
            </h1>
          </div>
          <div className="m-10">
            <label
              className={`w-full p-14 border-dashed border-sky-400 border-2 rounded-md flex flex-col items-center justify-center cursor-pointer`}
            >
              {selectedFile ? (
                <>
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/023/234/824/original/pdf-icon-red-and-white-color-for-free-png.png"
                    alt="upload icon"
                    className="w-30 h-20 object-contain"
                  />
                  {/* show file name over here  */}

                  <div>{selectedFile?.name}</div>

                  <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center indivne-flex mt-3">
                    <p className="text-sky-400 text-xs font-semibold font-open">
                      Change Document
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={uploadDocument}
                    alt="upload icon"
                    className="w-10 h-10 object-contain"
                  />
                  <span className="text-sky-400 text-base font-normal font-open mt-2">
                    Upload Document
                  </span>
                  <span className="text-neutral-400 text-sm font-normal font-open mt-1">
                    Max file size: 10MB
                  </span>
                </>
              )}
              <input
                type="file"
                accept="application/pdf"
                name="pdfLocation"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <h1 className="text-black text-sm font-semibold font-open text-center">
            Select Category
          </h1>
          <div className="flex flex-col sm:flex-row items-start sm:item-center justify-center space-y-3 sm:space-y-0 space-x-0 sm:space-x-5 mt-3">
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="reportType"
                value="Doctors Prescription"
                checked={reportType === "Doctors Prescription"}
                onChange={handleButtonClick}
                className="hidden"
              />
              <span
                className={`mr-1 w-4 h-4 border-2 rounded-full flex items-center justify-center ${
                  reportType === "Doctors Prescription"
                    ? "border-sky-400"
                    : "border-neutral-400"
                }`}
              >
                {reportType === "Doctors Prescription" && (
                  <span className="block w-2 h-2 bg-sky-400 rounded-full"></span>
                )}
              </span>
              <span
                className={
                  reportType === "Doctors Prescription"
                    ? "text-sky-400 text-sm font-semibold font-open"
                    : "text-neutral-400 text-sm font-semibold font-open"
                }
              >
                Doctor's Prescription
              </span>
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="reportType"
                value="Lab Reports"
                checked={reportType === "Lab Reports"}
                onChange={handleButtonClick}
                className="hidden"
              />
              <span
                className={`mr-1 w-4 h-4 border-2 rounded-full flex items-center justify-center ${
                  reportType === "Lab Reports"
                    ? "border-sky-400"
                    : "border-neutral-400"
                }`}
              >
                {reportType === "Lab Reports" && (
                  <span className="block w-2 h-2 bg-sky-400 rounded-full"></span>
                )}
              </span>
              <span
                className={
                  reportType === "Lab Reports"
                    ? "text-sky-400 text-sm font-semibold font-open"
                    : "text-neutral-400 text-sm font-semibold font-open"
                }
              >
                Lab Reports
              </span>
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="reportType"
                value="Imaging"
                checked={reportType === "Imaging"}
                onChange={handleButtonClick}
                className="hidden"
              />
              <span
                className={`mr-1 w-4 h-4 border-2 rounded-full flex items-center justify-center ${
                  reportType === "Imaging"
                    ? "border-sky-400"
                    : "border-neutral-400"
                }`}
              >
                {reportType === "Imaging" && (
                  <span className="block w-2 h-2 bg-sky-400 rounded-full"></span>
                )}
              </span>
              <span
                className={
                  reportType === "Imaging"
                    ? "text-sky-400 text-sm font-semibold font-open"
                    : "text-neutral-400 text-sm font-semibold font-open"
                }
              >
                Imaging Reports
              </span>
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="reportType"
                value="Others Reports"
                checked={reportType === "Others Reports"}
                onChange={handleButtonClick}
                className="hidden"
              />
              <span
                className={`mr-1 w-4 h-4 border-2 rounded-full flex items-center justify-center ${
                  reportType === "Others Reports"
                    ? "border-sky-400"
                    : "border-neutral-400"
                }`}
              >
                {reportType === "Others Reports" && (
                  <span className="block w-2 h-2 bg-sky-400 rounded-full"></span>
                )}
              </span>
              <span
                className={
                  reportType === "Others Reports"
                    ? "text-sky-400 text-sm font-semibold font-open"
                    : "text-neutral-400 text-sm font-semibold font-open"
                }
              >
                Others
              </span>
            </label>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-3 sm:space-y-0 space-x-0 sm:space-x-5 mt-10">
            <button
              onClick={() => saveReportInDB()}
              className={`w-72 px-3 py-3 justify-center items-center gap-2.5 inline-flex text-base font-semibold font-open rounded-lg shadow ${
                !reportType || !selectedFile
                  ? "bg-neutral-300 text-zinc-500 cursor-not-allowed"
                  : "bg-sky-100 text-center text-sky-400 hover:shadow-lg hover:bg-sky-200"
              }`}
            >
              Save
            </button>
            <button
              onClick={() => saveAndSendReport()}
              className={`w-72 px-3 py-3 justify-center items-center gap-2.5 inline-flex text-base font-semibold font-open rounded-lg shadow ${
                !reportType || !selectedFile
                  ? "bg-neutral-300 text-zinc-500 cursor-not-allowed"
                  : "bg-sky-500 text-center text-white hover:shadow-lg hover:bg-sky-600"
              }`}
            >
              Save & Send to Patient
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent show={showPDFModal}>
        <div className="bg-white text-center w-[300px] sm:w-[500px] md:w-[750px]">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img
                src={selectedSales?.logoURL}
                alt="sales"
                className="w-10 h-10 object-contain"
              />
              <h1 className="text-black text-sm sm:text-base font-semibold font-open ml-2">
                {selectedSales?.ProductName}
              </h1>
            </div>
            <div
              onClick={() => {
                setSelectedSales(null);
                setShowPDFModal(false);
              }}
              className="cursor-pointer absolute top-5 right-5"
            >
              <AiOutlineCloseCircle className="text-slate-300 w-7 h-7" />
            </div>
          </div>
          <div className="m-5">
            <div
              className={`flex flex-col items-center justify-center overflow-y-auto`}
            >
              {pdfUrl && <PdfComp pdf={pdfUrl} />}
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showConfimationModal}
        style={{ position: "absolute", top: 0 }}
      >
        <div className="bg-white text-center w-[200px] sm:w-[300px] md:w-[450px]">
          <div className="text-center text-black text-xl font-bold font-open">
            Are you sure?
          </div>
          <div className="text-center text-black text-lg font-normal font-open mt-4">
            Would You Like to Confirm the Completion of this Sale?
          </div>
          <div className="flex flex-row items-center justify-center space-y-0 space-x-5 mt-10">
            <button
              onClick={() => setShowConfimationModal(false)}
              className={`w-80 px-3 py-3 justify-center items-center gap-2.5 inline-flex text-base font-semibold font-open rounded-lg shadow bg-sky-100 text-sky-400 hover:shadow-lg hover:bg-sky-200`}
            >
              Go Back
            </button>
            <button
              onClick={() => markAsComplete()}
              className={`w-80 px-3 py-3 justify-center items-center gap-2.5 inline-flex text-base font-semibold font-open rounded-lg shadow bg-sky-500 text-center text-white hover:shadow-lg hover:bg-sky-600`}
            >
              Confirm
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

export default DashboardAdmin;
