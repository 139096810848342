import { ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import logo from "../assets/Images/logo.png";
import logoutImg from "../assets/Images/icon/logout.png";
import { logout } from "../services/operations/authAPI";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <aside className="h-screen">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src={logo}
            className={`overflow-hidden transition-all ${
              expanded ? "w-40 h-11 object-contain" : "w-0"
            }`}
            alt=""
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 mt-5">{children}</ul>
        </SidebarContext.Provider>

        <button
          onClick={() => {
            dispatch(logout(navigate));
          }}
          className="flex items-center px-5 py-7"
        >
          <img src={logoutImg} alt="" className="w-6 h-6" />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-40 ml-2" : "w-0"}
          `}
          >
            <h1 className="text-rose-500 font-semibold font-open">Log Out</h1>
          </div>
        </button>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, to }) {
  const { expanded } = useContext(SidebarContext);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = () => {
    if (to) {
      const match = location.pathname === to;
      return match;
    }
    return false;
  };

  const handleClick = () => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <li
      className={`
        relative flex items-center justify-center py-2 px-3 mb-5
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${isActive() ? "text-sky-500" : "text-neutral-500"}
        ${expanded ? "hover:bg-sky-100" : ""}
      `}
      onClick={handleClick}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${
          expanded ? "w-40 ml-3" : "w-0"
        }`}
      >
        {text}
      </span>

      {!expanded && (
        <div
          className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-sky-400 text-white text-sm z-50
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
          ${isActive() ? "visible opacity-100 translate-x-0" : ""}
      `}
        >
          {text}
        </div>
      )}
    </li>
  );
}
