import React from "react";
import logo from "../assets/Images/logo.png";
import { useSelector } from "react-redux";
import ProfileDropdown from "./ProfileDropdown";
import { MdOutlineMenu } from "react-icons/md";

function Navbar() {
  const { token } = useSelector((state) => state.auth);
  const { user, company } = useSelector((state) => state.profile);
  return (
    <nav className="flex items-center justify-between px-10 py-4 bg-white border-b-2 shadow-lg">
      <h1 className="text-black text-base sm:text-xl font-bold font-open">
        Hello, {user?.Name}
      </h1>
      <h1 className="text-black text-base sm:text-xl font-bold font-open">
        {company?.CompanyName}
      </h1>
      <ProfileDropdown />
    </nav>
  );
}

export default Navbar;
