import React from "react";
import shop from "../assets/Images/shopLogo.png";
import { useNavigate } from "react-router-dom";
function NoOffer({ isSubscribed }) {
  const navigate = useNavigate();
  return (
    <div className="mx-10 my-5">
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-black text-xl font-bold font-inter mt-2">
          Your Offerings on AllesHealth Platform
        </h1>
        {!isSubscribed && (
          <button
            type="button"
            className="px-3 py-2 bg-rose-500 rounded shadow justify-center items-center gap-2.5 inline-flex hover:shadow-md transition duration-300 ease-in-out"
            onClick={() => {
              navigate("/membership");
            }}
          >
            <div className="text-sky-100 text-sm font-normal font-open">
              Go Premium
            </div>
          </button>
        )}
      </div>
      <div className="bg-white rounded-md p-10 mt-5">
        <div className="mt-6 mb-4 pt-2 flex items-center justify-center">
          <img
            src={shop}
            alt="shop icon"
            className="w-36 h-42 object-contain"
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-black text-center text-xl font-semibold font-open pb-4 mt-6">
            Add your Offerings to be Listed on AllesHealth Platform
          </h1>
          <div className="text-center text-black text-sm font-normal font-inter leading-normal mt-2">
            <p>Be concise with your offer. </p>
            <p>
              Best offers are where Customer has lower price and flexibility
            </p>
          </div>
          <button
            type="button"
            className="p-3 bg-sky-400 hover:bg-sky-500 transition-all duration-200 rounded-lg w-1/2 mt-5"
            onClick={() => {
              navigate("/createOffer");
            }}
          >
            <div className="text-center text-white text-xs font-semibold font-open">
              Create Offer
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoOffer;
