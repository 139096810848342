import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "react-modal";
import toast from "react-hot-toast";
import {
  addCompanyBranch,
  deleteCompanyBranch,
  updateCompanyBranch,
} from "../services/operations/dashboardAPI";
import { getCompanyProfileDetails } from "../services/operations/profileAPI";
import { MERCHANT_TYPE } from "../utils/constants";

const BranchManagement = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { company, loading: companyLoading } = useSelector(
    (state) => state.profile
  );

  const { currentPlan } = useSelector((state) => state.membership);

  console.log("====================================");
  console.log(">>>>>>>>>>>>>>>>", currentPlan);
  console.log("====================================");

  // const { user, loading: userLoading } = useSelector((state) => state.profile);

  // const [formattedLocations, setFormattedLocations] = useState({});
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");

  // Store users Branch locations
  // const formattedLocations = {};

  // company?.Locations.forEach((location) => {
  //     const formattedLocation = `${location.PremiseStreetAddress}, ${location.City}, ${location.State}, ${location.ZipCode}`;
  //     formattedLocations[location.ID] = {
  //         id: location.ID,
  //         name: location.Branch_Name,
  //         address: formattedLocation
  //     };
  // });

  // const Data = Object.values(formattedLocations).map((location) => ({
  //     id: location.id,
  //     Name: location.name,
  //     Address: location.address,
  //     PhoneNumber: "+89 0947582734",
  // }));

  // MODAL part
  const [modalIsOpen, setIsOpen] = React.useState(false);
  Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "40rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      backgroundColor: "white",
    },
  };
  Modal.setAppElement(document.getElementById("root"));

  function openModal() {
    if (currentPlan?.branches === 2 && company?.Locations.length >= 2) {
      toast.error("You cannot add more than 2 branches");
      return;
    } else if (currentPlan?.branches === 3 && company?.Locations.length >= 3) {
      toast.error("You cannot add more than 3 branches");
      return;
    }
    setIsOpen(true);
  }

  function closeModal() {
    setId("");
    setIsEdit(false);
    // Clear formData state
    setFormData(
      Object.fromEntries(Object.keys(formData).map((key) => [key, null]))
    );
    setErrors(
      Object.fromEntries(Object.keys(errors).map((key) => [key, null]))
    );
    setIsOpen(false);
    dispatch(getCompanyProfileDetails(token));
  }

  // const handleEditBranch = (center) => {
  //   setIsEdit(true);
  //   setId(center.id);
  //   // Set formData state with the data of the branch being edited
  //   setFormData({
  //     Branch_Name: center.Branch_Name,
  //     PremiseStreetAddress: center.PremiseStreetAddress,
  //     City: center.City,
  //     State: center.State,
  //     ZipCode: center.ZipCode,
  //     PhoneNumber: center.PhoneNumber,
  //   });

  //   // Open the modal
  //   openModal();
  // };

  // Branch Management part
  const [formData, setFormData] = useState({
    ID: "",
    Branch_Name: "",
    PremiseStreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
    PhoneNumber: "",
    Latitude: "0",
    Longitude: "0",
    LocationType: "",
  });
  const [errors, setErrors] = useState({
    Branch_Name: "",
    PremiseStreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
    PhoneNumber: "",
    Latitude: "0",
    Longitude: "0",
    LocationType: "",
  });

  const handleEditBranch = (center) => {
    if (company?.AccountStatus !== MERCHANT_TYPE.ADMINISTRATOR) setIsEdit(true);
    setFormData({
      ID: center.ID,
      Branch_Name: center.Branch_Name,
      PremiseStreetAddress: center.PremiseStreetAddress,
      City: center.City,
      State: center.State,
      ZipCode: center.ZipCode,
      PhoneNumber: center.PhoneNumber,
      LocationType: center.LocationType,
      Latitude: center.Latitude,
      Longitude: center.Longitude,
    });
    openModal();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // async function updateLocationGeolocation(basicData) {
  //   // if (!basicData || typeof basicData !== 'object') {
  //   //   console.error("Input data is not an object");
  //   //   return null;
  //   // }

  //   try {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //         `${basicData.PremiseStreetAddress}, ${basicData.City}, ${basicData.State}, ${basicData.ZipCode}`
  //       )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
  //     );
  //     const responseData = await response.json();
  //     const { lat, lng } = responseData.results[0]?.geometry.location;
  //     return {
  //       ...basicData,
  //       Latitude: lat.toString(),
  //       Longitude: lng.toString(),
  //     };
  //   } catch (error) {
  //     console.error("Error fetching geolocation:", error);
  //     return basicData; // Return original data if there's an error
  //   }
  // }

  // async function submitHandler(e) {
  //   e.preventDefault();
  //   const newErrors = {};

  //   // Clear the previous errors
  //   setErrors(
  //     Object.fromEntries(Object.keys(errors).map((key) => [key, null]))
  //   );

  //   // Check for new errors
  //   Object.keys(formData).forEach((key) => {
  //     if (!formData[key]) {
  //       newErrors[key] = "This field is required";
  //     } else if (
  //       typeof formData[key] === "string" &&
  //       formData[key].trim() === ""
  //     ) {
  //       newErrors[key] = "This field cannot be empty";
  //     }
  //   });
  //   if (Object.keys(newErrors).length > 0) {
  //     toast.error("Please fill all the required fields");
  //     setErrors(newErrors);
  //     return;
  //   }

  //   const geolocation = await getGeolocation();
  //   if (geolocation !== null) {
  //     const { lat, lng } = geolocation;
  //     setFormData((prev) => ({
  //       ...prev,
  //       Latitude: lat.toString(),
  //       Longitude: lng.toString(),
  //     }));
  //   } else {
  //     toast.error("Please provide a valid address.");
  //     setFormData((prev) => ({
  //       ...prev,
  //       Latitude: "",
  //       Longitude: "",
  //     }));
  //     setErrors((prev) => ({
  //       ...prev,
  //       PremiseStreetAddress: "Invalid address",
  //       City: "Invalid address",
  //       State: "Invalid address",
  //       ZipCode: "Invalid address",
  //     }));
  //     return;
  //   }

  //   let basicData = {};

  //   if (isEdit === true) {
  //     basicData = {
  //       ID: id,
  //       Branch_Name: formData.Branch_Name,
  //       PremiseStreetAddress: formData.PremiseStreetAddress,
  //       City: formData.City,
  //       State: formData.State,
  //       ZipCode: formData.ZipCode,
  //       PhoneNumber: formData.PhoneNumber.startsWith("+91")
  //         ? formData.PhoneNumber
  //         : `+91${formData.PhoneNumber}`,
  //       Latitude: formData.Latitude,
  //       Longitude: formData.Longitude,
  //     };
  //     // const data = await updateLocationGeolocation(basicData);

  //     dispatch(updateCompanyBranch(token, basicData));
  //   } else {
  //     basicData = {
  //       Branch_Name: formData.Branch_Name,
  //       PremiseStreetAddress: formData.PremiseStreetAddress,
  //       City: formData.City,
  //       State: formData.State,
  //       ZipCode: formData.ZipCode,
  //       PhoneNumber: `+91${formData.PhoneNumber}`,
  //       Latitude: formData.Latitude,
  //       Longitude: formData.Longitude,
  //     };
  //     // const data = await updateLocationGeolocation(basicData);

  //     // Check if there is an error in getting latitude and longitude
  //     // if (!data.Latitude || !data.Longitude) {
  //     //   toast.error("Address provided is not correct");
  //     //   toast.error("Please fill the correct address fields");
  //     // }
  //     dispatch(addCompanyBranch(token, basicData));
  //   }
  //   closeModal();
  // }

  const getGeolocation = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          formData.PremiseStreetAddress
        )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
      );
      const data = await response.json();

      if (data.results && data.results[0]?.geometry?.location) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("Geolocation:", lat, lng);
        return { lat, lng };
      } else {
        console.error("Geolocation data not found in API response");
        return null;
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null;
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (company?.AccountStatus !== MERCHANT_TYPE.ADMINISTRATOR) {
      const mandatoryFields = [
        "Branch_Name",
        "PremiseStreetAddress",
        "City",
        "State",
        "ZipCode",
        "PhoneNumber",
      ];
      const newErrors = {};

      mandatoryFields.forEach((key) => {
        const value = formData[key];

        if (!value) {
          newErrors[key] = "This field is required";
        } else if (typeof value === "string" && value.trim() === "") {
          newErrors[key] = "This field cannot be empty";
        }
      });

      if (Object.keys(newErrors).length > 0) {
        toast.error("Please fill all the required fields");
        setErrors(newErrors);
        return;
      }

      const geolocation = await getGeolocation();
      if (geolocation !== null) {
        const { lat, lng } = geolocation;
        setFormData((prev) => ({
          ...prev,
          Latitude: lat.toString(),
          Longitude: lng.toString(),
        }));

        if (isEdit) {
          let data = {
            ID: formData.ID,
            Branch_Name: formData.Branch_Name,
            PremiseStreetAddress: formData.PremiseStreetAddress,
            City: formData.City,
            State: formData.State,
            ZipCode: formData.ZipCode,
            PhoneNumber: `+91${formData.PhoneNumber}`,
            Latitude: lat.toString(),
            Longitude: lng.toString(),
          };
          dispatch(updateCompanyBranch(token, data));
          toast.success("Branch Updated Successfully");
        } else {
          let data = {
            Branch_Name: formData.Branch_Name,
            PremiseStreetAddress: formData.PremiseStreetAddress,
            City: formData.City,
            State: formData.State,
            ZipCode: formData.ZipCode,
            PhoneNumber: `+91${formData.PhoneNumber}`,
            Latitude: lat.toString(),
            Longitude: lng.toString(),
          };
          dispatch(addCompanyBranch(token, data));
          toast.success("Branch Added Successfully");
        }
      } else {
        toast.error("Please provide a valid address.");
        setFormData((prev) => ({
          ...prev,
          Latitude: "",
          Longitude: "",
        }));
        setErrors((prev) => ({
          ...prev,
          PremiseStreetAddress: "Invalid address",
          City: "Invalid address",
          State: "Invalid address",
          ZipCode: "Invalid address",
        }));
        return;
      }
    }
    handleModalClose();
  };

  const handleModalClose = () => {
    closeModal();
    dispatch(getCompanyProfileDetails(token));
  };

  useEffect(() => {
    dispatch(getCompanyProfileDetails(token));
  }, []);

  useEffect(() => {
    if (company) {
      setData(
        company.Locations.map((location) => ({
          ID: location.ID || "",
          Branch_Name: location.Branch_Name || "",
          City: location.City || "",
          PremiseStreetAddress: location.PremiseStreetAddress || "",
          State: location.State || "",
          ZipCode: location.ZipCode || "",
          Address: `${location.PremiseStreetAddress}, ${location.City}, ${location.State}, ${location.ZipCode}`,
          PhoneNumber: location.PhoneNumber || "",
          LocationType: location.LocationType || "",
        }))
      );
    }
  }, [company]);

  if (companyLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-sky-100 min-h-screen py-2 px-4 sm:py-5 sm:px-10 scroll-smooth">
      {/* MODAL */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Branch Modal"
        >
          {/* Modal Header */}
          <div className="m-5">
            <div className="flex flex-row justify-between items-center mb-6">
              <h2 className="text-black text-lg font-semibold font-open pb-2">
                Add New Branch
              </h2>
              <button
                onClick={closeModal}
                style={{ color: "#ACACAC", fontSize: "1.2em" }}
                className="pb-2"
              >
                <IoIosCloseCircleOutline />
              </button>
            </div>
            <form>
              {/* Branch Name */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Branch Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="Branch_Name"
                    value={formData.Branch_Name}
                    onChange={handleOnChange}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                    maxLength={30}
                    placeholder="Input branch name here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.Branch_Name ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Branch Street Address */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Street Address
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="PremiseStreetAddress"
                    value={formData.PremiseStreetAddress}
                    onChange={handleOnChange}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                    maxLength={100}
                    placeholder="Input street address here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.PremiseStreetAddress ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* City state zipcode */}
              <div className="mb-6 flex flex-row justify-center items-center gap-5">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    City
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleOnChange}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                    maxLength={20}
                    placeholder="Input city here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.City ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    State
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="State"
                    value={formData.State}
                    onChange={handleOnChange}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                    maxLength={20}
                    placeholder="Input state here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.State ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    ZIP Code
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="number"
                    name="ZipCode"
                    value={formData.ZipCode}
                    onChange={(e) => {
                      // Ensure that the value is not longer than 10 digits
                      if (e.target.value.length <= 6) {
                        handleOnChange(e);
                      }
                    }}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                    max={6}
                    placeholder="Input ZipCode here"
                    className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                      errors.ZipCode ? "border-red-500" : ""
                    }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              {/* Branch Phone Number */}
              <div className="mb-6">
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-open mb-2">
                    Phone Number
                    <sup className="text-red-600">*</sup>
                  </p>
                  <div className="flex flex-row gap-4 items-center justify-center">
                    <input
                      type="text"
                      name="CountryCode"
                      value={"+91"}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                      }
                      maxLength={3}
                      className={`w-[4.5em] text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                    <span>-</span>
                    <input
                      type="number"
                      name="PhoneNumber"
                      // value={
                      //   formData.PhoneNumber?.startsWith("+91")
                      //     ? formData.PhoneNumber.slice(3)
                      //     : formData.PhoneNumber
                      // }
                      value={formData.PhoneNumber}
                      onChange={(e) => {
                        // Ensure that the value is not longer than 10 digits
                        if (e.target.value.length <= 10) {
                          handleOnChange(e);
                        }
                      }}
                      disabled={
                        company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                      }
                      max={10}
                      placeholder="Input branch phone number here"
                      className={`w-full text-black text-xs font-normal font-open bg-gray-100 rounded-md p-4 border ${
                        errors.PhoneNumber ? "border-red-500" : ""
                      }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                  </div>
                </label>
              </div>

              {/* Submit Button */}
              {company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR ? (
                <>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#3CA5DC" }}
                    className="text-white w-full px-4 py-2 rounded-md mt-4"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#3CA5DC" }}
                    className="text-white w-full px-4 py-2 rounded-md mt-4"
                    onClick={submitHandler}
                    disabled={
                      company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR
                    }
                  >
                    Proceed
                  </button>
                </>
              )}
            </form>
          </div>
        </Modal>
      </div>

      {/* Branch Management section */}
      <div className="pb-6 pt-2">
        <h1 className="text-black text-xl font-semibold font-open">
          Branch Management
        </h1>
      </div>
      <div className="bg-white h-full rounded-md flex-1 px-4">
        <div className="grid grid-cols-5 gap-4 text-left justify-between items-center py-5 px-4">
          <h3 className="text-black text-sm font-semibold font-open">Name</h3>
          <h3 className="col-span-2 text-black text-sm font-semibold font-open">
            Address
          </h3>
          <h3 className="text-black text-sm font-semibold font-open">
            Phone Number
          </h3>
          <div className="flex text-white justify-end">
            {/* Add New Employee */}
            {company?.AccountStatus !== MERCHANT_TYPE.ADMINISTRATOR && (
              <button
                type="button"
                className="bg-[#3CA5DC] font-normal text-sm py-2 flex flex-row gap-2 items-center justify-center rounded-md px-1 w-[12rem]"
                onClick={openModal}
              >
                <span>
                  <FaPlus />
                </span>
                Add New Branch
              </button>
            )}
          </div>
        </div>
        <hr />
        {data.map((center) => (
          <>
            <div
              key={center.id}
              className="grid grid-cols-5 gap-4 text-left justify-between items-center py-5 px-4"
            >
              <p className="text-black text-sm font-normal font-open">
                {center.Branch_Name}
              </p>
              <p className="col-span-2 text-black text-sm font-normal font-open">
                {center.Address}
              </p>
              <p className="text-black text-sm font-normal font-open">
                {center.PhoneNumber}
              </p>
              <div className="flex flex-row justify-center items-center">
                {company?.AccountStatus === MERCHANT_TYPE.ADMINISTRATOR ? (
                  <>
                    <button
                      type="button"
                      style={{ color: "#3CA5DC" }}
                      className="flex flex-row items-center justify-center gap-2 pr-4 pl-4"
                      onClick={() => handleEditBranch(center)}
                    >
                      <span>
                        <IoEyeOutline />
                      </span>
                      View
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      style={{ color: "#3CA5DC" }}
                      className="flex flex-row items-center justify-center gap-2 pr-4 pl-4"
                      onClick={() => handleEditBranch(center)}
                    >
                      <span>
                        <MdEdit />
                      </span>
                      Edit
                    </button>
                  </>
                )}
                {/* <button
                  type="button"
                  style={{ borderLeft: "1px solid #ACACAC", color: "#DF535F" }}
                  className="flex flex-row items-center justify-center gap-2 pl-4"
                  onClick={(e) => {
                    dispatch(deleteCompanyBranch(token, center?.id));
                  }}
                  disabled={user?.MerchantType === MERCHANT_TYPE.ADMINISTRATOR}
                >
                  <span>
                    <RiDeleteBinLine />
                  </span>
                  Delete
                </button> */}
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>
    </div>
  );
};

export default BranchManagement;
