import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: [],
  loading: false,
  reportPath: "",
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    setProduct(state, action) {
      state.product = action.payload;
      // localStorage.setItem("product", JSON.stringify(action.payload));
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setReportPath(state, action) {
      state.reportPath = action.payload;
    },
  },
});

export const { setProduct, setLoading, setReportPath } = productSlice.actions;

export default productSlice.reducer;
