import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import check from "../assets/Images/icon/checkWhite.png";
import uploadReport from "../assets/Images/icon/uploadReport.png";
import uploadReportDisabled from "../assets/Images/icon/uploadReportDisabled.png";
import viewReport from "../assets/Images/icon/viewReport.png";
import send from "../assets/Images/icon/send.png";
import sendDisabled from "../assets/Images/icon/sendDisabled.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { markSalesAsFulfilled } from "../services/operations/productAPI";
import { FaCheckCircle } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import toast from "react-hot-toast";

function SalesCard({
  sales,
  selectedCard,
  currentPlan,
  logoURL,
  showReportModal,
  setShowReportModal,
  setShowPDFModal,
  selectedSales,
  setSelectedSales,
  sendReportToPatient,
  setShowConfimationModal,
}) {
  // const getPayoutDate = (dateString) => {
  //   const currentDate = new Date();
  //   const date = new Date(dateString);
  //   const previousMonth = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth() - 1,
  //     25
  //   );
  //   const currentMonth = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     9
  //   );

  //   let resultDate;
  //   if (date >= previousMonth && date <= currentMonth) {
  //     resultDate = new Date(
  //       currentDate.getFullYear(),
  //       currentDate.getMonth(),
  //       15
  //     );
  //   } else {
  //     resultDate = new Date(
  //       currentDate.getFullYear(),
  //       currentDate.getMonth(),
  //       30
  //     );
  //   }

  //   resultDate.setHours(23);
  //   resultDate.setMinutes(59);
  //   resultDate.setSeconds(0);
  //   return resultDate.toISOString();
  // };

  const getPayoutDate = (dateString) => {
    const date = new Date(dateString);
    let resultDate;

    if (date.getDate() >= 9 && date.getDate() <= 25) {
      resultDate = new Date(date.getFullYear(), date.getMonth(), 30);
    } else {
      // If the date is not between 9 and 25, set the payout date to the 15th of the next month
      resultDate = new Date(date.getFullYear(), date.getMonth() + 1, 15);
    }

    resultDate.setHours(23);
    resultDate.setMinutes(59);
    resultDate.setSeconds(0);

    // Check if the current date is past the payout date
    const today = new Date();
    if (today > resultDate) {
      return "Paid";
    }

    return resultDate.toISOString();
  };
  // Check if the payout date matches the current date
  const isPayoutToday = (payoutDate) => {
    const today = new Date();
    // const today = new Date(2024, 2, 15);
    return moment(payoutDate).isSame(today, "day");
  };

  const sendReport = () => {
    sendReportToPatient(sales);
  };

  return (
    <div className="bg-white rounded-lg p-5">
      <div>
        <div className="flex flex-row items-center">
          {selectedCard?.logoURL ? (
            <img
              src={selectedCard.logoURL}
              alt="sales"
              className="w-14 h-10 object-contain"
            />
          ) : logoURL ? (
            <img
              src={logoURL}
              alt="sales"
              className="w-14 h-10 object-contain"
            />
          ) : (
            <svg
              fill="#0ea5e9"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              className="w-14 h-14 object-contain"
            >
              <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z"></path>
              <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z"></path>
            </svg>
          )}
          <div className="text-black text-sm font-semibold font-open ml-2">
            {sales?.ProductName}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 justify-around my-5">
          {sales?.IsReportUploaded && (
            <div
              onClick={() => {
                setSelectedSales(sales);
                setShowPDFModal(true);
              }}
              className="w-full lg:w-36 px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-1 inline-flex text-white text-xs font-normal font-open cursor-pointer select-none"
            >
              <img src={viewReport} alt="tick" className="w-4 h-4" />
              View Reports
            </div>
          )}
          {sales?.OrderStatus === "FULFILLED" ? (
            <div className="w-full lg:w-36 px-3 py-2 bg-lime-500 rounded shadow justify-center items-center gap-1 inline-flex text-white text-xs font-normal font-open select-none">
              <img src={check} alt="tick" className="w-4 h-4" />
              Fullfilled
            </div>
          ) : (
            <div
              onClick={() => {
                setSelectedSales(sales);
                setShowConfimationModal(true);
              }}
              className="w-full lg:w-36 px-3 py-2 bg-sky-100 rounded shadow justify-center items-center gap-2.5 inline-flex text-sky-400 text-xs font-normal font-open cursor-pointer select-none"
            >
              Mark as Complete
            </div>
          )}
          {!sales?.IsReportUploaded && (
            <>
              <FaArrowRightLong className="text-zinc-400 w-4 h-4 hidden lg:flex" />
              <div
                onClick={() => {
                  if (sales.OrderStatus !== "FULFILLED") {
                    toast.error("Fulfill the order first");
                    return;
                  }
                  setSelectedSales(sales);
                  setShowReportModal(true);
                }}
                className={`w-full lg:w-36 px-3 py-2 rounded ${
                  sales.OrderStatus === "FULFILLED"
                    ? "bg-sky-100 text-sky-400 cursor-pointer"
                    : "bg-neutral-300 text-zinc-500 cursor-not-allowed"
                } shadow justify-center items-center gap-2.5 inline-flex text-xs font-normal font-open select-none`}
              >
                <img
                  src={
                    sales.OrderStatus === "FULFILLED"
                      ? uploadReport
                      : uploadReportDisabled
                  }
                  alt="tick"
                  className="w-4 h-4"
                />
                Upload Report
              </div>
            </>
          )}
          {sales?.IsReportSend ? (
            <div className="w-full lg:w-36 px-3 py-2 bg-lime-500 rounded shadow justify-center items-center gap-1 inline-flex text-white text-xs font-normal font-open select-none">
              <img src={check} alt="tick" className="w-4 h-4" />
              Sent to Patient
            </div>
          ) : (
            <>
              <FaArrowRightLong className="text-zinc-400 w-4 h-4 hidden lg:flex" />
              <div
                onClick={sendReport}
                className={`w-full lg:w-36 px-3 py-2 rounded ${
                  sales.OrderStatus === "FULFILLED" &&
                  sales?.IsReportUploaded === true
                    ? "bg-sky-100 text-sky-400 cursor-pointer"
                    : "bg-neutral-300 text-zinc-500 cursor-not-allowed"
                } shadow justify-center items-center gap-2.5 inline-flex text-xs font-normal font-open select-none`}
              >
                <img
                  src={
                    sales.OrderStatus === "FULFILLED" &&
                    sales?.IsReportUploaded === true
                      ? send
                      : sendDisabled
                  }
                  alt="tick"
                  className="w-4 h-4"
                />
                Send to Patient
              </div>
            </>
          )}
        </div>
      </div>
      <hr className="mb-3" />
      <div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Customer Name
          </div>
          <div className="text-black text-xs font-normal font-open">
            {sales?.ShippingName}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Date of Purchase
          </div>
          <div className="text-black text-xs font-normal font-open">
            {moment(sales?.PaymentDate).format("DD MMM YYYY")}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Email
          </div>
          <div className="text-black text-xs font-normal font-open">
            {sales?.Email}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Phone Number
          </div>
          <div className="text-black text-xs font-normal font-open">
            {sales?.ShippingPhone}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Total Amount Paid
          </div>
          <div className="text-black text-xs font-normal font-open">
            INR {sales?.Amount}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Alleshealth Commission
          </div>
          <div className="text-black text-xs font-normal font-open">
            INR {sales?.AHCommissionAmount}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-zinc-500 text-xs font-semibold font-open">
            Payout Date
          </div>
          {/* <div className="text-black text-xs font-normal font-open">
            {isPayoutToday(getPayoutDate(sales?.PaymentDate)) ? (
              <div className="flex flex-row items-center">
                Paid <FaCheckCircle className="text-green-500 w-4 h-4 ml-1" />
              </div>
            ) : (
              <div>
                {moment(getPayoutDate(sales?.PaymentDate)).format(
                  "DD MMM YYYY"
                )}
              </div>
            )}
          </div> */}
          <div className="text-black text-xs font-normal font-open">
            {getPayoutDate(sales?.PaymentDate) === "Paid" ? (
              <div className="flex flex-row items-center">
                Paid <FaCheckCircle className="text-green-500 w-4 h-4 ml-1" />
              </div>
            ) : (
              <div>
                {moment(getPayoutDate(sales?.PaymentDate)).format(
                  "DD MMM YYYY"
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesCard;
